export const home = {
  title: 'QueenCasino クイーンカジノ | 日本No.1オンラインカジノ',
  description:
    "クイーンカジノは、アジア市場知名度ナンバーワンの ' 本格オンラインカジノサイト' です。定番のカジノゲームからオンラインパチンコ・パチスロまでオンラインで気軽に楽しめます。",
  newStyle: '新スタイル',
  classic: '旧スタイル',
  baccaratJackpot: 'バカラジャックポット',
  game: {
    title: 'カジノゲーム',
    all: 'すべて',
    provider: 'プロバイダー',
    input: '検索',
    barInput: 'CR の最も人気のある検索...',
    select: 'ゲームメーカー名の選択',
    filter: 'ゲームプロバイダー審査結果',
    result: '検索結果 {{target}} を {{total}} から表示関連性の高い順',
    resultHtml: '検索結果 <span>{{target}}</span> を <span>{{total}}</span> から表示関連性の高い順',
    maybe: '全画面表示になると品質が低下する可能性があります',
    cancel: 'クリア',
    search: 'サーチ',
    fullTips: '全画面表示',
    bigTips: '拡大する',
    collect: 'お気に入りに追加',
    cancelFavorite: 'お気に入りをキャンセルする',
    recordTips: '賭けの記録',
    has: 'すでにお気に入りに追加されています',
    tips1: 'お知らせ',
    tips2: 'ウォレット残高',
    tips3: 'ベットの際は',
    tips4: '円に換算されます',
    tips5: 'パチンコゲームへのベットはゲームピンに換算，レートは ',
    tips6: 'パチンコ・パチスロからの出金は、ゲームプラットフォームのルールを厳守してください。',
    tips7: '残高はUSDウォレットに変換されます。レートはリアルタイム為替レートで計算されます。',
    tips8: '了解',
    tipHtml: `換算金額はリアルタイムの為替レートの影響を受けます。`,
    searchTitle: 'ゲームを選択',
    loadmore: 'Load More',
    soccer: 'さっかー',
    basketball: 'ばすけっとぼーる',
    baseball: '野球',
    horseRacing: '競馬'
  },
  vip: {
    title: 'オンラインカジノ最高クラスのVIPメンバーシップ特典',
    sub01: 'ワンランク上のステータスをあなたへ。',
    sub02:
      'クイーンカジノVIPメンバーシップでは、さらに満足のゆく体験をご用意しております。 以下のステータスに応じて、<br />特別な幅広い特典をご利用いただけます。',
    btn: 'もっと詳しく'
  },
  promotion: {
    btn: 'プロモーション一覧'
  },
  pachinko: {
    btn: 'パチンコ'
  },
  liveCasino: {
    btn: 'ライブカジノはこちら'
  },
  slotGame: {
    btn: 'ビデオスロットはこちら',
    tryPlay: '無料プレイ',
    goGame: 'リアルプレイ'
  },
  sport: {
    sportBtn: 'スポーツベットはこちら',
    eBtn: 'E スポーツ',
    virtualBtn: 'バーチャルゲーム',
    h5Des: 'LIVE BETTING WHILE ENJOYING THE GAME! ENJOY SPORTS BETTING WITH QUEEN CASINO!'
  },
  feature: {
    titleText: '安心して楽しめる8つの特徴',
    subText01: 'QUEEN CASINO を安心して楽しめる8つの特徴',
    subText02: '安心してオンラインカジノをお楽しみください！'
  },
  spokesman: {
    titleText: '人気セクシー女優が幸運の女神です。',
    subText:
      'QUEEN CASINOの案内役として、アダルトアワード最優秀女優賞受賞「相沢 みなみ」が新アンバサダーガールに加わり、ますます超豪華ラインアップに！誰もが知っている人気セクシー女優・小澤マリア、丘咲エミリ、川上奈々美と共に貴方をサポートします。'
  },
  seach: {
    text1: '検索結果',
    text2: 'を',
    text3: 'から表示関連性の高い順',
    text4: 'ゲーム名',
    text5: '検索結果',
    text6: '検索したいゲームを入力してください',
    text7: 'まだ結果がありません。<br/>もう一度検索してください'
  },
  event: 'イベントとレース',
  brand: 'Queen Casinoブランドのアンバサダー',
  people: '人々',
  link: 'お好みのゲームを選択してください',
  marquee: [
    {
      msgContent:
        '24時間いつでもどこでもパチンコパチスロ！リニューアル期間限定の入金不要ボーナス45ドルでお試しプレイ'
    },
    {
      msgContent:
        'クイーンカジノへようこそ！当サイトでは、便利で豊富な入出金方法を取りそろえているだけでなく、日本史上初のパチンコ・パチスロの実機プレイ機能を搭載ています。もし、ご質問やご意見等がございましたら、24時間年中無休のカスタマーサポートまでお問い合わせをお願いいたします。'
    }
  ],
  tempNotice: {
    confirm: '確認',
    title: 'クイーンカジノからのお知らせ',
    date: '期間：2023年5月2日（火曜日）17時～5月8日（月曜日）8時30分',
    text1: 'GW期間中はHi-BANQからの出金対応時間帯が以下のようになります。',
    text2:
      'なお、ご入金は通常通り行えます。GWが終わりましたら、引き続き出金時間業界最速を目指します！！ご不便お掛け致しますが、ご理解の程何卒宜しくお願い致します。'
  },
  registerWelcone: {
    text1: 'こんにちは',
    text2: 'クイーンカジノへようこそ！新規登録ありがとうございます！全てのユーザーへ、幸運が訪れますように！',
    more: 'もっと見る',
    deposit: '入金'
  },
  jackpot: {
    moreBtn: '勝利速報',
    record: '勝利速報',
    totalAmount: '合計金額',
    time: '時間',
    round: 'ラウンド',
    cardType: 'カードタイプ',
    player: 'プレイヤー',
    amount: '金額',
    more: 'リプレイ',
    player1: 'P',
    banker1: 'B',
    rankListTitle: '重賞記録',
    index: '指数',
    marqueeText: '{{0}} プレゼント ${{1}}',
    dialogTitle: '勝利リプレイ'
  },
  betList: {
    title: '最新のベット情報',
    tab1: 'ハイローラー',
    tab2: '最新ベット情報',
    tab3: '自分のベット情報',
    game: 'ゲーム',
    player: 'プレーヤー',
    time: '時間',
    betAmount: 'ベット額',
    multiplier: '乗数',
    payout: '支払い',
    noData: 'データなし',
    totalWin: '合計勝利額',
    totalWins: '合計勝利数',
    totalBets: '合計ベット数',
    totalWagered: '合計ベット額',
    top3Fav: 'お気に入りのゲーム Top 3',
    wagered: 'ベット額',
    userInfo: 'アカウント情報',
    userId: 'ユーザーID',
    bigWin: 'ビッグウィン',
    tastSweet: '勝利の美酒に酔いしれよう！',
    shareMedia: 'SNSでシェアする',
    copyLink: 'リンクをコピー',
    statistics: '統計',
    casino: 'カジノ'
  },
  menuBar: {
    visitor: 'ビジター'
  },
  deposit: {
    hot: '熱いゲーム',
    login: 'ログイン',
    register: '新規登録',
    first: '初回入金完了で追加ボーナス',
    receipt: 'ボーナスを獲得しました',
    welcome: 'こんにちは{{name}} ようこそ Queen Casino',
    limit: '制限時間内にクリアして追加アイテムを獲得しましょう',
    bonus: 'ボーナス',
    fs: 'FSを受け取る',
    become: 'ご当選おめでとうございます',
    tips1: '初めてのご入金が完了し、',
    tips2: '2回目のご入金が完了し、',
    tips3: '3回のご入金が完了し、',
    tips4: 'ご入金イベントが完了し、',
    tips5: '個人情報を入力して、{{amount}}ボーナス',
    tips8: '個人情報を入力して、初めてのご入金 x {{amount}} ボーナス',
    tips6: '連絡先情報を認証して、{{amount}}ボーナス',
    tips9: '連絡先情報を認証して、初めてのご入金 x {{amount}} ボーナス',
    tips7: 'ご本人様確認(KYC)を完了して、{{amount}}ボーナス',
    tips0: 'ご本人様確認(KYC)を完了して、 初めてのご入金 x {{amount}} ボーナス'
  },
  heard: {
    wallet: 'キャッシュ',
    coupon: 'ボーナス',
    freeSqin: '無料スピン',
    display: 'フィアット通貨で表示',
    expire: '期限切れ',
    hide: 'ゼロ残高を隠す',
    btn: '確認',
    text: 'ご自国の通貨での表示はあくまで参考値です。すべてのベット履歴上の金額および送金トランザクションは、仮想通貨ベースで計算されてます。詳細についてはラインサポートまでお問い合わせください'
  }
} as const
