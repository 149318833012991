export const home = {
  // export const HOME = {
  title: 'Queen Casino | Japan No.1 Online Casino',
  description: 'No.1 Description',
  newStyle: 'New Style',
  classic: 'Classic',
  baccaratJackpot: 'BACCARAT JACKPOT',
  game: {
    title: 'Casino Games',
    all: 'All',
    provider: 'Provider',
    input: 'Game | Provider',
    barInput: 'Most popular searches for CR...',
    select: 'Select Game Provider',
    filter: 'Game Provider Screening Results',
    result: 'Showing {{target}} of {{total}} results sorted by relevance',
    resultHtml: 'Showing <span>{{target}}</span> of <span>{{total}}</span> results sorted by relevance',
    maybe: 'Full screen may reduce picture quality',
    cancel: 'Cancel',
    search: 'Search',
    fullTips: 'Full screen',
    bigTips: 'Enlarge',
    collect: 'Add to Favorites',
    cancelFavorite: 'Canceled favorite',
    recordTips: 'Betting record',
    has: 'Added to my favorites',
    tips1: 'Reminder',
    tips2: 'Wallet balance',
    tips3: 'Will be converted to',
    tips4: 'for betting',
    tips5: 'Betting on Pachinko game will be exchanged for game pins, at an exchange rate of  ',
    tips6: 'Withdrawal of cash from Pachinko and Pachislot is under the terms gaming platform.',
    tips7:
      'The balance will be transferred back to the US Dollar wallet, according to the real-time exchange rate.',
    tips8: 'Got it',
    tipHtml: `The conversion amount is subject to the real-time exchange rate.`,
    searchTitle: 'Select Games',
    loadmore: 'Load More',
    soccer: 'Soccer',
    basketball: 'Basketball',
    baseball: 'Baseball',
    horseRacing: 'Horse Racing'
  },
  vip: {
    title: 'VIP membership benefits of the best online casino',
    sub01: 'A higher-grade status to you.',
    sub02: 'We offer the more satisfying experiences for customers who always enjoy QUEEN CASINO.',
    btn: 'More details'
  },
  promotion: {
    btn: 'PROMOTIONS'
  },
  pachinko: {
    btn: 'Pachinko'
  },
  liveCasino: {
    btn: 'LIVE CASINO'
  },
  slotGame: {
    btn: 'SLOTS',
    tryPlay: 'PLAY FOR FUN',
    goGame: 'PLAY FOR REAL'
  },
  sport: {
    sportBtn: 'SPORTS BETTING',
    eBtn: 'Esports',
    virtualBtn: 'Virtual Games',
    h5Des: 'LIVE BETTING WHILE ENJOYING THE GAME! ENJOY SPORTS BETTING WITH QUEEN CASINO!'
  },
  feature: {
    titleText: 'Performance & Strengths',
    subText01: '8 features to enjoy QUEEN CASINO with pleasure.',
    subText02: 'Enjoy the online casino with confidence!'
  },
  spokesman: {
    titleText: 'The popular sexy actresses are the lucky goddess.',
    subText:
      'We welcome popular sexy actresses Maria Ozawa, Emily Okazaki, and Nanami Kawakami who are known as ambassador women who act as guides for QUEEN CASINO.'
  },
  seach: {
    text1: 'Showing ',
    text2: 'of ',
    text3: 'results sorted by relevance',
    text4: 'Game name',
    text5: 'Search Result',
    text6: 'Please enter the game you want to search',
    text7: 'No results yet, please search again'
  },
  event: 'Event and Race',
  brand: 'Queen Casino Brand Ambassador',
  people: 'people',
  link: 'Select Favourite Games',
  marquee: [
    {
      msgContent:
        'Simple and Fun!  24H Pachinko physical slot machines. Deposit not required for a free trial of $45. Limited-time offer now available.'
    },
    {
      msgContent:
        'Welcome to Queen Casino. We support faster and more convenient payment methods, and you can experience physical Pachinko and Pachislot machine games. If you have any suggestions, please feel free to contact our 24/7 customer service.'
    }
  ],
  tempNotice: {
    confirm: 'Confirm',
    title: 'Important Notice',
    date: 'Date：May 2 2023 17：00～May 8 08：30',
    text1: 'During the GW period, the following hours will be available for withdrawals from Hi-BANQ.',
    text2:
      'Deposits can be made as usual. After GW is over, we will continue to aim for the fastest withdrawal time in the industry! We apologize for any inconvenience this may cause and thank you for your understanding.'
  },
  registerWelcone: {
    text1: 'Hi',
    text2: 'Welcome to Queen Casino! Thank you for your new registration! Best of luck to all users!',
    more: 'More',
    deposit: 'Deposit'
  },
  jackpot: {
    moreBtn: 'More',
    record: 'Winning record',
    totalAmount: 'Total amount',
    time: 'Time',
    round: 'Round',
    cardType: 'Card type',
    player: 'Player',
    amount: 'Amount',
    more: 'More',
    player1: 'Player',
    banker1: 'Banker',
    rankListTitle: 'Prize record',
    index: 'Index',
    marqueeText: '{{0}} giveaway ${{1}}',
    dialogTitle: 'Winning replay'
  },
  betList: {
    title: 'Latest Bets & Race',
    tab1: 'High Rollers',
    tab2: 'Latest Bets',
    tab3: 'My Bets',
    game: 'Game',
    player: 'Player',
    time: 'Time',
    betAmount: 'Bet Amount',
    multiplier: 'Multiplier',
    payout: 'Payout',
    noData: 'No data',
    totalWin: 'Total Winning',
    totalWins: 'Total Wins',
    totalBets: 'Total Bets',
    totalWagered: 'Total Wagered',
    top3Fav: 'Top 3 Favorite Games',
    wagered: 'Wagered',
    userInfo: 'User Information',
    userId: 'User ID',
    bigWin: 'Recent Big Wins',
    tastSweet: 'Winning tastes sweet!',
    shareMedia: 'Share on social media',
    copyLink: 'Copy Link',
    statistics: 'Statistics',
    casino: 'Casino'
  },
  menuBar: {
    visitor: 'Visitor'
  },
  deposit: {
    hot: 'HOT Games',
    login: 'Login Now',
    register: 'Sign Up Now',
    first: 'Extra bonus for completing FTD',
    receipt: 'bonus has arrived',
    welcome: 'Hi {{name}}, Welcome To Queen Casino',
    limit: 'Complete within a limited time and get extra',
    bonus: 'bonus',
    fs: 'FS',
    become: 'Congratulations on becoming our distinguished member',
    tips1: 'The first time deposit has been completed',
    tips2: 'The second time deposit has been completed',
    tips3: 'The three time deposits have been completed',
    tips4: 'The deposit event has been completed',
    tips5: 'Complete the personal information and get {{amount}} bonus',
    tips8: 'Complete the personal information and get first deposit x {{amount}} bonus',
    tips6: 'Certified contact information and get {{amount}} bonus',
    tips9: 'Certified contact information and get first deposit x {{amount}} bonus',
    tips7: 'Complete KYC and get {{amount}} bonus',
    tips0: 'Complete KYC and get first deposit x {{amount}} bonus'
  },
  heard: {
    wallet: 'Credit',
    coupon: 'Reward',
    freeSqin: 'Free Spin',
    display: 'Display in fiat currency',
    expire: 'Expire',
    hide: 'Hide zero balance',
    btn: 'Confirm',
    text: 'Values displayed in your home currency are for reference only. All historical bet amounts and transfer transactions are calculated in virtual currency. Please contact Line Support for more details.'
  }
} as const
