export const bonus = {
  // export const BONUS = {
  id: 'Even ID',
  bonus: 'Bonus',
  viewBonus: 'VIEW BONUS',
  viewTast: 'VIEW TASK',
  bonusHistory: 'BONUS HISTORY',
  awaitingDeposit: 'Awaiting',
  noData: 'No task at the moment, please stay tuned',
  noDataBonus: 'No bonus at the moment, please stay tuned',
  taskContent: 'Task Content',
  revert: 'Revert',
  claimTaskSuccess: 'Claimed successfully',
  claimBonusTips: 'Would you like to claim this bonus?',
  claimBonusTips2: 'WOULD YOU LIKE TO REVERT THIS BONUS STATUS?',
  claimBonusTips3: 'WOULD YOU LIKE TO DECLINE THE BONUS HERE?',
  claimBonusSubTips: 'Receive this bonus offer ',
  claimBonusSubTips2: 'Revert Claimed Awaiting status to Issued.',
  claimBonusSubTips3: 'Once you decline a bonus, you will not be able to apply for the same bonus.',
  campaignName: 'Campaign Name',
  campaignId: 'Campaign ID',
  bonusAmount: 'Bonus Amount',
  rolloverStatus: 'Rollover Status',
  transactionID: 'Transaction ID',
  campaignPeriod: 'Campaign Period',
  start: 'Start',
  end: 'End',
  max: 'Max',
  bonusBetLimit: 'Bonus Bet Limit',
  actionGo: 'Start Now',
  actionClaim: 'Claim',
  lowerAmount: 'Claim conditions not met',
  regusterTastfinished: 'Finished',
  regusterTastgoing: 'Complete',
  actionReject: 'Reject',
  rejected: 'rejected',
  processing: 'processing',
  claim: 'Not started',
  reject: 'Reject',
  claimed: 'Claimed',
  canceled: 'Cancel',
  finished: 'Finished',
  convert: 'Received',
  ongoing: 'Ongoing',
  declined: 'Declined',
  expired: 'Expired in',
  dateClaimed: 'Date Claimed',
  expiration: 'Expiration Date',
  bonusConverted: 'Bonus Converted',
  placeholder: 'Please select',
  success: 'Submitted successfully',
  balance: 'Current Balance',
  allGame: 'All Games',
  depostLimit: 'Single deposit',
  depostAmount: 'Deposit amount',
  depostValue: 'Deposit',
  creditValue: 'Credit amount',
  credit: 'Credit',
  rollover: 'Bet amount',
  rolloverMultiple: 'Bet multiplier',
  rolloverLimit: 'Rollover limit',
  gameType: 'Categories',
  betLowTips: 'Bet amount must be greater than {{0}}',
  depositTips: 'Please make a deposit of at least {{0}} to activate the bonus',
  turnoverTips: 'Please reach a minimum wagering amount of {{0}} to activate the bonus.',
  creditTips: 'The first deposit reaches {{0}} to activate the reward',
  betTips: 'Please stake at least {{0}} to activate the reward',
  depositSuccessTips: 'Deposit completed. The wagering will start to be counted after claiming.',
  turnoverSuccessTips: 'Turnover has been completed, please claim the reward',
  day: 'day',
  days: 'days',
  hour: 'hour',
  minute: 'min',
  second: 'sec',
  finshProcess: 'Completion ({{0}})',
  taskId: 'Task ID',
  tips: 'Tips',
  justOne: 'You can only receive 1 event',
  cancelTips: 'If the bonus is given up, the betting history will be cleared',
  confirm: 'Confirm',
  cancelTaskSuccess: 'Canceled successfully',
  statu1: 'Unaccalimed',
  statu2: 'Awaiting',
  statu3: 'Claimed',
  statu4: 'Declined',
  statu5: 'Cancel',
  newbonus: 'Bonus',
  giftText: 'Get Bonus',
  bonusPlaza: 'Quest',
  plaza: 'Plaza',
  plazaHistory: 'Reward History',
  hotActivity: 'Hot activity',
  firstDepositActivity: 'Deposit',
  registerActivity: 'Registration',
  betActivity: 'Betting',
  recommendedActivity: 'Special events',
  depositActivity: 'Deposit',
  taskName: 'Event name',
  taskReward: 'Event reward',
  activityName: 'Event name',
  activityReward: 'Event Rewards method',
  taskRewardText1: 'Get Reward to complete',
  taskRewardText2: 'Complete to get reward',
  rewardType: 'Bonus type',
  rewardAmount: 'Reward amount',
  rewardCurrency: 'Reward currency',
  rewardTypeText1: 'Bonus',
  rewardTypeText2: 'Free Spin',
  rewardTypeText3: 'Bonus + Free Spin',
  rewardValue: 'Bonus value',
  rewardLimit: 'Maximum bonus limit',
  min: 'Minimum bonus limit',
  transactionAmount: 'Maximum transaction amount',
  rewardExpire: 'Reward expiration date',
  rewardLimitValue: 'Unlimited',
  rewardLimitMultipleValue: '{{0}} x {{1}}',
  maxWithdraw: 'Max withdraw',
  rolloverRequest: 'Turnover requirement',
  rolloverRequestNone: 'No flow required',
  rolloverRequestText: 'Bonus x {{0}}',
  rolloverRequestText2: '(deposit + bonus) x {{0}}',
  activeEndTime: 'Event end time',
  promotionEndTime: 'Promotion EndTime',
  gameTimes: 'You can join',
  gameTimesValue: '{{0}} times/{{1}}',
  gameTimesValueOne: 'One time',
  gameTimesValueDay: 'Day',
  gameTimesValueWeek: 'Week',
  gameTimesValueMonth: 'Month',
  gameTimesValueYear: 'Year',
  remainingGameTimes: 'Remained',
  viewTask: 'View details',
  viewTaskActivity: 'View details',
  claimeActivity: 'Receive',
  declineActivity: 'Refuse to collect',
  cancelActivity: 'Cancel the plaza',
  cancelBonus: 'Cancel this bonus',
  gameTims: '{{0}} spins',
  gameTimsProgress: '{{0}}<span>Free Spins</span>',
  rewardCalSourceBetAmount: 'Valid Betting Total',
  rewardCalSourceDepositAmount: 'Total deposit',
  rewardCalSourceDepositFirstAmount: 'Single deposit amount',
  rewardCalSourceSingleDepositAmount: 'Single deposit amount',
  rewardCalSourcePecommendNum: 'Recommended number of effective friends',
  closeDialogBtn: 'Close details',
  lobbyName: 'Game platform',
  gameName: 'Game name',
  sigleGameValue: 'Single game amount',
  tag1: 'Not started',
  tag2: 'In progress',
  notice: 'Notification',
  cancel: 'Are you sure to cancel?',
  cancelTast1: 'If you cancel the plaza, the progress of the task will be cleared.',
  cancelTast2: 'If you cancel the activity, the progress of the task will be cleared.',
  wallet1: 'Bonus wallet',
  wallet2: 'Credit wallet',
  claimAlertContent1: '【{{0}}】{{1}} USD bonus has been sent to your {{2}}',
  claimAlertContent2: '【{{0}}】{{1}} transfer free spin has been issued',
  claimAlertContent3:
    '【{{0}}】{{1}} USD bonus and {{2}} transfer to free spins have been released, the bonus will be sent to your {{3}}, please refresh to check',
  claimAlertContent4:
    '【{{0}}】The reward has been issued, the current reward is frozen, and the reward can be unlocked after completing {{1}} USD turnover',
  claimAlertContent5: 'Please complete the discounts you have received before claiming other discounts',
  claimAlertContent6: 'Whether to confirm the refusal to receive',
  noDataBonusText: 'No activities, please wait',
  noDataTastText: 'No plaza, please wait',
  task: 'Task',
  activity: 'Activity',
  offerType: 'Promo type',
  status: 'Select status',
  time: 'Select time',
  rewardMethod: 'Reward method',
  receiveTime: 'Reward receive time',
  expirationTime: 'Reward expiration time',
  finishTime: 'task completion time',
  currentStatus: 'Status',
  noNeed: 'No flow required',
  change: '{{num}}spins',
  platform: 'Game platform: ',
  name: 'Game name: ',
  amount: 'Single game amount: ',
  today: 'Today',
  today3: 'Last 3 days',
  today5: 'Last 7 days',
  today7: 'Last 15 days',
  today9: 'Last 30 days',
  record: 'Promo record',
  type: 'Task type',
  taskNames: 'Task name:',
  method: 'Activity reward method:',
  collectionTime: 'Reward collection time',
  award: 'Activity award',
  from: 'From',
  to: 'To',
  index: 'No.',
  sure: 'Are you sure you want to cancel?',
  voucher: 'Bonus x {{multiple}}',
  bonusInfo: {
    rakebackBonus: 'Rakeback Bonus',
    bounceBackBonus: 'Bounce Back Bonus',
    otherList_DoubleRakebackPromo: 'Double Rakeback Promo',
    dailyBonus: 'Rakeback Bonus',
    weeklyBonus: 'Weekly Bonus',
    monthlyBonus: 'Monthly Bonus',
    birthdayBonus: 'Birthday Bonus',
    exclusiveCustomerService: 'Exclusive-Customer Service',
    VIPSubscriptionBonus: 'VIP Subscription Bonus',
    fullyCustomizableExperience: 'Fully Customizable Experience',
    VIPTour: 'VIP customized tour (Invitation only & Diamond)',
    luxuryHotelSuites: 'Luxury Hotel Suites',
    privilegeDetails: 'Privilege Details'
  },
  join: 'Join Now',
  complete:
    'You need to complete mobile phone authentication and level 2 KYC authentication before you can receive it',
  later: 'Later',
  go: 'To verify',
  locking: 'Received successfully',
  dg: 'This promotion ends on {{time}}',
  up: 'Give up successfully!',
  give: 'Release successful',
  times: 'Times',
  use: 'Using',
  release: 'Received',
  dec: 'successfully deleted',
  extra: 'Extra',
  level: 'Member level',
  and: 'bonus and',
  fee: 'free spins!',
  fg: "you've received",
  wallet: 'Free Spin Wallet',
  rakebackTime: 'Claim next time '
} as const
