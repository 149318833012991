export const exclusion = {
  // export const EXCLUSION = {
  hintsText01:
    'If you feel that you are having difficulties controlling your gambling, you may self-exclude yourself for a period of 7 days, 30 days or 90 days. You may also wish to contact',
  hintsText02: 'GamCare',
  hintsText03: 'or',
  hintsText04: 'GamblingTherapy.org',
  hintsText05: 'for advice and support.',
  radioText01: 'Do not allow me to sign in for the next <span>7 days</span>',
  radioText02: 'Do not allow me to sign in for the next <span>30 days</span>',
  radioText03: 'Do not allow me to sign in for the next <span>90 days</span>',
  checkText01: 'Do not send me any promotion emails during my self exclusion period',
  checkText02: 'Send me an email when self exclusion is over',
  confirmText:
    'Are you sure to proceed? You will be logged out and will not be able to login for {{val}} days.',
  btn: 'SUBMIT',
  btnConfirm: 'YES',
  btnCancel: 'NO',
  emailUnsubscribe: 'Subscribe',
  Unsubscribe: 'Email',
  fh: 'Whether to turn on “Do not send me any promotion emails during my self exclusion period”'
} as const
