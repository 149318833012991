export const help = {
  title: 'はじめてガイドメニュー',
  selectAll: '--- メニューを選択 ---',
  help: {
    helpTitle: 'サイト画面の見方',
    navHead1: 'サイト画面の見方',
    navContent1: 'ゲームロビー',
    navHead2: 'アカウント作成ガイド',
    navContent2_1: 'ユーザー登録方法 (PC)',
    navContent2_2: 'ユーザー登録方法 (スマホ)',
    navHead3: '入金・出金ガイド',
    navContent3_1: '入出金に使える口座一覧',
    navContent3_2: 'PCからの入金方法',
    navContent3_3: 'スマホからの入金方法',
    navContent3_4: '出金方法について',
    navHead4: 'サービスについて',
    navContent4_1: 'ボーナスについて',
    navContent4_2: 'Q-PONについて',
    navContent4_3: 'VIPステータスについて'
  },
  novice: {
    noviceTitle1: 'ゲームロビーの見方・ログイン前(PC)',
    noviceContent1_1_1: 'QUEEN CASINO 公式サイトがそのままゲームロビーです。',
    noviceContent1_2_1: '項目',
    noviceContent1_2_2: '説明',
    noviceContent1_3_1: 'ホーム',
    noviceContent1_3_2: 'QUEEN CASINO ホーム画面に戻ります。',
    noviceContent1_4_1: 'ヘッダーメニュー',
    noviceContent1_4_2:
      'ここから好きなゲームのジャンルや、プロモーションページなどを選ぶことができます。\n' +
      '無料でスロットゲーム体験も楽しめます。',
    noviceContent1_5_1: 'ユーザー登録',
    noviceContent1_5_2: 'ユーザー登録が行えます。登録は日本語でとてもカンタンです。',
    noviceContent1_5_3: 'ユーザー登録方法はコチラ',
    noviceContent1_6_1: 'ログイン',
    noviceContent1_6_2: 'ユーザー登録が既にお済みの方は',
    noviceContent1_6_3: 'こちらからログイン',
    noviceContent1_6_4: 'できます。',
    noviceContent1_7_1: 'キャンペーン情報',
    noviceContent1_7_2: '現在開催中のキャンペーン・プロモーション情報',
    noviceContent1_8_1: 'サポート',
    noviceContent1_8_2: '日本語のカスタマーサポートスタッフとチャットが繋がります。',

    noviceTitle2: 'ゲームロビーの見方・ログイン後(PC)',
    noviceContent2_1_1: 'QUEEN CASINO にログインすると、ヘッダーバーに現在の残高や入金ボタンが表示されます。',
    noviceContent2_2_1: '項目',
    noviceContent2_2_2: '説明',
    noviceContent2_3_1: 'ユーザーアイコン',
    noviceContent2_3_2: 'クリックすると、マイアカウントのメニュー項目を開く事が出来ます。',
    noviceContent2_4_1: '現在の残高',
    noviceContent2_4_2: 'お客様が現在お持ちのバランス残高が表示されます。',
    noviceContent2_5_1: 'ボーナス残高',
    noviceContent2_5_2: 'お客様が現在お持ちのバランス残高が表示されます。',
    noviceContent2_6_1: '入金ページ',
    noviceContent2_6_2: '入金ページへ移動し、入金申請を行えます。',
    noviceContent2_7_1: 'マイメニュー',
    noviceContent2_7_2: 'お客様情報の変更や、プレイ履歴などの確認が行えます。',

    noviceTitle3: 'ゲームロビーの見方・ログイン前(モバイル)',
    noviceContent3_1_1: 'QUEEN CASINO にログインするとヘッダーバーに現在の残高や入金ボタンが表示されます。',
    noviceContent3_2_1: 'メニュー',
    noviceContent3_2_2: 'こちらを選択するとメニューを表示出来ます。',
    noviceContent3_3_1: 'ホーム',
    noviceContent3_3_2: 'QUEEN CASINO ホーム画面に戻ります。',
    noviceContent3_4_1: 'ログイン',
    noviceContent3_4_2: '既にユーザー登録がお済みの方は',
    noviceContent3_4_3: 'こちらからログイン',
    noviceContent3_4_4: 'できます',
    noviceContent3_5_1: 'キャンペーン情報',
    noviceContent3_5_2: '現在開催中のHOTなキャンペーン情報!!',
    noviceContent3_6_1: 'サポート',
    noviceContent3_6_2: '日本語のカスタマーサポートスタッフとチャットが繋がります！',

    noviceTitle4: 'サイドメニューの見方(モバイル)',
    noviceContent4_1_1: 'QUEEN CASINO にログインするとヘッダーバーに現在の残高や入金ボタンが表示されます。',
    noviceContent4_2_1: '表示言語切り替え',
    noviceContent4_2_2: 'お好みの言語表示に切り替える事が出来ます。（英語・日本語・タイ語）',
    noviceContent4_3_1: 'ゲームジャンル・プロモーション表示',
    noviceContent4_3_2: 'ここから好きなゲームのジャンルやプロモーション詳細ページを選ぶ事が出来ます。',
    noviceContent4_4_1: 'サイト情報',
    noviceContent4_4_2: '規約やサイト説明等の詳細を確認出来ます。',

    noviceTitle5: 'マイメニューの見方(モバイル)',
    noviceContent5_1_1: 'ログインすると、ヘッダーバー右上にユーザーアイコンが表示されます。',
    noviceContent5_2_1:
      'アイコンをクリックすると、マイメニューが表示され、いつでもお客様の残高や各種メニューへアクセスできます。',
    noviceContent5_3_1: 'ユーザー名',
    noviceContent5_4_1: '現在の残高',
    noviceContent5_5_1: '入金・出金ボタン',
    noviceContent5_6_1: 'プレイ履歴・各種アカウント設定メニュー'
  },
  accountList: {
    accountListTitle1: '入金方法の種類',
    accountListContent1_1_1: `<div style="text-align: center">QUEEN CASINOへの入金は下記の方法があります。</div>`,
    bankList: [
      { name: '銀行振込', description: 'お近くのATM又は銀行からお振込みが出来ます。' },
      {
        name: 'Credit Card',
        description: `<p>
                              MasterCard, JCB, American Express,<br />
                              Diners Club, Discover<br />
                              に対応しています。
                              </p>`
      },
      {
        name: 'WlandPay',
        description: `<p>MasterCard, JCB<br />
                            に対応しています。</p>`
      },
      { name: 'CoinPayment', description: 'ビットコインをはじめ、多くの仮想通貨に対応しています。' },
      { name: 'エコペイズ', description: '多くのオンラインカジノで入出金できる決済サービスです。' },
      { name: 'iWallet', description: '多くのオンラインカジノで入出金できる決済サービスです。' }
    ],
    accountListContent1_2_1: '銀行振込',
    accountListContent1_2_2: 'お近くのATM又は銀行からお振込みが出来ます。',
    accountListContent1_3_1: 'Credit Card',
    accountListContent1_3_2: `<p>
                              MasterCard, JCB, American Express,<br />
                              Diners Club, Discover<br />
                              に対応しています。
                              </p>`,
    accountListContent1_4_1: 'WlandPay',
    accountListContent1_4_2: `<p>MasterCard, JCB<br />
                            に対応しています。</p>`,
    accountListContent1_5_1: 'CoinPayment',
    accountListContent1_5_2: 'ビットコインをはじめ、多くの仮想通貨に対応しています。',
    accountListContent1_6_1: 'エコペイズ',
    accountListContent1_6_2: '多くのオンラインカジノで入出金できる決済サービスです。',
    accountListContent1_7_1: 'iWallet',
    accountListContent1_7_2: '多くのオンラインカジノで入出金できる決済サービスです。',
    accountListTitle2: '入金タイプによる限度額について',
    accountListContent2_1_1: '* 入金上限額引上げのご相談承ります。',
    accountListContent2_2_1: '入金タイプ',
    accountListContent2_2_2: '手数料',
    accountListContent2_2_3: '1回の取引限度額',
    accountListContent2_2_4: '所要時間',
    accountListContent2_3_1: `<tr>
                <td>銀行振込</td>
                <td>
                  無料
                  <br />
                  (銀行振込手数料のみお客様負担)
                </td>
                <td>
                  最低取引額 : 20 USD
                  <br />
                  最高取引額 : 10,000 USD
                </td>
                <td align="center">着金確認後、即時</td>
              </tr>
              <tr>
                <td>Credit Card</td>
                <td>無料</td>
                <td>
                  最低取引額 : 10 USD
                  <br />
                  最高取引額 : 1,000 USD
                </td>
                <td align="center">即時</td>
              </tr>
              <tr>
                <td>WlandPay</td>
                <td>無料</td>
                <td>
                  最低取引額 : 30 USD
                  <br />
                  最高取引額 : 1,000 USD
                </td>
                <td align="center">即時</td>
              </tr>
              <tr>
                <td>CoinPayment</td>
                <td>
                  無料
                  <br />
                  (ご利用ウォレットの送金手数料はお客様負担)
                </td>
                <td>
                  最低取引額 : 20 USD
                  <br />
                  最高取引額 : 10,000 USD
                </td>
                <td align="center">着金確認後、即時</td>
              </tr>
              <tr>
                <td>ecoPayz</td>
                <td>無料</td>
                <td>
                  最低取引額 : 10 USD
                  <br />
                  最高取引額 : 5,000 USD
                  <br />
                  <br />
                  * お客様のアカウント状況により 入金上限額、引上げのご相談承ります。
                </td>
                <td align="center">即時</td>
              </tr>
              <tr>
                <td>iWallet</td>
                <td>無料</td>
                <td>
                  最低取引額 : 10 USD
                  <br />
                  最高取引額 : 5,000 USD
                  <br />
                  <br />
                  * お客様のアカウント状況により 入金上限額、引上げのご相談承ります。
                </td>
                <td align="center">即時</td>
              </tr>`,
    accountListTitle3: '出金方法の種類',
    accountListContent3_4_1: `<div style="text-align: center">QUEEN CASINOからの出金は下記の方法があります。</div>`,
    accountListTitle4: '出金タイプによる限度額と所要時間',
    accountListContent4_4_1: `<table>
                                    <thead>
                                      <tr>
                                        <th style="min-width: 110px">出金タイプ</th>
                                        <th>手数料</th>
                                        <th>1回の取引限度額</th>
                                        <th style="text-align: center">所要時間</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>銀行振込</td>
                                        <td>
                                          無料
                                          <br />
                                          (銀行振込手数料のみお客様負担)
                                        </td>
                                        <td>
                                          最低取引額 : 50 USD
                                          <br />
                                          最高取引額 : 5,000 USD
                                        </td>
                                        <td align="center">約5分〜約24時間</td>
                                      </tr>
                                      <tr>
                                        <td>CoinPayment</td>
                                        <td>
                                          無料
                                          <br />
                                          (ご利用ウォレットの送金手数料はお客様負担)
                                        </td>
                                        <td>
                                          最低取引額 : 50 USD
                                          <br />
                                          最高取引額 : 5,000 USD
                                        </td>
                                        <td align="center">約5分〜約24時間</td>
                                      </tr>
                                      <tr>
                                        <td>ecoPayz</td>
                                        <td>無料</td>
                                        <td>
                                          最低取引額 : 10 USD
                                          <br />
                                          最高取引額 : 5,000 USD
                                        </td>
                                        <td align="center">約5分〜約24時間</td>
                                      </tr>
                                      <tr>
                                        <td>iWallet</td>
                                        <td>無料</td>
                                        <td>
                                          最低取引額 : 10 USD
                                          <br />
                                          最高取引額 : 5,000 USD
                                        </td>
                                        <td align="center">約5分〜約24時間</td>
                                      </tr>
                                    </tbody>
                                </table>`
  },
  depositPc: {
    tu: {
      tu1: '入金ボタンをクリック',
      tu2: '入金タイプを選択',
      tu3: '必要情報を入力し入金完了'
    },
    content: [
      {
        dt: 'パソコンからの入金方法',
        dd: [
          {
            type: 'text',
            color: 'card-c-red',
            text: '入金はQUEEN CASINOへログイン後、下記の3ステップで完了します。'
          },
          { type: 'tu', text: '' },
          {
            type: 'text',
            text: `<p>どの方法をご利用頂いても簡単にスピーディーな入金が出来ますが、<strong class="marker-pink">iWalletに関してはあらかじめ口座開設が必要となります</strong>ので、必要に応じてご登録ください。</p>`
          },
          { type: 'text', text: 'では入金方法を順を追ってご説明致します' }
        ]
      },
      {
        dt: '入金方法の選択',
        dd: [
          { type: 'text', text: 'トップ画面右側の【入金する】をクリックしてください。' },
          { type: 'img', text: 'depositPC-ja-JP-1.jpg' },
          {
            type: 'text',
            text: '入金ページに移動されますので、ご希望の入金方法をチェックして選択してください。'
          },
          { type: 'img', text: 'depositPC-ja-JP-2.jpg' },
          {
            type: 'text',
            text: 'この先は入金方法によって手順が変わってきますので、それぞれの項目をご覧ください。'
          }
        ]
      },
      {
        dt: '銀行振込',
        dd: [
          {
            type: 'text',
            text: 'クイーンカジノでは銀行振込による入金に対応しており、とても手軽に即時入金ができます。'
          },
          { type: 'img', text: 'depositPC-ja-JP-3.jpg' }
        ]
      },
      {
        dt: '銀行振込をチェックすると下記画面が表示されます。',
        dtg: true,
        dd: [
          { type: 'img', text: 'depositPC-ja-JP-4.jpg' },
          {
            type: 'text',
            text: '入金する金額（最低20ドル）'
          },
          {
            type: 'text',
            text: '上記の項目を全てご入力頂き【入金する】ボタンをクリックしてください。'
          }
        ]
      },
      {
        dt: 'Hi-BANQのページに移動します。',
        dtg: true,
        dd: [
          { type: 'img', width: 50, text: 'depositPC-ja-JP-5.jpg' },
          {
            type: 'text',
            width: 50,
            text: '内容をご確認の上、【入金する】ボタンをクリックしてください。'
          }
        ]
      },
      {
        dt: '入金申請手続きが完了すると、メールが届きます。',
        dtg: true,
        dd: [
          {
            type: 'text',
            text: '入金手続きが完了すると、QUEEN CASINOに戻り、下記のような画像が表示されます。'
          },
          { type: 'img', width: 50, text: 'depositPC-ja-JP-6.jpg' },
          {
            type: 'text',
            width: 50,
            text: '数分以内に、Hi-Banqから入金案内に関するメールが届きますので、ご確認ください。'
          }
        ]
      },
      {
        dt: 'クレジットカード',
        dd: []
      },
      {
        dt: 'クレジットカードでの入金',
        dtg: true,
        dd: [
          {
            type: 'text',
            text: 'クイーンカジノのクレジットカード決済は、6ブランドに対応しております。'
          },
          {
            type: 'link',
            text: 'VISAも利用可能になりました',
            link: '/promo'
          },
          { type: 'img', text: 'depositPC-ja-JP-7.jpg' },
          { type: 'img', width: 50, text: 'depositPC-ja-JP-8.jpg' },
          {
            type: 'text',
            width: 50,
            text: 'クレジットカードのご利用には、マイアカウントのアカウント情報がすべて入力されている必要が有ります。海外で発行したクレジットカードはご利用できませんのでご注意ください。'
          },
          { type: 'img', width: 50, text: 'depositPC-ja-JP-9.jpg' },
          {
            type: 'text',
            width: 50,
            text: `<p>上から順に項目をお間違えなく入力してください。</p>
              <ul class="num-li">
                <li>
                  <p>入金する金額（最低10ドル）</p>
                </li>
                <li>
                  <p>クレジットカード番号（16桁）</p>
                </li>
                <li>
                  <p>有効期限（月・年）</p>
                </li>
                <li>
                  <p>CVV番号（3桁）※通常カード裏面の署名欄右側当たりにあります</p>
                </li>
                <li>
                  <p>お名前</p>
                </li>
                <li>
                  <p>市区町村／番地</p>
                </li>
                <li>
                  <p>都道府県</p>
                </li>
                <li>
                  <p>国</p>
                </li>
                <li>
                  <p>電話番号</p>
                </li>
                <li>
                  <p>メールアドレス</p>
                </li>
              </ul>
              <p>上記の項目を全てご入力頂き【入金する】ボタンをクリックすると入金処理が開始されます。</p>
            `
          }
        ]
      },
      {
        dt: 'WlandPay での入金',
        dtg: true,
        dd: [
          {
            type: 'text',
            text: 'クイーンカジノのクレジットカード決済は、WlandPayと提携しております。'
          },
          { type: 'img', text: 'depositPC-ja-JP-10.jpg' },
          { type: 'img', width: 50, text: 'depositPC-ja-JP-8.jpg' },
          {
            type: 'text',
            width: 50,
            text: 'WlandPayのご利用には、マイアカウントのアカウント情報がすべて入力されている必要が有ります。以下のメッセージが出た場合は、アカウント情報をすべて入力してからご利用下さい。'
          },
          { type: 'img', width: 50, text: 'depositPC-ja-JP-11.jpg' },
          {
            type: 'text',
            width: 50,
            text: ` <p>上から順に項目をお間違えなく入力してください。</p>
                            <ul class="num-li">
                              <li>入金する金額（最低10ドル）</li>
                              <li>クレジットカード番号（16桁）</li>
                              <li>有効期限（月・年）</li>
                              <li>CVV番号（3桁）※通常カード裏面の署名欄右側当たりにあります</li>
                              <li>電話番号</li>
                            </ul>
                            <p>上記の項目を全てご入力頂き【入金する】ボタンをクリックすると入金処理が開始されます。</p>
                          `
          }
        ]
      },
      {
        dt: '仮想通貨 CoinPayment',
        dd: [
          {
            type: 'text',
            text: 'ビットコインをはじめ、数多くの仮想通貨で入金が行えます。'
          },
          { type: 'img', width: 50, text: 'depositPC-ja-JP-12.jpg' },
          {
            type: 'text',
            width: 50,
            text: `入金方法から
              <strong class="marker-yellow">Coinpayment を選択</strong>
              します。`
          }
        ]
      },
      {
        dt: 'Coinpaymentをチェックすると下記画面が表示されます。',
        dtg: true,
        dd: [
          { type: 'img', width: 50, text: 'depositPC-ja-JP-13.jpg' },
          {
            type: 'text',
            width: 50,
            text: `<p>項目をお間違えなく入力してください。</p>
                      <ul class="num-li">
                        <li>仮想通貨の選択</li>
                        <li>入金する金額</li>
                      </ul>
                      <p>をご入力頂き、【アドレスを生成】をクリックすると、振込先のアドレスの生成が開始されます。</p>
                    `
          }
        ]
      },
      {
        dt: '生成アドレスをコピーし、仮想通貨アプリから振込を完了してください。',
        dtg: true,
        dd: [
          { type: 'img', width: 50, text: 'depositPC-ja-JP-14.jpg' },
          {
            type: 'text',
            width: 50,
            text: `<p>アドレスが生成されたら、クリックしアドレスをコピーします。</p>
                      <p>
                        あとはお客様の仮想通貨アプリから、アドレス宛に振込をしてください。
                        <br />
                        お振込の着金が確認できた時点で、すぐに入金反映されます。
                      </p>`
          }
        ]
      },
      {
        dt: 'iWallet',
        dd: [
          {
            type: 'text',
            text: 'iWalletは現在多くのオンラインカジノに対応した口座ですので作成をお勧めします。'
          },
          { type: 'img', text: 'depositPC-ja-JP-15.jpg' }
        ]
      },
      {
        dt: 'iWalletをチェックすると下記画面が表示されます。',
        dtg: true,
        dd: [
          { type: 'img', width: 50, text: 'depositPC-ja-JP-16.jpg' },
          {
            type: 'text',
            width: 50,
            text: ` <p>項目をお間違えなく入力してください。</p>
              <ul class="num-li">
                <li>入金する金額（最低10ドル）</li>
              </ul>
              <p>をご入力頂き、【入金する】をクリックすると、入金処理が開始されiWalletのページへ移動します。</p>
            `
          }
        ]
      },
      {
        dt: 'iWalletにてログインし、入金手続きを完了してください。',
        dtg: true,
        dd: [
          {
            type: 'text',
            text: `ご入力するアカウントIDやセキュアIDが不明な場合はiWalletにログインしてご確認ください。
                    <br />
                    iWalletのアカウントをまだお持ちでない場合は、こちらのURLからご登録ください。
                  `
          },
          {
            type: 'link',
            text: 'https://iwl.hk/',
            link: 'https://iwl.hk/'
          }
        ]
      },
      {
        dt: 'ecoPayz',
        dd: [
          {
            type: 'text',
            text: 'ecoPayzは現在多くのオンラインカジノに対応した口座ですので作成をお勧めします。'
          },
          { type: 'img', text: 'depositPC-ja-JP-17.jpg' }
        ]
      },
      {
        dt: 'ecoPayzをチェックすると下記画面が表示されます。',
        dd: [
          { type: 'img', width: 50, text: 'depositPC-ja-JP-18.jpg' },
          {
            type: 'text',
            width: 50,
            text: `<p>上から順に項目をお間違えなく入力してください。</p>
              <ul class="num-li num-li-n">
                <li>金額を選択してください（最低10ドル）</li>
                <li>ご入金額を入力後、【続ける】ボタンをクリックしてください。</li>
                <li>EcoPayzのアカウントへと誘導しますので、ログインして内容を確認してください。</li>
              </ul>
              <p>
                これにて、ご入金がクイーンカジノのアカウントに反映されます。
                <br />
                ecoPayzのアカウントをまだお持ちでない場合は、こちらのURLからご登録ください。
              </p>
              <a href="https://www.ecopayz.com/ja-jp/ecopayz"  class="a-link card-c" target="_blank">https://www.ecopayz.com/ja-jp/ecopayz</a>`
          }
        ]
      }
    ]
  },
  depositH5: {
    tu: {
      tu1: '入金ボタンをクリック',
      tu2: '入金タイプを選択',
      tu3: '必要情報を入力し入金完了'
    },
    content: [
      {
        dt: 'スマートフォンからの入金方法',
        dd: {
          title: {
            type: 'text',
            text: '入金はQUEEN CASINOへログイン後、下記の3ステップで完了します。',
            color: 'card-c-red'
          },
          content: []
        },
        tu: true
      },
      {
        dt: '入金ボタンをクリックし、入金画面へ進みます',
        dtg: true,
        dd: {
          title: null,
          content: [
            { type: 'img', text: 'depositH5-ja-JP-1.jpg' },
            {
              type: 'text',
              text: [
                `<ul class="num-li">
                        <li>
                          <p>
                            ログイン後、
                            <strong class="marker-yellow">上部メニューバーの右側に表示されているメニューボタンをタップ</strong>
                            すると、
                            <strong class="marker-yellow">マイメニュー項目が表示</strong>
                            されます。
                          </p>
                        </li>
                        <li>
                          <p>
                            メニュー項目の中から
                            <strong class="marker-yellow">【入金する】をタップ</strong>
                            してください。
                          </p>
                        </li>
                        <li>
                          <p>
                            入金ページに移動されますので、
                            <strong class="marker-yellow">ご希望の入金方法をチェックして選択</strong>
                            してください。
                          </p>
                        </li>
                      </ul>
              `
              ]
            }
          ]
        }
      },
      {
        dt: '銀行振込',
        dd: {
          title: {
            type: 'text',
            text: `QUEEN CASINO では
                    <strong class="marker-yellow">銀行振込による入金に対応</strong>
                    しており、とても
                    <strong class="marker-yellow">手軽に即時入金</strong>
                    ができます。`
          },
          content: [
            { type: 'img', text: 'depositH5-ja-JP-2.jpg' },
            {
              type: 'text',
              text: ` <p>
                      入金方法から
                      <strong class="marker-yellow">銀行振込 を選択</strong>
                      します。
                    </p>`
            }
          ]
        }
      },
      {
        dt: '銀行振込をチェックすると下記画面が表示されます。',
        dtg: true,
        dd: {
          content: [
            { type: 'img', text: 'depositH5-ja-JP-3.jpg' },
            {
              type: 'text',
              text: ` <p>
                    <strong class="marker-yellow">
                      入金したい金額（最低20ドル）をご入力いただき、【入金する】ボタンをクリック
                    </strong>
                    してください。
                  </p>`
            }
          ]
        }
      },
      {
        dt: 'Hi-BANQ のページに移動します。',
        dtg: true,
        dd: {
          content: [
            { type: 'img', text: 'depositH5-ja-JP-4.jpg' },
            {
              type: 'text',
              text: ` <p>
                    内容をご確認の上、
                    <strong class="marker-yellow">【入金する】ボタンをクリック</strong>
                    してください。
                  </p>`
            }
          ]
        }
      },
      {
        dt: '入金申請手続きが完了すると、メールが届きます。',
        dtg: true,
        dd: {
          content: [
            { type: 'img', text: 'depositH5-ja-JP-5.jpg' },
            {
              type: 'text',
              text: `<p>
                <strong class="marker-yellow">数分以内にHi-BANQから入金案内に関するメールが届きます</strong>
                ので、ご確認ください。
              </p>`
            }
          ]
        }
      },
      {
        dt: 'クレジットカード',
        dd: {}
      },
      {
        dt: 'クレジットカードでの入金',
        dtg: true,
        dd: {
          title: {
            type: 'text',
            text: `<p>クイーンカジノのクレジットカード決済は、6ブランドに対応しております。<br>
                  <a href="https://www.queen-casino.com/ja-jp/promotions/promo-creditcard-information" class="a-link card-c" target="_blank">VISAも利用可能になりました</a></p>`
          },
          content: [
            { type: 'img', text: 'depositH5-ja-JP-6.jpg' },
            {
              type: 'text',
              text: `<p>
                入金方法から
                <strong class="marker-yellow">クレジットカードを選択</strong>
                します。
              </p>`
            },
            { type: 'img', text: 'depositH5-ja-JP-7.jpg' },
            {
              type: 'text',
              text: `<p>上から順に項目をお間違えなく入力してください。</p>
                  <ul class="num-li">
                    <li>
                      <p>入金する金額（最低10ドル）</p>
                    </li>
                    <li>
                      <p>クレジットカード番号（16桁）</p>
                    </li>
                    <li>
                      <p>有効期限（月・年）</p>
                    </li>
                    <li>
                      <p>CVV番号（3桁）※通常カード裏面の署名欄右側当たりにあります</p>
                    </li>
                    <li>
                      <p>お名前</p>
                    </li>
                    <li>
                      <p>市区町村／番地</p>
                    </li>
                    <li>
                      <p>都道府県</p>
                    </li>
                    <li>
                      <p>国</p>
                    </li>
                    <li>
                      <p>電話番号</p>
                    </li>
                    <li>
                      <p>メールアドレス</p>
                    </li>
                  </ul>
                  <p>
                    上記の項目を全てご入力頂き
                    <strong class="marker-yellow">【入金する】ボタンをクリックすると入金処理が開始</strong>
                    されます。
                  </p>`
            }
          ]
        }
      },
      {
        dt: 'WlandPay での入金',
        dtg: true,
        dd: {
          title: {
            type: 'text',
            text: 'クイーンカジノのクレジットカード決済は、WlandPayと提携しております。'
          },
          content: [
            { type: 'img', text: 'depositH5-ja-JP-8.jpg' },
            {
              type: 'text',
              text: ` <p>
                入金方法から
                <strong class="marker-yellow">WlandPay を選択</strong>
                します。
              </p>`
            },
            { type: 'img', text: 'depositH5-ja-JP-9.jpg' },
            {
              type: 'text',
              text: `<p>上から順に項目をお間違えなく入力してください。</p>
              <ul class="num-li">
                <li>
                  <p>入金する金額（最低10ドル）</p>
                </li>
                <li>
                  <p>クレジットカード番号（16桁）</p>
                </li>
                <li>
                  <p>有効期限（月・年）</p>
                </li>
                <li>
                  <p>CVV番号（3桁）※通常カード裏面の署名欄右側当たりにあります</p>
                </li>
                <li>
                  <p>電話番号</p>
                </li>
              </ul>
              <p>
                上記の項目を全てご入力頂き
                <strong class="marker-yellow">【入金する】ボタンをクリックすると入金処理が開始</strong>
                されます。
              </p>`
            }
          ]
        }
      },
      {
        dt: '仮想通貨 CoinPayment',
        dd: {
          title: { type: 'text', text: 'ビットコインをはじめ、数多くの仮想通貨で入金が行えます。' },
          content: [
            { type: 'img', text: 'depositH5-ja-JP-10.jpg' },
            {
              type: 'text',
              text: ` <p>
                入金方法から
                <strong class="marker-yellow">WlandPay を選択</strong>
                します。
              </p>`
            }
          ]
        }
      },
      {
        dt: 'Coinpaymentをチェックすると下記画面が表示されます。',
        dtg: true,
        dd: {
          content: [
            { type: 'img', text: 'depositH5-ja-JP-11.png' },
            {
              type: 'text',
              text: `<p>項目をお間違えなく入力してください。</p>
                        <ul class="num-li">
                          <li>仮想通貨の選択</li>
                          <li>入金する金額</li>
                        </ul>
                        <p>をご入力頂き、【アドレスを生成】をクリックすると、振込先のアドレスの生成が開始されます。</p>
                      `
            }
          ]
        }
      },
      {
        dt: '生成アドレスをコピーし、仮想通貨アプリから振込を完了してください。',
        dtg: true,
        dd: {
          title: { type: 'text', text: 'ビットコインをはじめ、数多くの仮想通貨で入金が行えます。' },
          content: [
            { type: 'img', text: 'depositH5-ja-JP-12.jpg' },
            {
              type: 'text',
              text: `<p>アドレスが生成されたら、クリックしアドレスをコピーします。</p>
                      <p>
                        あとはお客様の仮想通貨アプリから、アドレス宛に振込をしてください。
                        <br />
                        お振込の着金が確認できた時点で、すぐに入金反映されます。
                      </p>`
            }
          ]
        }
      },
      {
        dt: 'iWallet',
        dd: {
          title: {
            type: 'text',
            text: 'iWalletは現在多くのオンラインカジノに対応した口座ですので作成をお勧めします。'
          },
          content: [
            { type: 'img', text: 'depositH5-ja-JP-13.jpg' },
            {
              type: 'text',
              text: `<p>
                        入金方法から
                        <strong class="marker-yellow">iWallet を選択</strong>
                        します。
                      </p>`
            }
          ]
        }
      },
      {
        dt: 'iWalletをチェックすると下記画面が表示されます。',
        dtg: true,
        dd: {
          content: [
            { type: 'img', text: 'depositH5-ja-JP-14.jpg' },
            {
              type: 'text',
              text: ` <p>上から順に項目をお間違えなく入力してください。</p>
                        <ul>
                          <li>
                            <p>入金する金額（最低10ドル）</p>
                          </li>
                          <li>
                            <p>入金ボーナスの希望（ボーナスがある場合）</p>
                          </li>
                        </ul>
                        <p>
                          をご入力頂き、
                          <strong class="marker-yellow">【入金する】をタッチしていただくと入金処理が開始</strong>
                          されます。
                        </p>
                        <p>
                          ご入力するアカウントIDやセキュアIDが不明な場合はiWalletにログインしてご確認ください。
                          <br />
                          iWalletのアカウントをまだお持ちでない場合は、下記のURLからユーザー登録を行えます。
                        </p>
                        <a
                          href="https://iwl.hk/"
                          target="_blank"
                          class="a-link card-c"
                        >
                          https://iwl.hk/
                        </a>
                      </div>`
            }
          ]
        }
      },
      {
        dt: 'ecoPayz',
        dd: {
          title: {
            type: 'text',
            text: 'ecoPayzは現在多くのオンラインカジノに対応した口座ですので作成をお勧めします。'
          },
          content: [
            { type: 'img', text: 'depositH5-ja-JP-15.jpg' },
            {
              type: 'text',
              text: `<p>
                        入金方法から
                        <strong class="marker-yellow">ecoPayz を選択</strong>
                        します。
                      </p>`
            }
          ]
        }
      },
      {
        dt: 'ecoPayzをチェックすると下記画面が表示されます。',
        dtg: true,
        dd: {
          content: [
            { type: 'img', text: 'depositH5-ja-JP-16.jpg' },
            {
              type: 'text',
              text: `<p>上から順に項目をお間違えなく入力してください。</p>
                      <ul class="num-li num-li-n">
                        <li>金額を選択してください（最低10ドル）</li>
                        <li>ご入金額を入力後、【続ける】ボタンをクリックしてください。</li>
                        <li>EcoPayzのアカウントへと誘導しますので、ログインして内容を確認してください。</li>
                      </ul>
                      <p>
                        これにて、ご入金がクイーンカジノのアカウントに反映されます。
                        <br />
                        ecoPayzのアカウントをまだお持ちでない場合は、こちらのURLからご登録ください。
                      </p>
                      <a
                        href="https://www.ecopayz.com/ja-jp/ecopayz"
                        target="_blank"
                        class="a-link card-c"
                      >
                        https://www.ecopayz.com/ja-jp/ecopayz
                      </a>`
            }
          ]
        }
      }
    ]
  },
  withdrawHelp: {
    content: [
      {
        dt: '出金方法について',
        dd: {
          content: []
        }
      },
      {
        dt: 'ログイン後、出金ページに移動します。',
        dtg: true,
        dd: {
          title: {
            type: 'text',
            text: 'ecoPayzは現在多くのオンラインカジノに対応した口座ですので作成をお勧めします。'
          },
          content: [
            { type: 'img', text: 'withdraw-1.jpg' },
            {
              type: 'text',
              text: ` <p>
                        ログイン後、画面ページ上部に表示されているマイアカウントメニューより
                        <span class="marker-yellow">【出金】ボタンをクリック</span>
                        してください。
                      </p>
                      <p>出金手続きの入力ページへと移動します。</p>`
            }
          ]
        }
      },
      {
        dt: '出金方法を選択します。',
        dtg: true,
        dd: {
          title: {
            type: 'text',
            text: 'お客様がご希望の出金方法を表示されている項目の中からお選びいただけます。'
          },
          content: [
            { type: 'img', text: 'withdraw-2.jpg' },
            {
              type: 'text',
              text: ` <p>
                    現在クイーンカジノでは、
                    <strong class="marker-yellow">こちらの出金方法がございます。</strong>
                  </p>`
            }
          ]
        }
      },
      {
        dt: '銀行振込でのご出金の場合',
        dd: {
          content: [
            { type: 'img', text: 'withdraw-3.jpg' },
            {
              type: 'text',
              text: `<p>
                          下記の必要事項を記入していただき、
                          <strong class="marker-yellow">最後に出金をクリックしていただきますと、出金申請完了</strong>
                          となります。
                        </p>
                        <ul class="num-li">
                          <li>
                            <p>金額</p>
                          </li>
                          <li>
                            <p>銀行名</p>
                          </li>
                          <li>
                            <p>口座名義</p>
                          </li>
                          <li>
                            <p>口座番号</p>
                          </li>
                          <li>
                            <p>支店</p>
                          </li>
                          <li>
                            <p>都道府県</p>
                          </li>
                          <li>
                            <p>市</p>
                          </li>
                        </ul>
                        <p>上記の入力作業が完了したら、【出金】ボタンをクリックしてください。</p>
                        <div class="pick-box-2">
                          <p>
                            【住所】記入欄には都道府県を除いた住所を入力し、【県】記入欄に都道府県をご入力ください。
                            <br />
                            ご利用通貨はUSD(米ドル)のみ選択できます。
                          </p>
                        </div>`
            }
          ]
        }
      },
      {
        dt: 'これにて出金申請手続き完了です。',
        dtg: true,
        dd: {
          content: [
            { type: 'img', text: 'withdraw-4.jpg' },
            {
              type: 'text',
              text: `<p>
                        <strong class="marker-yellow">こちらの画面が表示されましたら、出金申請手続きが完了</strong>
                        です。
                      </p>
                      <p>出金手続きが承認されるまで、少々お待ちくださいませ。</p>`
            }
          ]
        }
      }
    ]
  },
  'registration-guide': {
    content: [
      {
        dt: 'QUEEN CASINOのユーザー登録方法',
        dd: {
          width: 100,
          content: [
            {
              type: 'text',
              text: `<p>クイーンカジノのユーザー登録は、とても簡単です。<br>
                        もちろん、スマホでもPCでもどちらでも簡単に登録が出来ます。<br>
                        このページではパソコンでのユーザー登録方法を分かりやすくご案内します。</p>`
            }
          ]
        }
      },
      {
        dt: 'ユーザー登録ページへ移動します。',
        dtg: true,
        dd: {
          width: 100,
          content: [
            { type: 'img', text: 'login-ja-JP-1.jpg' },
            {
              type: 'text',
              text: `<p>ページ上部のヘッダーバーから<span class="marker-yellow">【ユーザー登録】ボタンをクリック</span>して下さい。<br>
                      新規ユーザーアカウント開設の入力画面ページへと移動します。</p>`
            }
          ]
        }
      },
      {
        dt: 'メールアドレス・パスワード・必要情報をご記入下さい。',
        dtg: true,
        dd: {
          title: {
            type: 'text',
            text: `<p>空欄を全てご入力し、利用規約を読み【利用規約とプライバシーポリシーに同意する】をチェック。<br>
                      <strong class="marker-yellow">全ての入力が完了したら【新規登録】をクリックしてください。</strong></p>`
          },
          width: 100,
          content: [
            { type: 'img', text: 'login-ja-JP-2.png' },
            {
              type: 'text',
              text: `<p>入力項目は上から順に</p>
                        <ul class="num-li">
                          <li>メール</li>
                          <li>6桁のコード</li>
                          <li>パスワード</li>
                          <li>紹介コード</li>
                          <li>利用規約とプライバシーポリシーに同意する</li>
                        </ul>
                        <p><strong class="marker-yellow">入力作業が完了したら、【新規登録】ボタンをクリック</strong>してください。</p>
                      `
            }
          ]
        }
      },
      {
        dt: 'メールアドレス認証を行います。',
        dtg: true,
        dd: {
          title: {
            type: 'text',
            text: `<p>登録したメールアドレスに<strong class="marker-yellow">メールアドレス認証に関するメールが届きます。</strong></p>`
          },
          width: 100,
          content: [
            { type: 'img', text: 'login-ja-JP-3.jpg' },
            {
              type: 'text',
              text: `<p>メール本文に記載されている<strong class="marker-yellow">「メールアドレス認証」ボタンをクリック</strong>してください。</p>`
            }
          ]
        }
      },
      {
        dt: 'これにてユーザー登録は完了です。',
        dtg: true,
        dd: {
          width: 100,
          content: [
            { type: 'img', text: 'login-ja-JP-4.jpg' },
            {
              type: 'text',
              text: `<p>こちらの登録画面が表示されますとユーザー登録は完了となります。<br>
                          ログインしてクイーンカジノをお楽しみください。</p>`
            }
          ]
        }
      }
    ]
  },
  'registration-mobile-guide': {
    content: [
      {
        dt: 'QUEEN CASINOのユーザー登録方法',
        dd: {
          width: 100,
          content: [
            {
              type: 'text',
              text: `<p>クイーンカジノのユーザー登録は、とても簡単です。<br>
                        もちろん、スマホでもPCでもどちらでも簡単に登録が出来ます。<br>
                        このページではパソコンでのユーザー登録方法を分かりやすくご案内します。</p>`
            }
          ]
        }
      },
      {
        dt: 'まずは公式サイトへアクセス',
        dtg: true,
        dd: {
          width: 50,
          content: [
            { type: 'img', text: 'login-h5-ja-JP-1.jpg' },
            {
              type: 'text',
              text: `<p>ページ上部のヘッダーバーから<span class="marker-yellow">【ユーザー登録】ボタンをクリック</span>して下さい。<br>
                      新規ユーザーアカウント開設の入力画面ページへと移動します。</p>`
            }
          ]
        }
      },
      {
        dt: 'メールアドレス・パスワード・必要情報をご記入下さい。',
        dtg: true,
        dd: {
          title: {
            type: 'text',
            text: `<p>空欄を全てご入力し、利用規約を読み【利用規約とプライバシーポリシーに同意する】をチェック。<br>
                      <strong class="marker-yellow">全ての入力が完了したら【新規登録】をクリックしてください。</strong></p>`
          },
          width: 50,
          content: [
            { type: 'img', text: 'login-h5-ja-JP-2.png' },
            {
              type: 'text',
              text: `<p>入力項目は上から順に</p>
                        <ul class="num-li">
                          <li>メール</li>
                          <li>6桁のコード</li>
                          <li>パスワード</li>
                          <li>紹介コード</li>
                          <li>利用規約とプライバシーポリシーに同意する</li>
                        </ul>
                        <p><strong class="marker-yellow">入力作業が完了したら、【新規登録】ボタンをクリック</strong>してください。</p>
                      `
            }
          ]
        }
      },
      {
        dt: 'メールアドレス認証を行います。',
        dtg: true,
        dd: {
          title: {
            type: 'text',
            text: `<p>登録したメールアドレスに<strong class="marker-yellow">メールアドレス認証に関するメールが届きます。</strong></p>`
          },
          width: 100,
          content: [
            { type: 'img', text: 'login-h5-ja-JP-3.jpg' },
            {
              type: 'text',
              text: `<p>メール本文に記載されている<strong class="marker-yellow">「メールアドレス認証」ボタンをクリック</strong>してください。</p>`
            }
          ]
        }
      },
      {
        dt: 'これにてユーザー登録は完了です。',
        dtg: true,
        dd: {
          width: 50,
          content: [
            { type: 'img', text: 'login-h5-ja-JP-4.jpg' },
            {
              type: 'text',
              text: `<p>こちらの登録画面が表示されますとユーザー登録は完了となります。<br>
                          ログインしてクイーンカジノをお楽しみください。</p>`
            }
          ]
        }
      }
    ]
  },
  'guide-bonus': {
    content: [
      {
        dt: 'ボーナスについて',
        dd: {
          width: 100,
          content: [
            {
              type: 'text',
              text: `<p>クイーンカジノでは、ご入金に対して<strong class="marker-yellow">様々な種類の入金ボーナスや、入金不要の特別ボーナス</strong>を進呈しております。</p>
                      <p>ボーナスは、お客様がより多くプレイしたり、より大きな勝利を得ていただくために、進呈しております。<br>
                      <strong class="marker-yellow">ボーナスを出金するためには一定の条件をクリアしていただく必要</strong>が有ります。</p>
                      <p>ボーナスを獲得するか、しないかを、お客様は自由に選ぶことができますので、条件をご確認の上、プレイするゲームや、戦略によって、獲得するかどうかをお決めください。</p>
                      `
            }
          ]
        }
      },
      {
        dt: 'クイーンカジノのボーナスについて',
        dd: {
          width: 100,
          content: [
            {
              type: 'text',
              text: `<p><strong class="marker-yellow">クイーンカジノのボーナスは、入金してボーナスを獲得すると、どちらも一緒にボーナスカウントに反映されます。</strong>勝ったり負けたりしながら入金額とボーナス額の合計の〇〇倍を賭けると、現金アカウントに資金が移動して、出金できるようになります。</p>`
            },
            {
              type: 'text',
              text: `
                        <table class="bordered">
                          <thead>
                            <tr>
                              <th class="center" width="20%">&nbsp;</th>
                              <th class="center">分離型</th>
                              <th class="center new-td">一体型</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th class="center" scope="row">入金後のアカウント</th>
                              <td>入金額は現金アカウントに<br>
                              ボーナスはボーナスアカウントに</td>
                              <td class="new-td"><strong class="marker-yellow">入金額＋ボーナス額の合計</strong>がボーナスアカウントに入金される</td>
                            </tr>
                            <tr>
                              <th class="center" scope="row">100ドル入金してボーナス150ドル獲得</th>
                              <td>100ドルは現金アカウントに、150ドルはボーナスアカウントに</td>
                              <td class="new-td"><strong class="marker-yellow">250ドル</strong>がボーナスアカウントに入金される</td>
                            </tr>
                            <tr>
                              <th class="center" scope="row">消費されるのは？</th>
                              <td>現金から消費され、現金残高が０になると、自動的にボーナスアカウントから消費される</td>
                              <td class="new-td"><strong class="marker-yellow">現金アカウントと、入金に紐づけされていないボーナスがある場合は、ボーナスアカウントの残高から消費</strong>されます。</td>
                            </tr>
                            <tr>
                              <th class="center" scope="row">出金条件は？</th>
                              <td>ボーナスの○○倍</td>
                              <td class="new-td"><strong class="marker-yellow">（入金＋ボーナス）の○○倍</strong></td>
                            </tr>
                            <tr>
                              <th class="center" scope="row">出金条件のカウントはいつから？</th>
                              <td>現金残高が無くなり、ボーナスでベットした金額のみが対象</td>
                              <td class="new-td">ボーナスを獲得して、<strong class="marker-yellow">ベット開始したときから</strong></td>
                            </tr>
                            <tr>
                              <th class="center" scope="row">出金はいつできる？</th>
                              <td>入金額と同額ベットしたら、ボーナスは削除されますが、いつでも出金可能</td>
                              <td class="new-td last"><strong class="marker-yellow">ボーナス出金条件をクリアするまでは、1ドルも出金できません</strong></td>
                            </tr>
                          </tbody>
                        </table>
                      `
            }
          ]
        }
      },
      {
        dt: 'ボーナスの申請・獲得する方法',
        dd: {
          width: 100,
          content: [
            {
              type: 'text',
              text: 'クイーンカジノでは、おトクなボーナスを多数ご用意しております。このページではボーナスの申請・獲得する方法を分かりやすくご案内します。'
            }
          ]
        }
      },
      {
        dt: 'ボーナスページへ移動し獲得できるボーナスを申請します。',
        dtg: true,
        dd: {
          width: 100,
          content: [
            {
              type: 'text',
              text: `
                  <p>獲得できる<strong class="marker-yellow">ボーナスがある場合、ヘッダーメニューに通知が表示</strong>されます。マイアカウントメニューを開き、ボーナスページへ移動します。</p>
                `
            },
            { type: 'img', text: 'bonus-ja-JP-1.jpg' },
            {
              type: 'text',
              text: `
                  <p>ボーナスページへ移動されます。 ご希望のボーナスを見つけて、<strong class="marker-yellow">「獲得する」ボタンをクリック</strong>してください。</p>
                `
            },
            { type: 'img', text: 'bonus-ja-JP-2.jpg' },
            {
              type: 'text',
              text: `
                 <p>確認画面がポップアップ表示されます。 内容にお間違いがなければ<strong class="marker-yellow">「YES」をクリックし、ボーナス申請を完了</strong>します。</p>
                `
            },
            { type: 'img', text: 'bonus-ja-JP-3.jpg' }
          ]
        }
      },
      {
        dt: 'ボーナス申請が完了すると、メッセージが表示されます。',
        dtg: true,
        dd: {
          width: 100,
          content: [
            { type: 'img', text: 'bonus-ja-JP-4.jpg' },
            {
              type: 'text',
              text: 'ボーナスの進呈が完了すると、マイメニュー内のボーナス残高にボーナスが反映されます。ボーナスを利用して、クイーンカジノをお楽しみください。'
            }
          ]
        }
      }
    ]
  },
  vipRule: {
    ruleTitle: `
      <p>ワンランク上のステータスをあなたへ。</p>
          <p>
            オンラインカジノ最高クラスの
            <br />
            VIPメンバーシップ特典
          </p>`,
    ruleTitleD: `いつもクイーンカジノを楽しまれているお客さまへさらに満足のゆく体験をご用意しております。クイーンカジノVIPメンバーシップでは以下のステータスに応じて、特別な幅広い特典をご利用いただけます。`,
    vipLevelD: '運営チームよりご招待のご案内を差し上げたプレイヤー様のみVIPメンバーに昇格されます。',
    ruleLevel: [
      { level: '', rule: '入金額: 100,000 USD', imgClass: 'diamond', tips: '' },
      { level: 'シルバー', rule: '入金額: 50,000 USD', imgClass: 'platinum', tips: '' },
      { level: 'ゴールド', rule: '入金額: 10,000 USD', imgClass: 'gold', tips: '' },
      { level: 'プラチナ', rule: '入金額: 5,000 USD', imgClass: 'silver', tips: '' },
      { level: 'ダイヤモンド', rule: '入金額: 2,000 USD', imgClass: 'bronze', tips: '' }
    ],
    hTitle1: 'VIPメンバーシップに必要な昇格条件',
    hTitle2: 'Queen Casino VIPステータス特典',
    hTitle3: 'クイーンカジノVIPメンバーシッププログラム利用規約',
    ruleTable: `
          <table class="table">
            <thead>
              <tr>
                <th
                  class="blank"
                  style="width: 20%"
                >
                  &nbsp;
                </th>
                <th
                  class="bronze-th"
                  style="width: 16%"
                >
                  ブロンズ
                </th>
                <th
                  class="silver-th"
                  style="width: 16%"
                >
                  シルバー
                </th>
                <th
                  class="gold-th"
                  style="width: 16%"
                >
                  ゴールド
                </th>
                <th
                  class="platinum-th"
                  style="width: 16%"
                >
                  プラチナ
                </th>
                <th
                  class="diamond-th"
                  style="width: 16%"
                >
                  ダイヤモンド
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>VIP昇格・継続条件(月間)</td>
                <td>
                  賭け金総額: 40,000 ドル
                  <br />
                  入金額 : 2,000 ドル
                </td>
                <td>
                  賭け金総額: 100,000 ドル
                  <br />
                  入金額 : 5,000 ドル
                </td>
                <td>
                  賭け金総額: 200,000 ドル
                  <br />
                  入金額 : 10,000 ドル
                </td>
                <td>
                  賭け金総額: 1,000,000 ドル
                  <br />
                  入金額 : 50,000 ドル
                </td>
                <td>
                  賭け金総額: 2,000,000 ドル
                  <br />
                  入金額 : 100,000 ドル
                </td>
              </tr>
              <tr>
                <td>
                  VIPウェルカムボーナス
                  <br />
                  （初めてランクに昇格された際に獲得）
                </td>
                <td>30 ドル</td>
                <td>50 ドル</td>
                <td>100 ドル</td>
                <td>120 ドル</td>
                <td>150 ドル</td>
              </tr>
              <tr>
                <td>
                  VIPランクボーナス
                  <br />
                  （ランクの継続、2回目以降、毎月審査後に獲得）
                </td>
                <td>30 ドル</td>
                <td>50 ドル</td>
                <td>100 ドル</td>
                <td>120 ドル</td>
                <td>150 ドル</td>
              </tr>
              <tr>
                <td>VIPコンシェルジュ</td>
                <td
                  colspan="5"
                  style="text-align: center"
                >
                  専属VIPコンシェルジュ ＋ LINE電話対応サービス（VIPご招待メールにLINE URL記載）
                </td>
              </tr>
              <tr>
                <td>入出金の優先処理</td>
                <td
                  colspan="4"
                  style="text-align: center"
                >
                  あり
                </td>
                <td>最優先</td>
              </tr>
              <tr>
                <td rowspan="2">1回あたりの入金限度額</td>
                <td>10,000ドル以上</td>
                <td>20,000ドル以上</td>
                <td>30,000ドル以上</td>
                <td
                  colspan="2"
                  style="text-align: center"
                >
                  応相談
                </td>
              </tr>
              <tr>
                <td colspan="5">
                  1回あたりの入金限度額引き上げをご希望の場合は、VIP専用LINE、
                  <a href="mailto:support@queen-casino.com">support@queen-casino.com</a>
                  、チャットサポートからご相談くださいませ。
                </td>
              </tr>
              <tr>
                <td rowspan="2">出金限度額</td>
                <td
                  colspan="3"
                  style="text-align: center"
                >
                  1週間5,000ドル以上、1か月20,000ドル以上
                </td>
                <td>1週間100,000ドル以上、1か月400,000ドル以上</td>
                <td>出金上限・無制限</td>
              </tr>
              <tr>
                <td colspan="4">
                  出金上限額引き上げをご希望の場合は、VIP専用LINE、
                  <a href="mailto:support@queen-casino.com">support@queen-casino.com</a>
                </td>
                <td>ご希望額、即時全額出金可能</td>
              </tr>
              <tr>
                <td>
                  バースデーボーナス
                  <br />
                  <a href="/promo">
                    バースデーボーナスの規約はこちら
                  </a>
                </td>
                <td>バーズデーボーナス ＋30ドル</td>
                <td>バーズデーボーナス ＋50ドル</td>
                <td>バーズデーボーナス ＋100ドル</td>
                <td>バーズデーボーナス ＋120ドル</td>
                <td>バーズデーボーナス ＋150ドル</td>
              </tr>
              <tr>
                <td>特別オファーボーナス＋α</td>
                <td
                  colspan="3"
                  style="text-align: center"
                >
                  ご利用実績に応じて特別オファーボーナスを進呈いたします。
                </td>
                <td>もれなく全員特別オファーボーナスを進呈いたします。</td>
                <td>ランドカジノVIP待遇ご招待等、お楽しみが沢山あります。</td>
              </tr>
              <tr>
                <td>VIPウエルカムギフト</td>
                <td
                  colspan="5"
                  style="text-align: center"
                >
                  毎月抽選によりご当選されたお客様にくいかじオリジナルグッズ進呈
                </td>
              </tr>
            </tbody>
          </table>`,
    ruleList: [
      'クイーンカジノVIPメンバーシッププログラムは、完全招待制です。担当部署の、厳正な審査を通過されたお客様のみが、招待されます。',
      'VIPランクの査定対象期間は毎月1日0:00(日本時間) 〜 毎月月末23:59(日本時間)となります。',
      '毎月5日に、新しいVIPランクが決定します。対象の会員様には、メールにてランクのご案内いたします。',
      'VIPウェルカムボーナス、VIPランクボーナスの出金条件は進呈金額の40倍で、出金上限金額は、進呈額の10倍となります。また、出金条件をクリアするまで、1度の賭けの最大賭け金額が10USDとなりますので、ご注意下さい。',
      'VIPランクを継続するためには、各ランクごとに必要な、入金額、賭け金総額を満たす必要がございます。条件を満たされない場合は、残念ながらランクが降格されます。',
      'クイーンカジノは、クイーンカジノの利用規約に反する行為が発覚した場合には、VIPメンバーシップを剝奪する権利を有します。',
      'クイーンカジノは、事前通知なく、VIPメンバーシッププログラムの変更、修正、追加又は無効にする権利を有します。',
      'クイーンカジノは、VIPメンバーシップの付与決定を判断、または拒否する権利を有します。'
    ]
  },
  sitemap: {
    title: 'サイトマップ'
  },
  contact: {
    title: 'お問い合わせ',
    supportEmail: 'サポートメール',
    emailAddress: 'メールアドレス',
    emailAddressPlaceholder: 'お客様のメールアドレスを入力',
    emailAddressRoleMsg: 'メールの構文が無効です',
    fullName: 'お名前',
    fullNamePlaceholder: 'お名前',
    mobileNumber: '携帯番号',
    sendTitle: '題名',
    sendTitlePlaceholder: '題名',
    sendMessage: 'お問い合わせ内容',
    sendMessagePlaceholder: 'お問い合わせ内容を入力してください。',
    fileLabel: 'ファイルを添付する',
    fileSelect: 'ファイル選択 ',
    fileRequire_1: 'アップロード可能なファイル数 : 5',
    fileRequire_2: 'アップロード可能な最大ファイルサイズ : 5MB',
    fileRequire_3: `アップロード可能ファイル形式 : <br>image/gif, image/jpeg, image/png, application/pdf`,
    fileType: `image/gif, image/jpeg, image/png, application/pdf`,
    noEmpty: '必須フィールド',
    send: '送信する'
  }
} as const
