export const brand = {
  // export const BRAND = {
  name: 'Brand Story',
  mileStone: 'Milestones',
  detail: 'Detail',
  event: 'Event',
  importantEvent: 'Important Event',
  eventDetail: 'Event Detail',
  more: 'More',
  totalPage: 'Total {{0}} Pages',
  january: 'January',
  february: 'February',
  march: 'March',
  april: 'April',
  may: 'May',
  june: 'June',
  july: 'July',
  august: 'August',
  september: 'September',
  october: 'October',
  november: 'November',
  december: 'December',
  skip: 'Skip',
  back: 'BACK',
  next: 'NEXT',
  add: 'Add Game',
  addTxt:
    'Click 『+』 icon on the upper tab, select a game and click to add, the game screen will be displayed successfully, and you can bet on the game!',
  fast: 'Quick Deposit',
  fastTxt:
    'Select 『Deposit』 from the floating button, perform the deposit operation, complete the deposit and prompt "Successfully deposit", and wait for the system to score!',
  quit: 'Quit Game',
  quitTxt:
    'Select the game, long press the tab or game screen, select 『Quit Game』, a confirmation popup window will be displayed,  and click 『Confirm』',
  instructions: 'View instructions',
  instructionsTxt:
    'Go to the game management page, select a game screen and long press the popup window, select 『Instructions』, then you can see the operation of multi-window mode again!',
  illustrate: 'Button description',
  illustrateTxt:
    'Hide Tab － Hide game tab pagination<br/>Deposit－Show quick deposit popup window<br/>Add Game－Add games<br/>Homepage－Return to QueenCasino Home page',
  hide: 'Hide Tab',
  hideTxt:
    'Select "Hide" in the floating button, and the tab paging will be hidden, and click again to display it',
  switch: 'Switch Game',
  switchTxt:
    'Swipe left or right or tap the upper tab page to switch games, small window tab to switch the game screen of the small window',
  small: 'Retrieve Small Window',
  smallTxt:
    'Go to the game management page, select a game screen and long press on it to display the function popup window, select 『Open small window』, then the small window will be restored.'
} as const
