export const statements = {
  title: '取引履歴',
  filterExactId: 'ID検索',
  filterStatementType: 'ステートメントタイプ',
  filterFrom: '開始日',
  filterTo: '終了日',
  filterPlaceholder: 'タイプの選択',
  filterPlaceholderId: 'ID検索',
  submit: '送信',
  clear: '削除',
  id: 'ID',
  date: '日付',
  type: '種類',
  debit: 'デビット',
  credit: 'クレジット',
  balance: '残高',
  bonusBalance: 'ボーナス残高',
  status: '状況',
  optionsDeposit: '入金',
  optionsWithdraw: '出金',
  optionsWithdrawReject: '出金拒否',
  optionsWithdrawCancel: '出金がキャンセルされました',
  optionsWithdrawPromotion: 'プロモーション',
  optionsWithdrawPromotionDeduction: '割引プロモーション',
  optionsPromotionTransferIn: 'プロモーション転送イン',
  optionsPromotionTransferOut: 'プロモーション転送アウト',
  optionsGameTransferIn: 'ゲーム転送イン',
  optionsGameTransferOut: 'ゲーム転送アウト',
  optionsGameTransferToLocal: 'ゲームをローカルに転送',
  optionsLocalTransferToGame: 'ゲームへのローカル転送',
  affiliateCommission: 'アフィリエイトコミッション',
  status0: '処理',
  status1: 'ユーザーキャンセル',
  status2: '撤回を拒否する',
  status3: 'キャンセルのリクエスト',
  status4: '終了',
  status5: '失敗しました',
  noDate: '表示できる項目はありません。',
  withdrawalFee: '出金手数料',
  washCode: 'レーキバック',
  commission: 'アフィリエイト報酬',
  voucher_0048: 'クーポン - 預金',
  voucher_0049: 'クーポン - 受け取った',
  voucher_0050: 'クーポン - ロストライトの払い戻し',
  voucher_0013: '進行中',
  voucher_0014: '受取済み',
  voucher_0015: 'なくなりました',
  voucher_0016: '期限切れ',
  voucher_0017: '放棄',
  voucher_0018: '利用可能',
  vaultText011: 'ボールトデポジット',
  vaultText012: 'ボールトの引き出し',
  optionsOthers: 'その他',
  otherList_QuotaRevision9: 'クォータ ロック - クォータ リビジョン',
  otherList_QuotaRevision10: 'クォータ ロック - クォータを追加',
  otherList_QuotaRevision11: '優先リリース - 割り当て削減',
  otherList_QuotaRevision12: '優先リリース - クォータを追加',
  otherList_QuotaRevision13: 'オファーの有効期限/キャンセル減額',
  otherList_QuotaRevision14: '期限切れ/キャンセルされたオファーのクォータを追加',
  otherList_QuotaRevision: '残高修正'
} as const
