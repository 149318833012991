export const activity = {
  voucher: {
    offer: 'クーポン交換',
    tips: 'クーポンコードはSNS上や特別イベントで獲得できます。各ルールに従いご利用ください。クーポンコードは有効期限内にご利用ください。ご不明点はオ',
    record: '交換履歴',
    enter: 'オファーコードを入力してください',
    receive: '受取',
    code: 'クーポンコード'
  },
  promo: {
    readMore: '詳しく見る',
    goBack: '前に戻る',
    signUp: 'ユーザー登録はこちら',
    status0: '開催前',
    status1: '開催中',
    status2: '終了',
    startDateText: '開始日 {{0}}',
    endDateText: '終了日 {{0}}',
    SUPERTournament: 'SUPER Tournament',
    supplier: 'サプライヤー '
  },
  account: {
    btn: '口座振替',
    title1: 'アカウントのセキュリティのために',
    title2: 'パスワードを入力してください',
    password: 'パスワード',
    tips: 'アルファベット、数字、記号を6～20文字で入力してください',
    confirm: '確認',
    cancel: 'キャンセル',
    title3:
      '{{name}}様 弊社は、お客様にとって最良のサービスを提供することをお約束いたします。サービスの質を高め、より良いゲームを提供するために、Queen Casinoは包括的なシステムアップグレードを行い、アカウントデータを新しいQueen Casino Betaに移行するためのアプリケーションを開始します。現在、https://queen-casino.vip、新しいQueen Casino Betaを見つけられます：',
    tips2:
      'お客様が弊社のプラットフォームに参加し、探索し、我々と貴重な洞察を共有することを心から歓迎いたします。また、お客様の口座の詳細や残高を新しいサイトに速やかに移行していただくことをお勧めします。私たちは、今後もサービスの充実とアップグレードを続けていきます！ データ移行申請を確認すると、(queen-casino.com)のすべてのアカウントデータ情報は、24時間以内に新しいバージョンに迅速に移行されます。よろしくお願い致します！',
    transfer: '今すぐ転送',
    later: '後で転送',
    item1: 'パチンコの実機がオンラインで楽しめます。',
    item2: '7,000以上のスロットマシンゲーム、ライブカジノなどをお楽しみ頂けます。',
    item3: '入金・出金のスピードがアップしました。',
    item4: 'お客様ファーストの考えで、快適でシームレスなユーザー体験を提供いたします。'
  },
  freeSpin: {
    tips: ' ※無料スピンの有効期限は {{expiredTime}} <br /> 分までとなりますので、ご注意ください。'
  },
  vip: {
    title: 'ロイヤルティクラブ',
    level: 'レベル',
    wager: 'ベット',
    levelUpBonus: 'レベルアップクエスト',
    annuallyBonus: 'アニバーサリーボーナス',
    realRebate: 'リアルタイムリベート',
    monthlyBack: 'マンスリーキャッシュバック',
    luckySpin: 'ラッキースピン',
    vipManager: 'VIPマネージャー',
    luxuriousTrip: '豪華旅行',
    register: '登録',
    logFirst: 'まず、ログインしてください',
    levelEd: 'このレベルは達成しました',
    lockedLevel: 'このレベルはアンロックされていません',
    Received: '受取り済',
    claim: '受け取り',
    benefitsUnlocked: '{{0}}つのベネフィットがアンロックされました',
    claimText1: '個人情報が完了しました',
    claimText2: '連絡先情報が認証されました',
    claimText3: 'KYCが認証されました',
    claimTextSuccess: 'ボーナスが入金されました',
    claimSuccess: '受け取り成功',
    claimLevelOne: 'レベル1のボーナスを受け取る',
    maxLevel: '最高レベルに達しました',
    myBenefits: '私のベネフィット',
    wagerTitle: 'ベット',
    legend: 'ロイヤルティクラブ'
  },
  recFriend: {
    title: 'お友達紹介',
    recWay: '紹介方法',
    recLink: '紹介リンク:',
    recCode: '紹介コード:',
    recQrCode: '紹介QRコード:',
    recNum: '紹介者の数',
    recMember: '紹介した友達の数:',
    recEficacy: '有効な友達の数:',
    recRank: 'シリアル番号',
    myFriend: '私の友達',
    regTime: '登録時間',
    isEficacy: '有効な友達',
    copySuc: 'コピー成功',
    noFriends: 'まだお友達を紹介していません。お友達に紹介コードを入力してもらいましょう。',
    more: 'もっと',
    friendsList: '友達リスト',
    yes: 'はい',
    not: 'いいえ',
    copy: 'コピー',
    save: '保存',
    referralMethod: '紹介方法',
    referrer: '紹介結果',
    totalFriendsReferred: '紹介したお友達の人数',
    totalFriendsReferred2: '紹介したお友達',
    totalReferralReward: 'ボーナス履歴',
    totalFriendsFreeSpin: '無料スピン履歴',
    rewardsAvailable: '受け取り可能なボーナス',
    details: '詳細',
    receive: '受け取る',
    rules: 'お友達紹介ルール',
    rulesTitle1: 'VIPレベル',
    rulesTitle2: '入金額(総計)',
    rulesTitle3: '紹介されたお友達が必要な累計ベット額',
    rulesTitle4: '紹介者様へのボーナス',
    rules1Title: '紹介されたお友達',
    rules1Li1: '1.紹介されたお友達は紹介リンク/QRコード/紹介コードよりアカウント登録する必要があります。',
    rules1Li2: '2.アカウント認証にて本人確認をを完了すると、{{spins}}回の無料スピン',
    rules1Li3: '2.合計${{ftd}}以上の入金で、{{spins}}回の無料スピンを獲得できます。',
    rules1Li4:
      '2.KYC（ご本人様確認）を完了後、{{ftd}}ドル以上の入金を行うと{{spins}}回分の無料スピンを獲得できます。',
    rules2Title: '紹介者様',
    rules2Li1_1:
      '1.紹介者様のお友達がアカウント認証にて本人確認を完了、{{spins}}回の無料スピンを獲得できます。',
    rules2Li1_2:
      '1.紹介者様のお友達がアカウント${{ftd}}ドル以上入金すると、{{spins}}回の無料スピンを獲得できます。',
    rules2Li1_3:
      '1.紹介者様のお友達がKYC（ご本人様確認）を完了後、{{ftd}}ドル以上の入金を行うと{{spins}}回分の無料スピンを獲得できます。',
    rules2Li2: '2.紹介者様のお友達のベット額が下記累計金額に達すると紹介者様にボーナスをプレゼントします。',
    receiveTitle: '友達推薦活動',
    receiveIntroduce: '{{0}}米ドルのボーナスがボーナス ウォレットに送金されました。',
    search1: 'プロモーション対象',
    search2: '登録日程',
    search3: '',
    tableNo: '番号',
    tableAccount: 'ユーザー名',
    tableRegTime: '登録時間',
    tableIsEficacy: 'プロモーション対象',
    tableCumulativeRewardContribution: '受け取れるボーナス額',
    tableCumulativeBetAmount: 'お友達のベット額の合計',
    tableAmount: '報酬額',
    tableBonusType: '報酬の種類',
    tableRequiredTurnover: 'ベット条件',
    tableDispatchTime: '獲得日程',
    tableGameName: 'ゲーム名',
    tableFreeSpins: '無料スピン回数',
    tableSpinOnePrice: '1回のゲーム金額',
    total: '合計',
    tableBonusType1: '紹介者の報酬',
    tableBonusType2: 'お友達紹介報酬',
    effective: '対象',
    invalid: '対象外'
  },
  tournament: {
    finishIn: '終了まで',
    days: '日',
    hours: '時',
    min: '分',
    sec: '秒',
    daysMin: '日',
    hoursMin: '時',
    minMin: '分',
    secMin: '秒',
    tournaments: 'トーナメント',
    prize: '賞金',
    all: '全部',
    running: '終了まで',
    announced: '発表済み',
    ended: '終了',
    startIn: '開始は',
    closedAt: '終了は',
    rank: '順位',
    player: 'プレイヤー',
    points: 'スコア',
    now: '本回のスコア',
    outOfRank: 'ランキング外',
    joinNow: '今すぐ参加',
    joined: '参加済み',
    login: 'ログイン',
    getPoints: 'ポイント獲得',
    bonusHistory: 'ボーナス履歴',
    lastBonus: '前回',
    totalBonus: '合計ボーナス',
    receive: '受け取る',
    received: '受け取り済み',
    send: '送る',
    rounds: 'スピン',
    round: 'スピン',
    topGames: '人気ゲーム',
    ranking: 'ランキング',
    me: '私',
    rules: 'ルール',
    unsent: '未送信',
    understand: '確認する',
    receiveFailed: '受け取りに失敗しました',
    receiveSuccess: '受け取りに成功しました',
    receiveFailedTips:
      '申し訳ございませんが、お客様はお受け取りいただけません。詳細につきまして、カスタマーサポートまでご連絡ください。',
    totalPrize: '賞品',
    totalFreeSpin: '無料スピン',
    detail: '詳細',
    betListTitle: 'SUPERトーナメント',
    magnification: '勝利倍率',
    updateMagnification: '今すぐ参加',
    FreeSpins: "無料スピン",
  },
  ticket: {
    rt: 'クイーンカジノ初心者ガイド',
    siteRt: '各種サイトポリシー',
    detailTitle: '抽選券＆賞品詳細',
    detailSelectDate: '期数選択',
    detailSelectStatus: '当選状況',
    search: 'チケット番号を検索する',
    searchTxt: 'チケット番号を入力してください',
    detailIndex: '番号',
    detailNum: 'チケット番号',
    claimDate: '受取時間',
    prize: '賞品詳細',
    totalSize: '{{0}}件',
    totalPage: '共{{0}}ページ',
    goToPage: '{{page0}}移動',
    reviewPast: '過去の振り返り',
    bigWinNum: 'スーパー賞',
    midWinNum: 'ウィークリー賞',
    level0: '特賞',
    level1: '一等賞',
    level2: '二等賞',
    level3: '三等賞',
    level4: '四等賞',
    weeklyRewards: 'ウィークリー賞',
    superRewards: 'スーパー賞',
    marqueeText: 'おめでとうございます！{{0}}さんが{{1}}に当選し、{{2}}を獲得しました。',
    openDateText: `開催時間{{0}} 日 {{1}} 時 {{2}} 分 {{3}} 秒`,
    openedText: '開催済み',
    openingText: '開催中',
    lotSourceEnum1: '登録初回入金',
    lotSourceEnum2: '累計入金額',
    lotSourceEnum3: 'パチンコでのベット',
    lotSourceEnum4: 'スロットでのベット',
    lotSourceEnum5: 'スポーツでのベット',
    lotSourceEnum6: 'ライブカジノでのベット',
    lotSourceEnum7: '後台贈与',
    lotNumText1: `{{0}}枚`,
    lotNumText2: `\${{0}}/枚`,
    lotNumText3: `\${{0}}/{{1}}枚`,
    claimText: '枚',
    dr: 'を入手',
    claim: '受け取る',
    myTickets: '現在の状況',
    ticketLimit: '上限に達しました',
    loginNow: 'ログイン',
    remainingTickets: '残りチケット数',
    claimSuccess: '受け取り成功',
    claimSuccessChatServer:
      '無事に請求されましたので、賞品を受け取るにはカスタマー サービスにお問い合わせください。',
    betTotal: '全ユーザーのベット額が高額になると',
    betTotalText: '{{amount}}ボーナスが当たるチャンス！',
    claimStatus: '収集状況',
    claimStatus0: '削除済み',
    claimStatus1: '承認待ち',
    claimStatus2: '承認が拒否されました',
    claimStatus3: '申請中',
    claimStatus4: '受信済み',
    claimStatus5: '期限切れ',
    claimStatus6: '発送済み',
    status0: '未当選',
    status1: '当選',
    statusAll: '全部',
    noData: 'データなし',
    winText: '{{0}}',
    winPeopleText: '{{0}}人',
    rounds: '回',
    activeRule: 'イベントルール',
    myPrice: '私の賞品',
    priceName: '賞品名',
    priceDate: '有効期限',
    operate: '操作する',
    times: '{{0}}回',
    bonus: '賞金',
    freeSpins: 'フリースピン',
    cashback: 'キャッシュバック',

    gameRule: `
    <div class="line">
    【本プロモーション対象者】クイーンカジノに登録されているユーザー様
    </div>
    <div class="line">
    【抽選券受け取り】条件を満たした後、本イベントページにて受け取ると、システムにより抽選番号がランダムに割り当てられ、各期間につき、１名様最大{{maxClaim}}枚の抽選券を受け取ることができます。
    </div>
    <div class="line">
    【抽選ルール】キャンペーン全体で{{totalNum}}枚の抽選券があり、抽選券が無くなるまで獲得可能です。1枚の抽選券でウィークリー賞とスーパージャックポットの両方に同時に参加でき、1つの抽選券につき、複数の当選チャンスがございます。
    </div>
     `
  },
  betting: {
    cannotBetTips: '優先ウォレットはベットできません',
    inputPlaceHolder: 'ベット額を入力してください',
    historyBtn: '歴代ランキング',
    liText1: '実際の有効ベット額',
    liText2: '推定リターン',
    flagText: '試合 予想',
    dateText: '{{0}}日{{1}}時間{{2}}分{{3}}秒',
    historyTab1: '全ての予想',
    historyTab2: '高ジャックポット記録',
    historyTab3: '歴代勝者',
    historyTabSub1: 'キャッシュ',
    historyTabSub2: 'ボーナス',
    historyTabSub3: 'Q-PON',
    comingSoon: '近日公開',
    historyTableHeader1: '注目の試合',
    historyTableHeader2: '会員アカウント',
    historyTableHeader3: '賭け金',
    historyTableHeader4: '推定リターン',
    historyTableHeader5: '賭けの内容',
    indexRank: 'ランキング',
    poolValue: 'ジャックポット金額',
    poolValueIn: '賞金プール投入額',
    gameTimes: '参加回数',
    winValue: '獲得賞金額',
    bet: 'ベット',
    betBtn0: 'ベット',
    more: 'もっと見る',
    all: '全部',
    historyHigth: '高ジャックポット記録',
    historyWin: '歴代勝者',
    bettingTitle: 'スペシャルイベント',
    bettingTitleNew: '注目の試合',
    bettingTitleMore: 'もっと見る',
    maxPrize: '最高賞金',
    startDate: '開始時間',
    endDate: '終了時間',
    betBtn: 'ベット',
    myScore: '自分の成績',
    totalBetNum: '合計{{0}}回の予想が可能',
    totalInfo: '合計データ{{0}}件',
    claim: '受け取る',
    poolType: 'ジャックポット種類',
    betTime: 'ベット時間',
    betProportion: '予想比率',
    status: 'スターテス',
    status1: '勝つ',
    status2: '未勝利',
    status3: '取消',
    status4: '進行中',
    return: '推定リターン',
    betRuleTitle: 'ルールの説明',
    knowIt: 'わかりました',
    totalNum: '合計 {{0}} 個のデータがあります',
    filterPlaceholder: '推測されるトピックまたはイベント番号を入力してください',
    selectWallet: 'ウォレットを選択する',
    selectStatus: 'スターテスを選択する',
    selectClaimStatus: '収集状況の選択',
    betTimeLabel: 'ベット時間',
    reset: 'リセット',
    confirm: '確認する',
    betHistory: '予想記録',
    history: '過去の記録',
    betPoolValue: 'ジャックポット金額',
    betPoolnum: 'ベット人数',
    betActiveDate: 'イベント時間',
    gameStaits: '勝者結果',
    rolloverRequest: 'フロー要求',
    wallet: '財布',
    betResult: '予想結果',
    betMoney: '賭け金',
    betText: '予想する',
    effectiveBet: '賞金プール総額',
    estimatedBonus: '推定ボーナス',
    betSuccess: '賭けは成功した',
    betTimes: '出席回数 {{0}}',
    noSelect: 'コンテスト内容をご確認ください',
    noInput: '賭け金を入力してください',
    noStart: '賭けはまだ始まっていません',
    claimSuccess: '正常に受信しました',
    status_not_start: '始まっていない',
    status_betting: 'ベット',
    status_wait_result: '描かれる予定',
    status_wait_settle: '決済保留中',
    status_settled: '決済済み',
    status_canceled: 'キャンセル',
    slotsBigWin: '最高ベット額',
    gameNo: 'セッション番号',
    winPeopleNum: '当選者数',
    openDate: '勝者発表日',
    rolloverRequestText: '{{0}}回',
    gameStatus0: '勝者発表待ち',
    gameStatus1: '勝者発表済み',
    gameStatus2: '取消',
    commissionAmount: '手数料',
    claimStatus: '受け取り待ち',
    claimStatus1: '未受領',
    claimStatus2: '受け取り済み',
    claimStatus3: '期限切れ',
    claimStatus4: '取消',
    claimStatus5: '受け取り済み',
    claimText: '受け取り',
    ended: '終了',
    cancel: 'キャンセル',
    cancelSuccess: 'キャンセル成功',
    LuxuryCruiseTravel: '豪華クルーズ船旅行が当たる！大抽選祭'
  }
} as const
