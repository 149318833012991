export const withdraw = {
  // export const WITHDRAW = {
  withdraw: 'Withdraw',
  withdrawType: 'PICK A WITHDRAW METHOD',
  banqtips: 'An active Local Bank Transfer account is required.',
  min: 'Minimum withdraw amount：',
  max: 'Maximum withdraw amount：',
  amount: 'AMOUNT',
  amountText: 'AMOUNT(USD)',
  bankName: 'BANK',
  bankNameText: 'SELECT YOUR BANK',
  userName: 'BANK ACCOUNT NAME (Katakana)',
  account: 'BANK ACCOUNT NUMBER',
  branchName: 'BRANCH (Katakana)',
  notEnough: 'Withdrawal limit is {{currency}}{{min}} - {{currency}}{{max}}',
  maxEnough: 'Amount too high',
  ecopaytitle: 'Amount too high',
  ecopaytips: 'An active ecoPayz account is required.',
  ecopayAccount: 'ECOPAYZ ACCOUNT NUMBER',
  ecopayAccountText: 'Account number',
  virtualTips: 'Provide an address to receive the specified amount exchanged in selected crypto currency.',
  virtualAccount: 'SELECT CRYPTO CURRENCY',
  walletAddress: 'WALLET ADDRESS',
  iwalletAccount: 'IWallet account number',
  length: '8-digits number',
  wrong: 'wrong format',
  orientaltitle: 'An active Oriental Wallet account is required.',
  withdrawSuccess: 'Withdraw submitted',
  tips: 'Your withdrawal will be authorised once we have reviewed your request',
  errTips:
    'Your withdrawal is currently under review. Please wait for a while until the review is completed. ',
  cancel: 'CANCEL',
  correctly: 'Fill in your information correctly',
  compare:
    "When you cash out, we compare the details you fill in here to those listed on your national identification document. If they're not an exact match, withdrawal payments can be delayed",
  customer: 'If you have any questions, please',
  service: 'contact online customer service',
  information: 'Account \n information',
  verification: 'Account \n  verification',
  protocol: 'SELECT PROTOCOL',
  err: 'only integers can be entered',
  success: 'Cancel success',
  channel: 'No channel available',
  tag: 'TAG/MEMO',
  fail: 'Sorry, cancel is not available now',
  support: 'Your withdrawal is currently under review.Please wait for a while until the review is completed.',
  under: 'please contact our customer support',
  notify: 'System Notification',
  condition: 'Withdrawal requirements not met',
  reason:
    'Reason: You have funds that do not meet the wagering requirement. Please complete the remaining ${{amount}} wagering amount before making a withdrawal.',
  time: 'Deposit Time',
  amounts: 'Deposit Amount',
  turnover: 'Wagering Amount Required',
  difference: 'Difference Not Completed',
  vary: 'Limit: Vary in currency',
  fee: 'Fee: Vary in network',
  creditTip: 'Limit: By credit card type',
  bank: 'Bank transfer',
  other: 'OTHER BANK',
  branchCode: 'Branch Code',
  branchCodeTxt: 'Please Enter Branch Code',
  tradingWallet: 'Trading Wallet',
  chooseName: 'Please select an exchange',
  gc: 'Wallet Exchange Name'
} as const
