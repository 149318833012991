export const personal = {
  title: 'アカウント情報',
  text001: '登録メールアドレスを変更するにはカスタマーサポートまでご連絡ください。',
  text002: 'support@queen-casino.com',
  firstName: '名',
  lastName: '姓',
  firstNameJapanese: '名（カタカナ）',
  lastNameJapanese: '姓（カタカナ）',
  emailAddress: 'メールアドレス',
  birthDate: '生年月日（西暦）',
  country: '国',
  prefecture: '都道府県',
  prefecturePla: '都道府県',
  city: '市区町村／番地（マンション名、部屋番号含む）',
  cityPla: '市区町村／番地',
  address: '町名番地',
  addressPla: '町名番地',
  postalCode: '郵便番号',
  postalCodePla: '郵便番号',
  occupation: 'キャリア',
  phone: '携帯番号',
  phonePla: '携帯番号',
  btn001: '編集',
  btn002: '更新',
  btn003: 'キャンセル',
  modifySuccess: 'データが正常に保存されました',
  lastNameJapanesePlc: 'ヤマダ',
  nativeFirstNamPlc: 'タロウ',
  firstNamePlc: '太郎',
  lastNamePlc: '山田',
  tips: '個人情報、現住所を正確にご記入下さい。一致しない場合申請が却下される可能性がございます。',
  tips2: `姓（セイ）と名（メイ）を全角カタカナで「口座名義（カナ）」で使用できる文字をご記入をお願いします.`
} as const
