export const common = {
  homeIcon: {
    more: 'もっと見る',
    open: '開ける',
    close: '閉じる'
  },
  tabber: {
    menu: '詳細',
    game: 'ゲーム',
    wallet: 'ウォレット',
    promo: 'キャンペーン',
    account: 'アカウント',
    guide: 'はじめてガイド',
    faq: 'よくある質問'
  },
  account: {
    ETHErrmsg: '0xで始まり、全長42文字',
    TRC20Errmsg: '大文字Tで始まり、全長34文字',
    BEP2Errmsg: 'bnbで始まり、全長42文字',
    BTCErrmsg: '1または3またはbc1で始まり、全長が27文字以上',
    BCHErrmsg: '1または3またはqまたはpまたはbitcoincash:で始まり、全長が26文字以上',
    LTCErrmsg: 'LまたはMまたはltc1で始まり、全長が26文字以上',
    XRPErrmsg: 'rで始まり、全長が25文字以上',
    cardName: '銀行を選択してください',
    cardNamePlc: '銀行を選択してください',
    cardNnm: '銀行カード番号',
    cardErr: '銀行カード番号フォーマットエラー',
    cardNnmPlc: '銀行カード番号を入力してください',
    name: 'ウォレット名',
    enterName: 'ウォレット名を入力してください',
    bankName: '銀行支店名',
    bankNamePlc: '銀行名を入力してください',
    code: '支店コード',
    codePlc: '支店コードを入力してください',
    crypto: '暗号を選択してください',
    cryptoPlc: '暗号通貨を選択してください',
    network: 'ネットワークを選択',
    networkPlc: 'ネットワークを選択してください',
    address: 'ウォレットアドレス',
    wallet: 'ウォレット管理',
    wallet2: 'ウォレット',
    card: '銀行カード',
    virtual: '仮想通貨',
    payment: '支払い通貨',
    kyc: 'この機能はKYC認証後に利用可能です',
    addCard: '銀行カードを追加',
    tips1:
      '最大 5 枚のカード（クレジット カード含む）を登録することができます。 当社はプラットフォームの関連規制を厳格に遵守し、お客様の個人情報を暗号化して情報セキュリティを確保いたします。',
    tips2:
      '同一契約下で、最大 5 つの仮想通貨ウォレットアドレスを登録できます。当社はプラットフォームの関連規制を厳格に遵守し、お客様の個人情報を暗号化して情報セキュリティを確保いたします。',
    addVirtual: 'ウォレットアドレスを追加',
    deleteCard: 'この銀行カードを削除することを確認します？',
    deleteVirtual: 'ウォレットアドレスを削除することを確認しますか？',
    verify: '確認に行く',
    voucher: 'クーポン',
    balance: 'アカウント残高',
    funks: 'キャッシャー',
    multimode: 'マルチウィンドウモード',
    despoit: '入金',
    withdraw: '出金',
    jl: 'ボーナス・履歴',
    point: 'ボーナス',
    history: 'ベット詳細',
    favorite: 'お気に入り',
    statements: '取引履歴',
    betStatus: 'ベット状況',
    jsBet: '決済ベット',
    wjsBet: '未確定ベット',
    myAccount: 'マイアカウント',
    accountInfo: 'アカウント情報',
    setPwd: 'パスワードを設定する',
    showSet: '表示設定',
    accountVerify: 'アカウント認証',
    selfSet: '自己規制',
    logOut: 'ログアウト',
    allGame: 'すべてのゲーム',
    pachinko: 'パチンコ',
    slots: 'スロット',
    liveCasino: 'ライブカジノ',
    top: 'トップ',
    sport: 'スポーツ',
    bountyFisher: 'シューティングゲーム',
    kuiPachi: 'くいぱち',
    personalCenter: 'パーソナルセンター',
    vip: 'ロイヤルティクラブ',
    recFriend: 'お友達紹介',
    notice: 'メッセージセンター',
    globalSettings: '設定',
    Approved: '認証済み',
    welcome: 'クイーンカジノへようこそ',
    tips: 'まずサインアップまたはログインしてください',
    playPachinko: 'パチンコで遊ぶ',
    PachinkoVideo: 'パチンコ動画',
    pachinkoBeginnerGuide: 'パチンコ初心者ガイド',
    notAvailbale: '未開放',
    back: '戻る',
    all: 'すべて',
    search: '検索',
    success: '成功'
  },
  footer: {
    links: [
      {
        title: 'ゲーム',
        children: [
          {
            title: 'パチンコ'
          },
          {
            title: 'ライブカジノ'
          },
          // {
          //   title: "バカラ",
          //   ischild: true
          // },
          // {
          //   title: "ルーレット",
          //   ischild: true
          // },
          // {
          //   title: "ブラックジャック",
          //   ischild: true
          // },
          {
            title: 'スロット'
          },
          // {
          //   title: "人気ゲーム",
          //   ischild: true
          // },
          // {
          //   title: "ボーナス購入",
          //   ischild: true
          // },
          {
            title: 'フイッシュゲーム'
          },
          {
            title: 'スポーツベット',
            isTitle: true
          }
        ]
      },
      {
        title: 'クイーンカジノについて',
        children: [
          {
            title: 'クイーンカジノについて'
          },
          {
            title: '一般利用規約'
          },
          {
            title: 'キャンペーン一般利用規約'
          },
          {
            title: 'スポーツベット一般利用規約'
          },
          {
            title: '各種サイトポリシー' // site policies 新页面待开发
          },
          {
            title: 'ボーナスとクエストルール'
          }
        ]
      },
      {
        title: 'サポート',
        children: [
          {
            title: 'はじめてガイド',
            link: '/help/guide'
          },
          // {
          //   title: "パチンコ初心者ガイド",
          //   link: "https://kui-pachi777.queen-casino.com/"
          // },
          {
            title: '入出金方法について',
            link: '/help/deposit_method'
          },
          {
            title: '出金方法について',
            link: '/help/about_withdrawal'
          },
          {
            title: 'よくある質問',
            link: '/faq'
          },
          {
            title: 'お問い合わせ',
            link: '/contact'
          },
          {
            title: 'サイトマップ',
            link: '/sitemap'
          },
          {
            title: 'アフィリエイト',
            link: 'https://onlinegaming-affiliate.com/'
          }
        ]
      }
    ],
    H5Links: [
      {
        title: 'ゲーム',
        children: [
          {
            title: 'パチンコ',
            link: '/pachinko?gameType=pachinko'
          },
          {
            title: 'ライブカジノ',
            link: '/live-casino'
            // content: [
            //   {
            //     title: "バカラ",
            //     link: "/live-casino?gameType=baccarat"
            //   },
            //   {
            //     title: "ルーレット",
            //     link: "/live-casino?gameType=roulette"
            //   },
            //   {
            //     title: "ブラックジャック",
            //     link: "/live-casino?gameType=black"
            //   }
            // ]
          },
          {
            title: 'スロット',
            link: '/slots'
            // content: [
            //   {
            //     title: "人気",
            //     link: "/slots?gameType=slotsPopuler"
            //   },
            //   // {
            //   //   title: "ボーナス購入",
            //   //   link: "/slots?gameType=slotsFeature"
            //   // },
            // ]
          },
          {
            title: 'フイッシュゲーム',
            link: '/fish'
          },
          {
            title: 'スポーツベット',
            link: '/fb?gameType=sport'
          }
        ]
      },
      {
        title: 'クイーンカジノについて',
        content: [
          {
            title: 'クイーンカジノについて',
            link: '/static/about'
          },
          {
            title: '一般利用規約',
            link: '/static/termsAndConditions'
          },
          {
            title: 'キャンペーン一般利用規約',
            link: '/static/generalTermsOfUse'
          },
          {
            title: 'スポーツベット一般利用規約',
            link: '/static/sportRules'
          },
          {
            title: '各種サイトポリシー', // site policies 新页面待开发
            link: '/sitePolicies'
          },
          {
            title: 'ボーナスとクエストルール',
            link: '/bonusRules'
          }
        ]
      },
      {
        title: 'サポート',
        content: [
          {
            title: 'ガイド',
            link: '/help/guide'
          },
          // {
          //   title: "パチンコ初心者ガイド",
          //   link: "https://kui-pachi777.queen-casino.com/"
          // },
          {
            title: '入出金方法について',
            link: '/help/deposit_method'
          },
          {
            title: '出金方法について',
            link: '/help/about_withdrawal'
          },
          {
            title: 'よくあるご質問',
            link: '/faq'
          },
          {
            title: 'お問い合わせ',
            link: '/contact'
          },
          {
            title: 'サイトマップ',
            link: '/sitemap'
          },
          {
            title: 'アフィリエイト',
            link: 'https://onlinegaming-affiliate.com/'
          }
        ]
      }
    ],
    credits:
      'QUEEN CASINO は、Curaçaoライセンス（ライセンス番号 No. 365/JAZ）を取得し、Zen Estratega SRL. の管轄下の元で運営、管理されております。',
    tip1: `当サイトへのアクセス、ご遊戯をする際は、<strong><a href="/static/termsAndConditions">利用規約</a></strong>に同意する必要がございます。またお客様に円滑かつ快適にご利用いただくために、当サイトではクッキーを使用しています。QUEEN CASINOは、クッキーを使用することによって、お客様のプライバシーを侵害することは決してありません。クッキーの使用方法や無効化方法については、<strong><a href="/static/privacy">プライバシーポリシー</a></strong>を参照してください。`,
    tip2: `当サイトを利用中に問題がある場合は、<strong><a href="/faq">よくある質問</a></strong>や利用規約、<strong><a href="/static/generalTermsOfUse">キャンペーン利用規約</a></strong>をご確認していただくか、サポートチャットまでお問い合わせください。また、ギャンブルには中毒性がありますので、責任を持って行動する必要があります。QUEEN CASINOは財政問題を解決するための手段ではありません。詳細については、<strong><a href="/static/responsibleGaming">責任あるギャンブル</a></strong>に記載されている内容を参照してください。`,
    tip3: `ネットカジノ, スポーツブック, バカラ, 入出金保証, 安全, 日本, 銀行振込, 仮想通貨決済, 決済代行, 阿武町, 芸能人, 有名人, インフルエンサー, キャバ, ホスト, Queen kajino, Kuiin kasino, クインカジノ, クイーンカシノ, オンラインカシノ, オンカシ`,
    ambassador: 'アンバサダー',
    followUS: '公式ソーシャルメディア',
    bottom: 'すべての権利を留保します.',
    blog: 'ブログ',
    news: 'ニュース',
    streams: '配信',
    copyrights: '© {{0}} QUEEN CASINO  すべての権利を留保します'
  },
  headerNav: {
    liveCasino: ' ライブカジノ ',
    slots: ' スロット ',
    tableGames: ' テーブルゲーム ',
    sportsBet: ' スポーツベット ',
    eSports: 'eスポーツ',
    virtual: ' バーチャル',
    promotions: ' プロモーション '
  },
  service: 'ライブチャット',
  serviceTitle: 'お問い合わせ内容',
  serviceCloseHint: 'またいつでもお声がけください！',
  serviceCloseBtn: '閉じる',
  validtor: {
    changeSuc: '無事変更！',
    usenameEpty: 'ユーザー名を入力してください',
    nameErr: '4 ～ 18 個の数字または文字を入力してください',
    phoneEpty: '電話番号を入力してください',
    emailEpty: 'メールアドレスを入力してください',
    japaneseNameErr: '片假名のみ使用してください',
    emailError: 'メールフォーマットエラー',
    passwordEpty: 'パスワードを入力してください',
    passwordError: 'アルファベット、数字、記号を6～32文字で入力してください',
    pwdSame: '2 つのパスワードが一致しません',
    codeError: '6桁のコードを入力してください',
    phoneVerify: '正しい電話番号を入力してください',
    resetSuc: 'リセット成功！!'
  },
  game: {
    goPlay: 'プレイ',
    funPlay: '無料プレイ',
    dailyBet: 'ベット金額',
    winBet: '勝利金額',
    nodata: 'データなし',
    loading: '読み込み中...',
    all: '全て',
    hotGame: '人気',
    newGame: '最新',
    buyGame: 'ボーナス購入',
    fun: '楽しく遊ぼう',
    real: '実際にプレイ',
    tips: 'プレイするためにはログインしてください。',
    noSeachData: '残念<br/><br/>お探しのゲームは見つかりません',
    otherSearch: '他の検索',
    popularity: '人気',
    games: 'ゲーム',
    maintenance: 'メンテナンス中、すぐに終わります',
    probability: '確率:',
    ball: '1玉:',
    roll: '1回:',
    ceiling: '天井:',
    token: '1 枚:',
    type: 'タイプ:',
    typeGame: 'タイプ',
    responsible: '責任あるギャンブル',
    play: 'プレイ',
    pcIslot: '携帯でのご遊戯をお願いいたします。',
    quickDeposit: 'クイック入金',
    quitGame: 'ゲーム終了',
    instructions: '操作説明',
    addFavorite: 'お気に入り追加',
    removeFavorite: 'お気に入り削除',
    addSuccess: '正常に追加されました',
    cancelSuccess: '成功をキャンセル',
    openSmallWindow: '小ウィンドウを開く',
    openedWindow: '小ウィンドウは開いています',
    quitGameAsk: '本当にゲームを終了しますか？',
    quitGameFirst: '追加ゲーム数が4を超えました、先にゲームを終了して下さい',
    depositFirst: '先にチャージして下さい',
    selectGames: 'ゲームを選択',
    selectMoreGames: '更にゲームを選択',
    moreopularPGames: '更に人気のゲーム',
    nosupport: 'このゲームはマルチウィンドウモードをサポートしていません',
    hide: '隠れる',
    goHomeAsk: 'すべてのゲームを終了してホームページに戻りますか?',
    notSuppot: 'このゲームはマルチマシンモードをサポートしていません'
  },
  video: {
    buffering: 'バッファリング中...',
    replay: 'リプレイ',
    wrongRequest: 'リクエストエラー',
    nomore: 'これ以上のビデオはありません',
    tag: '動画タグ',
    seach: '動画名または作者を検索',
    more: '他の動画',
    sort1: '総合順位',
    sort2: '最新リリース',
    sort3: '最多再生'
  },
  footerHtml: {
    text: `
      Queen Casinoは Zen Estratega SRL,によって運営されています。登録番号:3-102-897673 、登録住所:400, Calle La Ceiba, San Rafael, Escazú, Province of San Jose, Costa Rica。Queen Casinoは Zen Estratega SRL.りサブライセンスを保持しており、すべてのコンプライアンスを通過しており、各ゲームに対して、ゲーミングオペレーションを行う法的認可を受けて運営しております。ご不明点は<br /><a href="mailto:support@queen-casino.com">support@queen-casino.com</a> にお問い合わせください。 `,
    support: `
                <span>
                  サポート
                  <a href="mailto:support@queen-casino.com">support@queen-casino.com</a>
                </span>
                <span>
                  <span
                    style="margin-left: 2px"
                    class="line"
                  >
                    |
                  </span>
                  アフィリエイトパートナー
                  <a href="https://onlinegaming-affiliate.com">onlinegaming-affiliate.com</a>
                </span>`
  },
  notificTab: {
    reward: '報酬',
    promotions: 'プロモーション',
    news: 'ニュース'
  },
  levelUp: {
    willLevel:
      "もうすぐレベル<span>{{level}}</span>アップいたします！レベルアップ後<span class='money'>${{bonu}}</span><br />ボーナスが獲得可能です",
    willLevel2: '',
    level1: "<p style='font-size:12px;'>おめでとうございます！<br />レベル{{level}}へレベルアップし</p>",
    level2: "<span style='font-size:16px;'>${{bonu}}</span><br />ボーナスをご獲得いたしました！"
  },
  announcement: {
    preMsgContent0:
      'クイーンカジノからKAMIKAZE営業再開のお知らせです！<br/><br/>大変長らくお待たせいたしました！<br/>8/13よりKAMIKAZEが通常の営業時間となり戻ってきました！下記の時間で営業となります。<br/>【営業時間】<br/>日本時間 10:00～翌08:00<br/>水曜日のみ　日本時間 12:00～翌08:00<br/><br/>メンテナンス等で営業時間の変更になる場合もございますので、ご了承くださいませ。<br/>何卒よろしくお願いいたします。',
    preMsgContent2:
      '現在一部のスロットゲームがアップグレードに伴い提供を停止しております。<br/>昨今のスロットゲーム人気に伴い、クイーンカジノでは当サイト独占のゲーム提供を開始いたしました。<br/>当サイトでは魅力的なユーザー体験の提供に努めており、その準備に伴い定期的なメンテナンス時間をいただくことがございます。<br/>下記例以外にもご利用いただけない機種がございますが、現在併せてアップグレード作業中となります。<br/>ご不便おかけいたしますが、再登場をお待ちください。<br/>[例]<br/>Cash Ultimate<br/>Gemix 2<br/>Lucky Fridays<br/>Eye of the Kraken<br/>Gate of Olympus<br/>Sea Hunter<br/>Wild West Angel<br/>Dynamite Riches'
  },
  limitLogin: {
    title: '申し訳ありません。制限された地域からクイーンカジノにアクセスしようとしているようです。',
    bottom: 'プライバシーポリシー',
    Anti: 'マネーロンダリング防止ポリシー',
    Security: 'セキュリティ'
  },
  loading: '読み込み中...'
} as const
