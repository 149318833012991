export const bonus = {
  id: 'アクティビティID',
  bonus: 'ボーナス',
  viewBonus: 'ボーナスの確認',
  viewTast: 'タスク見る',
  bonusHistory: 'ボーナス履歴',
  noData: '現在タスクはありません。お待ちください',
  noDataBonus: '現在、ボーナスはありません。 お待ちください',
  taskContent: 'タスク内容',
  awaitingDeposit: '獲得中',
  revert: '元に戻す',
  claimTaskSuccess: 'タスクを受け取りました',
  claimBonusTips: 'このボーナスを請求しますか?',
  claimBonusTips2: 'このボーナス ステータスをロールバックしますか?',
  claimBonusTips3: 'このボーナスを辞退することを考えていますか?',
  claimBonusSubTips: 'このボーナスオファーを受け取る',
  claimBonusSubTips2: 'ステータスが受け取り保留に戻りました',
  claimBonusSubTips3: 'ボーナスを辞退すると、同じボーナスを請求することはできなくなります。',
  campaignName: 'キャンペーン名',
  campaignId: 'キャンペーンID',
  bonusAmount: 'ボーナス額',
  rolloverStatus: 'ロールオーバー ',
  transactionID: 'トランザクションID',
  campaignPeriod: 'キャンペーン期間',
  start: '開始日',
  end: '終了日 ',
  bonusBetLimit: '獲得最大可能額',
  max: '最大',
  actionGo: '今すぐ開始',
  actionClaim: '受け取る',
  lowerAmount: '受取条件を満たしていません',
  regusterTastfinished: '完了',
  regusterTastgoing: '今すぐ完了',
  actionReject: '拒絶',
  rejected: '承認が却下されました',
  processing: '未処理',
  claim: '未開始',
  claimed: '獲得中',
  reject: '拒絶された',
  canceled: 'キャンセル',
  finished: '終了済み',
  convert: '受け取った',
  ongoing: '活動中',
  declined: '却下',
  expired: '期限切れ',
  dateClaimed: '受取日',
  expiration: '有効期限',
  bonusConverted: '換算額',
  placeholder: '選んでください',
  success: '正常に送信されました',
  balance: 'アカウント残高',
  allGame: '全ゲーム',
  depostLimit: 'シングル入金額',
  depostAmount: '入金額',
  depostValue: '入金額',
  creditValue: 'クレジット額',
  credit: 'クレジット',
  rollover: 'ベット額',
  rolloverMultiple: '複数ベット',
  rolloverLimit: 'ロールオーバー限度額',
  gameType: '適用カテゴリー',
  betLowTips: 'ベット額が低すぎます。もう一度入力してください',
  depositTips: 'ボーナスを有効にするために、{{0}}ドル以上の入金をお願いします。',
  turnoverTips: 'ボーナスを有効にするために、最低{{0}}ドルの賭け金を達成して下さい。',
  creditTips: '最初のデポジットが {{0}} に達すると、特典が有効になります',
  betTips: '報酬を有効にするには、少なくとも {{0}} を賭けてください',
  depositSuccessTips: '入金完了。請求後、賭け金のカウントが開始されます。',
  turnoverSuccessTips: '取引が完了しました。報酬を受け取ってください',
  day: '日',
  days: '日',
  hour: '時',
  minute: '分',
  second: '秒',
  finshProcess: '完了 ({{0}})',
  taskId: 'タスクID',
  tips: 'ヒント',
  justOne: 'イベントは１つのみご選択いただけます',
  cancelTips: 'ボーナスを放棄した場合、ベット履歴は消去されます。',
  confirm: '確定',
  cancelTaskSuccess: 'タスクが正常にキャンセルされました',
  statu1: '未処理',
  statu2: '受け取り保留中',
  statu3: '受け取り済み',
  statu4: '拒否済み',
  statu5: 'キャンセル',
  newbonus: 'ボーナス',
  giftText: 'ボーナスをゲット',
  bonusPlaza: 'クエスト',
  plaza: 'プラザ',
  plazaHistory: '報酬履歴',
  hotActivity: '人気のイベント',
  firstDepositActivity: '入金',
  registerActivity: '登録',
  betActivity: 'ベット',
  recommendedActivity: 'スペシャルイベント',
  depositActivity: '入金',
  taskName: 'キャンペーン名称',
  taskReward: 'イベント報酬の方式',
  activityName: 'イベント名',
  activityReward: 'イベント報酬',
  taskRewardText1: '条件達成後、ボーナス獲得',
  taskRewardText2: '条件達成後、ボーナス獲得',
  rewardType: 'ボーナスタイプ',
  rewardAmount: '報酬額',
  rewardCurrency: '報酬通貨',
  rewardTypeText1: 'ボーナス',
  rewardTypeText2: 'フリースピン',
  rewardTypeText3: 'ボーナス + フリースピン',
  rewardValue: 'ボーナス値',
  rewardLimit: '最大ボーナス額',
  min: '最小ボーナス額',
  transactionAmount: 'ボーナス最大限度額',
  rewardExpire: 'ボーナス有効期限',
  rewardLimitValue: '無制限',
  rewardLimitMultipleValue: '{{0}} x {{1}}倍',
  maxWithdraw: '最大引き出し限度額',
  rolloverRequest: '出金条件',
  rolloverRequestNone: 'フロー不要',
  rolloverRequestText: 'ボーナス x {{0}}倍',
  rolloverRequestText2: '（元金＋ボーナス）x {{0}}倍',
  activeEndTime: 'イベント終了時間',
  promotionEndTime: 'オファーは終了します',
  gameTimes: '参加可能数',
  gameTimesValue: '{{0}} 回/{{1}}',
  gameTimesValueOne: '1回',
  gameTimesValueDay: '日',
  gameTimesValueWeek: '週',
  gameTimesValueMonth: '月あたり',
  gameTimesValueYear: '1年当たり',
  remainingGameTimes: '残りの参加回数',
  viewTask: '詳細を見る',
  viewTaskActivity: '詳細を見る',
  claimeActivity: '受け取る',
  declineActivity: '受け取り拒否',
  cancelActivity: 'キャンセル',
  cancelBonus: 'キャンセル',
  gameTims: '{{0}}スピン',
  gameTimsProgress: '{{0}}<span>スピン</span>',
  rewardCalSourceBetAmount: '有効な賭けの合計',
  rewardCalSourceDepositAmount: '総預金額',
  rewardCalSourceDepositFirstAmount: '単一の入金額',
  rewardCalSourceSingleDepositAmount: '1回の入金額',
  rewardCalSourcePecommendNum: '有効なフレンドの推奨数',
  closeDialogBtn: '閉じる',
  lobbyName: 'ゲーム場',
  gameName: 'ゲーム名称',
  sigleGameValue: 'ゲームごとの金額',
  tag1: '未開始',
  tag2: '進行中',
  notice: '通知',
  cancel: '本当にキャンセルしますか？',
  cancelTast1: 'キャンセルしますと、ベット実績は削除され復帰できません。',
  cancelTast2: 'イベントを取り消すと、それらの進行状況はクリアされます',
  wallet1: 'プロモーションウォレット',
  wallet2: '通常のウォレット',
  claimAlertContent1: '【{{0}}】{{1}} USD ボーナスがあなたの {{2}} に送金されました',
  claimAlertContent2: '【{{0}}】{{1}} 回の転送フリースピンが解放されました',
  claimAlertContent3:
    '【{{0}}】{{1}} USD ボーナスとフリー スピンへの {{2}} の転送がリリースされました。ボーナスは {{3}} に送られます。更新して確認してください。',
  claimAlertContent4:
    '【{{0}}】報酬は発行され、現在の報酬は凍結されています。報酬は {{1}} USD 件の取引完了後にロックを解除できます',
  claimAlertContent5: '他のオファーを請求する前に、過去に請求したオファーを完了して下さい',
  claimAlertContent6: '請求の拒否を確認するかどうか',
  noDataBonusText: 'イベントはありません、お待ち下さい',
  noDataTastText: 'プラザはありません、お待ち下さい',
  task: 'プラザ',
  activity: 'イベント',
  offerType: 'プロモーションタイプ',
  status: '状態を選択',
  time: '選考期間',
  rewardMethod: '報酬方法',
  receiveTime: '報酬受け取り時間',
  expirationTime: '特典の有効期限',
  finishTime: '任務完了時間',
  currentStatus: '状態を',
  noNeed: 'フロー不要',
  change: '{{num}}スピン',
  platform: 'ゲーム場: ',
  name: 'ゲーム名称: ',
  amount: 'ゲームごとの金額: ',
  today: '今日',
  today3: '直近3日',
  today5: '直近7日',
  today7: '直近15日',
  today9: '直近30日',
  record: 'プロモレコード',
  type: 'タスクの種類',
  taskNames: 'プラザ名称:',
  method: 'イベント報酬の方式:',
  collectionTime: 'ボーナス受け取り時間',
  award: '活動賞',
  from: 'から',
  to: 'まで',
  index: '番号',
  sure: '本当にキャンセルしますか？',
  voucher: 'ボーナ x {{multiple}} 倍',
  bonusInfo: {
    rakebackBonus: 'レーキバックボーナス',
    bounceBackBonus: '毎日キャッシュバック',
    otherList_DoubleRakebackPromo: 'リベート額倍増',
    dailyBonus: '日替わりボーナス',
    weeklyBonus: '週替わりボーナス',
    monthlyBonus: '月替わりボーナス',
    birthdayBonus: '誕生日ボーナス',
    exclusiveCustomerService: '専属サポーター',
    VIPSubscriptionBonus: 'VIPチャンネルフォローボーナス',
    fullyCustomizableExperience: 'カスタマイズサービス',
    VIPTour: 'VIP限定オファー特別なツアー(招待のみ＆ダイヤモンドレベル)',
    luxuryHotelSuites: '豪華ホテルルーム',
    privilegeDetails: 'ボーナス詳細'
  },
  join: '今すぐ参加',
  complete: '携帯電話認証とレベル2のKYC認証を完了してから受け取る必要があります',
  later: '後で',
  go: '認証します',
  locking: '受け取りが成功しました',
  dg: 'このプロモーションは{{time}}に終了します',
  up: 'あきらめる成功',
  give: 'リリース成功',
  times: '回',
  use: '使用する',
  release: '解放された',
  dec: '正常に削除されました',
  extra: 'その他',
  level: '会員レベル',
  and: 'ボーナスと',
  fee: '回のフリースピンを!',
  fg: '受け取りました',
  wallet: 'フリースピンウォレット',
  rakebackTime: '次回受け取りまで '
} as const
