export const responsible = {
  // export const RESPONSIBLE = {
  nav1: 'Contact Us',
  nav2: 'Useful Tips',
  bannerText: 'Enjoy the game responsibly!',
  bannerText2: '~Have Fun Play Healthy~',
  bannerBtn: 'Contact Us',
  title1: 'Ways to Maintain Mental Control',

  text1_1: 'To maintain control, please consider the following:',
  text1_2: 'Gambling is a form of entertainment and not a means to earn money.',
  text1_3: 'Do not try to recover your losses.',
  text1_4: 'Set a budget and only play within that budget.',
  text1_5: 'Ensure that gambling does not impact your daily life.',
  text1_6: 'Before starting to gamble, set clear limits for purchases, bets, and losses.',
  text1_7: 'Define an affordable amount to play with and manage your spending.',
  text1_8: 'If you need to temporarily stop gambling, request a timeout or self-exclusion.',
  title2: 'Gambling Addiction',

  text2_1:
    'Gambling addiction is the inability to control the desire to continue gambling despite negative consequences. Please check the following points to see if you or someone around you may be engaging in excessive gambling:',
  text2_2: 'Have you ever skipped work or school to gamble?',
  text2_3: 'Do you gamble as a way to escape from reality?',
  text2_4: " Do you feel the urge to gamble when you're disappointed or irritated?",
  text2_5: "Have you gambled until you've run out of money?",
  text2_6: 'Have you recently tried to quit gambling but failed?',
  text2_7: 'Have you lied or hidden your gambling activities from others?',
  text2_8: 'Have you been criticized by others for your gambling?',
  text2_9: 'Have you lied, stolen, or borrowed money to finance your gambling?',
  text2_10: 'Do you hesitate to use funds meant for other purposes for gambling?',
  text2_11: 'Have you lost interest in family, friends, or hobbies?',
  text2_12: 'Do you feel the need to continue gambling to recoup losses?',
  text2_13: 'Do you gamble impulsively during arguments, when feeling dissatisfied, or when disappointed?',
  text2_14: 'Does gambling lead to feelings of despair or recklessness?',
  text2_15:
    "If you answered 'yes' to many of these questions, there may be a possibility of gambling addiction.",
  title3: 'Point',
  text3_1: "We'll introduce important points to support a responsible gambling experience.",
  cardTitle1: "Everything is for the sake of 'enjoyment.'",
  cardText1:
    'Gambling and high-risk bets are not a solution to financial problems. Gambling exists for your entertainment. Play when you genuinely feel you can enjoy it. As a part of increasing happiness in your life, try to enjoy gambling!',
  cardTitle2: 'Budget Management',
  cardText2:
    "Let's enjoy playing within what you can afford to spend. If you gamble regularly, it's recommended to set a monthly play budget within your limits to ensure you can sustain future gameplay. Enjoy the games at your own pace with a budget that suits you.",
  cardTitle3: 'Setting Self-Exclusion',
  cardText3:
    "To help you manage the time and money you spend on gambling, we provide a 'Self-Exclusion Setting.' It's an effective tool for taking care of yourself, so feel free to use it with confidence. Enjoy gambling smartly and responsibly.",
  cardTitle4: 'Moderate Breaks',
  cardText4:
    "While enjoying gambling, it's easy to lose track of time. In those moments, we recommend taking a break from gambling, going out, spending time with loved ones and friends, or trying something new. Refreshing your mind and body can lead to a more fulfilling time.",
  cardTitle5: 'Self-Analysis',
  cardText5:
    'Regular self-analysis of your gambling tendencies is highly effective in maintaining a healthy gambling cycle. Take a moment to consider how gambling is affecting your life. Making the right choices is crucial to prioritize your health and happiness.',
  cardTitle6: 'Avoid Excessive Drinking',
  cardText6:
    "Alcohol can impair judgment. It's a wise choice to enjoy alcohol and gambling separately. By keeping them separate, you can have a healthier and more balanced experience. Take care of your mental and physical well-being and find enjoyment in a responsible way.",
  title4: "Let's set self-exclusion.",

  text4_1: "You can set login restrictions using the 'Self-Exclusion Feature.' ",
  text4_2:
    "If you wish to temporarily pause site usage, you can choose self-exclusion for up to 90 days. This can be set in 'Self-Exclusion' under 'My Account.' Applying self-exclusion will limit deposits and access to your account, and it cannot be lifted until the exclusion period ends. If you require an exclusion for more than 90 days, please email <a href=\"mailto:support@queen-casino.com\">support@queen-casino.com</a>.",
  text4_3: '',
  text4_4: '',
  text4_5: '',
  title4Btn: 'Go to the self-exclusion page',
  title7: 'Preventing Underage Gambling',

  text7_1:
    'To prevent gambling by individuals under the age of 18, we perform automatic checks on your age and identity when your personal information is registered. If we cannot confirm that you are 18 or older, we kindly request that you submit a copy of your identification within 72 hours.',
  text7_2:
    'This process is an important measure to protect minors and provide a safe environment. We prioritize your safety, as gambling by minors can potentially have a negative impact on their healthy development and future. Accurate age verification is crucial.',
  text7_3:
    "Let's work together to prevent underage gambling. We spare no effort in ensuring your safety and well-being. Feel free to reach out with any questions or concerns.",
  title8: 'To protect minors from gambling',

  text8_1:
    'Your cooperation is essential to provide the best service, especially when minors have access to the internet. Please consider the following:',
  text8_2:
    'Do not share important information such as login names, passwords, and account details with minors. This reduces the risk of minors accessing or using them without permission.',
  text8_3: 'Always log out when you finish using the site. This prevents others from accessing your account.',
  text8_4:
    'To protect minors, it is recommended to install internet filtering software (access control software). This allows you to control content display, block specific websites, and set passwords.',
  text8_5:
    "Protecting minors is crucial, and we prioritize your safety. We recommend using these measures. If you have any questions or concerns, please feel free to contact us. We're here to support your safety and happiness, as well as that of your loved ones!",
  title9: 'Do You Need Help?',
  text9_1:
    'If you are concerned about a family member or friend under the age of 20 gambling, there are support agencies that have trained professionals who can help you. please be aware that gambling under the age of 20 can have legal consequences beyond the financial and behavioral risks.',
  text9_2:
    'We recommend using the third-party company apps below to limit under-20 access to potentially harmful sites.',
  title11: 'If you still have concerns',

  text11_1: 'If you find it difficult to control your gambling,',
  text11_2: "it's important to seek help from those around you, even for the smallest signs. ",
  text11_3:
    'To make the right decisions, please do reach out to reliable support organizations for guidance.',
  supportBtn: 'View Related Information',
  supportTitle1:
    'The General Foundation for Gambling Addiction Prevention and Recovery Support Center offers a support call service with no call or consultation fees. It operates 24/7, all year round. Specialized counselors are available to listen to your concerns, so anyone who is troubled, including family members, can seek advice.',
  supportTitle2:
    'NPO ASK has been actively working to prevent and address substance addiction issues, including alcohol, since its establishment. Our goal is to create a society where these issues are prevented, detected early, and individuals receive the necessary treatment and support for recovery. Since 2017, we have also included prevention efforts for issues such as internet addiction, gaming addiction, and gambling addiction in our projects.',
  supportTitle3:
    "It's a leading organization in the UK that provides support, advice, and information for gambling-related issues, including free counseling and the National Gambling Helpline.",
  supportTitle4:
    "It's an organization that supports all individuals affected by gambling addiction. They provide support groups that both gambling addicts and their family and friends can participate in.",
  supportTitle5:
    'They offer online support for gambling addicts and their families and friends through support groups, forums, a resource database, and email support.',
  supportTitle6:
    'Gamstop provides a free service that allows registrants to restrict access to gambling-related websites and apps for their chosen duration.',
  title12: 'Contact Us',
  text12_1:
    "Don't know where to start? </br> Our customer support staff is here to assist you via live chat or email.",
  title12CardTitle1: 'Chat Support',
  title12CardText1: 'Available 24/7, year-round.',
  title12CardBtn1: 'Start Chat',
  title12CardTitle2: 'E-mail Support',
  title12CardText2:
    'We will respond to you through Japanese customer support. Please feel free to contact us.',
  title12CardBtn2: 'SEND E-MAIL'
} as const
