export const withdraw = {
  withdraw: '出金',
  withdrawType: '出金方法を選択してください',
  banqtips: 'お客様名義の銀行振込口座を入力してください。',
  min: '最低出金額：',
  max: '最大出金額：',
  amount: '金額',
  amountText: '金額(USD)',
  bankName: '銀行名',
  bankNameText: '銀行をお選びください。',
  userName: '口座名義（カタカナ）',
  account: '口座番号',
  branchName: '銀行支店名（カタカナ）',
  notEnough: '出金限度額は{{currency}}{{min}} - {{currency}}{{max}}',
  maxEnough: '金額が超過しております',
  ecopaytitle: 'エコペイズ',
  ecopaytips: 'エコペイズ アカウントが必要です。',
  ecopayAccount: 'エコペイズ アカウント番号',
  ecopayAccountText: 'アカウント番号',
  virtualTips: '出金したい仮想通貨とウォレットアドレスを入力してください。',
  virtualAccount: '仮想通貨を選択',
  walletAddress: 'ウォレットアドレス',
  iwalletAccount: 'I-WALLET アカウント番号',
  length: '8桁番号',
  wrong: '間違ったフォーマット',
  orientaltitle: '有効なオリエンタルウォレットアカウントが必要です。',
  withdrawSuccess: '出金手続き済み',
  tips: '申請が承認された後、ウォレットへ反映されます。',
  errTips: '現在、お客様の出金は審査中となっております。審査完了まで今しばらくお待ちください。',
  cancel: 'キャンセル',
  correctly: '個人情報を正しくご入力ください ',
  compare:
    '出金申請の前に、お客様の個人情報をご入力下さい。ご入力頂きました情報とご本人様確認書類での情報を照合致します。ご不明点ございましたら、ライブチャットサポートまでお問い合わせください',
  customer: 'ご不明な点がございましたら、',
  service: 'ンラインカスタマーサービスにお問い合わせください',
  information: 'アカウント情報',
  verification: 'アカウント認証',
  protocol: '同意を選択',
  err: '入力できるのは整数のみです',
  success: '成功をキャンセル',
  channel: 'チャンネルがありません',
  tag: 'タグ/メモ',
  fail: '申し訳ありませんが、現在キャンセルはできません',
  support: '現在、お客様の出金は審査中となっております。審査完了まで今しばらくお待ちください。',
  under: 'カスタマーサポートまでお問い合わせ下さい。',
  notify: 'システム通知',
  condition: '出金の条件を満たしていません。',
  reason: '原因: 賭け金額が不十分です。出金には残り{{amount}}ドルを賭ける必要があります。',
  time: '入金時間',
  amounts: '入金額',
  turnover: '必要な賭け金額',
  difference: '残り必要な賭け金額',
  vary: '限度額：通貨で異なる',
  fee: '手数料：ネットワークで異なる',
  creditTip: '限度額：クレジットカードの種類による',
  bank: '銀行振込',
  other: 'その他の銀行',
  branchCode: '銀行支店コード',
  branchCodeTxt: '銀行支店コードを入力してください',
  tradingWallet: '取引ウォレット',
  chooseName: '取引を選択して下さい',
  gc: '取引所名'
} as const
