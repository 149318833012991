export const statements = {
  // export const STATEMENTS = {
  title: 'Statements',
  filterExactId: 'SEARCH BY EXACT ID',
  filterStatementType: 'STATEMENT TYPE',
  filterFrom: 'FROM',
  filterTo: 'To',
  filterPlaceholder: 'Select type',
  filterPlaceholderId: 'Search ID',
  submit: 'SUBMIT',
  clear: 'CLEAR',
  id: 'ID',
  date: 'DATE',
  type: 'TYPE',
  debit: 'DEBIT',
  credit: 'CREDIT',
  balance: 'BALANCE',
  bonusBalance: 'BONUS BALANCE',
  status: 'STATUS',
  optionsDeposit: 'DEPOSIT',
  optionsWithdraw: 'WITHDRAW',
  optionsOthers: 'OTHERS',
  optionsWithdrawReject: 'WITHDRAW REJECT',
  optionsWithdrawCancel: 'WITHDRAW CANCEL',
  optionsWithdrawOthers: 'WITHDRAW OTHERS',
  optionsWithdrawPromotion: 'PROMOTION',
  optionsWithdrawPromotionDeduction: 'PROMOTION DEDUCTION',
  optionsPromotionTransferIn: 'PROMOTION TRANSFER IN',
  optionsPromotionTransferOut: 'PROMOTION TRANSFER OUT',
  optionsGameTransferIn: 'GAME TRANSFER IN',
  optionsGameTransferOut: 'GAME TRANSFER OUT',
  optionsGameTransferToLocal: 'GAME TRANSFER TO LOCAL',
  optionsLocalTransferToGame: 'LOCAL TRANSFER TO GAME',
  affiliateCommission: 'AFFILIATE COMMISSION',
  // status1: "Cancelled by user",
  status1: 'complete',
  status2: 'Withdrawal reject',
  status3: 'Withdrawal requested',
  status4: 'Cancelled',
  status0: 'Processing',
  status5: 'Unsuccessful',
  noDate: 'There is no statements that can be displayed.',
  withdrawalFee: 'Withdrawal Fee',
  washCode: 'Rakeback',
  commission: 'Commission',
  voucher_0048: 'Coupon - deposits',
  voucher_0049: 'Coupon - received',
  voucher_0050: 'Coupons - lose light refund',
  voucher_0013: 'In progress',
  voucher_0014: 'Receive',
  voucher_0015: 'lost all',
  voucher_0016: 'Expired',
  voucher_0017: 'Abandoned',
  voucher_0018: 'Available',
  vaultText011: 'Vault Deposit',
  vaultText012: 'Vault Withdrawal',
  therList_QuotaRevision9: 'Quota Lock-Quota Revision',
  otherList_QuotaRevision10: 'Quota lock-add quota',
  otherList_QuotaRevision11: 'Preferential Release - Quota Reduction',
  otherList_QuotaRevision12: 'Preferential release-add quota',
  otherList_QuotaRevision13: 'Offer expiration/cancellation-reduction amount',
  otherList_QuotaRevision14: 'Offer expired/cancelled-add quota',
  otherList_QuotaRevision15: 'Vault Withdrawal-System',
  otherList_QuotaRevision: 'Quota Revision'
} as const
