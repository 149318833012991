export const addToHome = {
  // export const ADDTOHOME = {
  title: 'HOW TO INSTALL ?',
  content: 'Click Add To The Home Screen To Play Anytime, Anywhere!',
  btn1: 'Add To Home Screen',
  btn2: "Don't Show Again",
  iosStepsTitle1: 'Open website in Safari browser.',
  iosStepsContent: 'Click to open the Safari browser on the phone desktop.',
  btnSkip: 'Skip',
  next: 'Next',
  iosTitle1: 'Open website in Safari browser.',
  iosContent1: 'Click to open the Safari browser on the phone desktop.',
  iosTitle2: 'Tap Sharing button.',
  iosContent2: 'Use Safari APP and go to queen-casino.com, Then tapthe Sharing Button:',
  iosTitle3: 'Tap Add to Home Screen.',
  iosContent3:
    "Press Add to Home Screen in the pop-up list to add to Home Screen. You may need to swipe down to find the 'Add to Home Screen' button.",
  iosTitle4: 'Tap Add',
  iosContent4: "Press Add to 'Add' in the upper right corner to add to the home screen",
  iosTitle5: 'Add completed',
  iosContent5: 'You can go back to your home screen to view the  Queen Casino app',
  btnEnd: 'Got It',
  androidTitle1: 'Open website in Chrome.',
  androidContent1: 'Click to open the Chrome on the phone desktop.',
  androidTitle2: 'Tap More button.',
  androidContent2:
    'Use Chrome APP to visit queen-casino.com, then click the More button in the upper right corner:',
  androidTitle3: 'Tap Add to Home Screen.',
  androidContent3: 'Press Add to Home Screen in the pop-up list to add to Home Screen.',
  androidTitle4: 'Tap Add',
  androidContent4: "Click the 'Add' button to add to your home screen.",
  androidTitle5: 'Add completed',
  androidContent5: 'You can go back to your home screen to view the Queen Casino app'
} as const
