export const exclusion = {
  hintsText01:
    'ギャンブルをコントロールすることが困難であると感じる場合は、 7日間・30日間・90日間の自己規制を設定することができます。',
  hintsText02: 'GamCare',
  hintsText03: 'または',
  hintsText04: 'GamblingTherapy.org',
  hintsText05: 'にアドバイスやサポートを求めることもできます。',
  radioText01: '<span>7日</span>間ログインを許可しない',
  radioText02: '<span>30日</span>間ログインを許可しない',
  radioText03: '<span>90日</span>間ログインを許可しない',
  checkText01: '規制期間中はプロモーションを受けとらない',
  checkText02: '規制期間終了のお知らせメールを受けとる',
  confirmText: '続行してもよろしいですか？ ログアウトし、{{val}}日間ログインできなくなります。',
  btn: '送信',
  btnConfirm: 'はい',
  btnCancel: 'いいえ',
  emailUnsubscribe: 'メール配信停止',
  Unsubscribe: 'Eメールアドレス',
  fh: 'オンにするかどうか “規制期間中はプロモーションを受けとらない”'
} as const
