import { Lng, languages, FALLBACK_LNG } from './i18n'
import { DEFAULT_NS } from './i18n'
export { DEFAULT_NS } from './i18n'
export const COOKIE_NAME = 'i18next'

export const localeItems = [
  {
    title: 'English',
    nickname: 'EN',
    name: 'en-US',
    key: 'en',
    icon: '/static/images/navPage/en.svg',
    arr: ['en', 'en-US']
  },
  {
    title: '日本語',
    nickname: 'JP',
    name: 'ja-JP',
    key: 'ja',
    icon: '/static/images/navPage/ja.svg',
    arr: ['ja', 'jp', 'ja-JP']
  }
]

export function getOptions(lng: Lng = FALLBACK_LNG, ns: string = DEFAULT_NS) {
  return {
    // debug: true,
    supportedLngs: languages,
    // preload: languages,
    fallbackLng: FALLBACK_LNG,
    lng,
    fallbackNS: DEFAULT_NS,
    load: 'currentOnly',
    defaultNS: DEFAULT_NS,
    ns
  } as const
}
