export const system = {
  // export const SYSTEM = {
  contact: 'Contact us',
  qcTeam: '— The QC Team —',
  403: {
    tips403: '403 Region Restricted',
    tip1: 'Sorry, The Page That You Are Trying To Access Is Restricted From Your Location.',
    tip2: 'We Are Sorry To Inform You The Services Of This Website Are Currently Unavailable In Your Country/Region.'
  },
  404: {
    tips404: '404 Page Not Found',
    tips: 'There Are Three Main Reasons Why 404 Not Found Occurs:',
    tip1: '1.The Linked Content Has Been Deleted. ',
    tip2: '2.The Content Has Been Moved To Another URL Without Proper Redirection Settings.',
    tip3: '3.The User Manually Entered A URL, But Entered An Incorrect URL.',
    tipResult:
      'Of These, It Is Not Possible To 100% Prevent 404 Errors Caused By Users Manually Entering URLs, but There Are Solutions For The Other Two, So We Recommend That You Take Appropriate Measures As Soon As You Find Them.'
  },
  500: {
    tips500: '500 Server Error',
    tips: 'There Are Three Main Reasons Why 500 Server Occurs:',
    tip1: '1.Permission Error',
    tip2: '2.Defective Third-Party plug-Ins Or Themes',
    tip3: '3..Htaccess File Is Damaged Or Corrupted',
    solution: 'Solution',
    solutionItem: '1.Refresh The Page.',
    solutionItem1: '2.Come Back Later.',
    solutionItem2: '3.Delete Your Browser’s Cookies.',
    solutionItem3: '4.Disable Plug-Ins Or Themes.',
    solutionResult:
      'If The Above Solutions Still Cannot Solve Your Problem, Please Contact Your Server Administrator For Assistance.'
  },
  maintain: {
    btnText: 'MAJOR UPDATE',
    tips: 'Notice of Site Maintenance',
    tipsText:
      'We are currently performing website maintenance.<br/>We apologize for any inconvenience this may cause. We will be back up and running as soon as possible.Thank you for your understanding.'
  }
} as const
