export const common = {
  homeIcon: {
    more: 'More',
    open: 'Open',
    close: 'Close'
  },
  tabber: {
    menu: 'More',
    game: 'Games',
    wallet: 'Wallet',
    promo: 'Promo',
    account: 'Account',
    guide: 'Guide',
    faq: 'FAQ'
  },
  account: {
    destinationTag: 'Destination tag/Memo',
    destinationTagPlc: 'Please enter destination tag/Memo',
    ETHErrmsg: 'Start with 0x, 42 in total length',
    TRC20Errmsg: 'Start with big letter T, 34 in total length',
    BEP2Errmsg: 'Start with bnb, 42 in total length',
    BTCErrmsg: 'Start with 1 or 3 or bc1, at least 27 in total length',
    BCHErrmsg: 'Start with 1 or 3 or q or p or bitcoincash:, at least 26 in total length',
    LTCErrmsg: 'Start with L or M or ltc1, at least 26 in total length',
    XRPErrmsg: 'Start with r, at least 25 in total length',
    cardName: 'Please choose bank',
    cardNamePlc: 'Choose bank',
    cardNnm: 'Card number',
    cardErr: 'Bank card number format error',
    cardNnmPlc: 'Please enter your bank card number',
    name: 'Wallet name',
    enterName: 'Please enter wallet name',
    bankName: 'Bank name',
    bankNamePlc: 'Please enter Bank name',
    code: 'Issuing bank code',
    codePlc: 'Please enter issuing bank code',
    crypto: 'Choose crypto',
    cryptoPlc: 'Please Choose crypto',
    network: 'Select network',
    networkPlc: 'Please select network',
    address: 'Wallet address',
    wallet: 'Wallet Management',
    wallet2: 'Wallet',
    card: 'Bank card',
    virtual: 'Cryptocurrency',
    payment: 'Payment currency',
    kyc: 'This function can be used after verifying KYC',
    addCard: 'Add Bank Card',
    tips1:
      'You can bind up to 5 bank cards(include credit cards). We will strictly comply with the relevant regulations of the platform and encrypt your personal information to ensure your information security.',
    tips2:
      'Under the same agreement, you can bind up to 5 virtual currency addresses. We will strictly comply with the relevant regulations of the platform and encrypt your personal information to ensure your information security.',
    addVirtual: 'Add wallet address',
    deleteCard: 'Confirm to delete this bank card?',
    deleteVirtual: 'Confirm to delete the wallet address?',
    verify: 'Go to verify',
    voucher: 'Voucher',
    balance: 'Balance',
    funks: 'CASHER',
    multimode: 'Multi Window Mode',
    despoit: 'Deposit',
    withdraw: 'Withdraw',
    jl: 'BONUS / HISTORY',
    point: 'Bonus',
    reward: 'Reward',
    betStatus: 'BETTING',
    history: 'History',
    favorite: 'Favorite',
    statements: 'Statements',
    jsBet: 'Settled Bets',
    wjsBet: 'Unsettled Bets',
    myAccount: 'My Account',
    accountInfo: 'Personal',
    setPwd: 'Password',
    showSet: 'Preferences',
    accountVerify: 'Verification',
    selfSet: 'Self Exclusion',
    logOut: 'Log Out',
    allGame: 'All Game',
    pachinko: 'Pachinko',
    slots: 'Slots',
    liveCasino: 'Live Casino',
    top: 'Top',
    sport: 'Sport',
    bountyFisher: 'Shooting Game',
    kuiPachi: 'KuiPachi',
    personalCenter: 'Personal Center',
    vip: 'Loyalty Club',
    recFriend: 'Refer and Earn',
    notice: 'Message Center',
    globalSettings: 'Global Settings',
    playPachinko: 'Play Pachinko',
    PachinkoVideo: 'Pachinko Video',
    pachinkoBeginnerGuide: "Pachinko Beginner's Guide",
    Approved: 'Approved',
    welcome: 'Welcome to Queen Casino',
    tips: 'Please Sign up or Log in first',
    notAvailbale: 'waitting',
    back: 'Back',
    all: 'ALL',
    search: 'Search',
    success: 'SUCCESS'
  },
  footer: {
    links: [
      {
        title: 'Games',
        children: [
          {
            title: 'Pachinko'
          },
          {
            title: 'Live Casino'
          },
          // {
          //   title: "Baccarat",
          //   ischild: true
          // },
          // {
          //   title: "Roulette",
          //   ischild: true
          // },
          // {
          //   title: "Black Jack",
          //   ischild: true
          // },
          {
            title: 'Slots'
          },
          // {
          //   title: "Feature Buy-in",
          //   ischild: true
          // },
          {
            title: 'Bounty Fisher'
          },
          {
            isTitle: true,
            title: 'Sport'
          }
        ]
      },
      {
        title: 'About',
        children: [
          {
            title: 'About Queen Casino'
          },
          {
            title: 'Terms & Conditions'
          },
          {
            title: 'Campaign Term of Use'
          },
          {
            title: 'Sports Betting Rules'
          },
          {
            title: 'Site Policies'
          },
          {
            title: 'Bonus and Quest Rules'
          }
        ]
      },
      {
        title: 'Info',
        children: [
          {
            title: 'Guide',
            link: '/help/guide'
          },
          // {
          //   title: "Pachinko Beginner's Guide",
          //   link: "https://kui-pachi777.queen-casino.com/",
          //   Unshow: true
          // },
          {
            title: 'About Deposit',
            link: '/help/deposit_method'
          },
          {
            title: 'About Withdrawal',
            link: '/help/deposit_method'
          },
          {
            title: 'FAQ',
            link: '/faq'
          },
          {
            title: 'Contact Us',
            link: '/contact'
          },
          {
            title: 'Sitemap',
            link: '/sitemap'
          },
          {
            title: 'Affiliate',
            link: 'https://onlinegaming-affiliate.com/'
          }
        ]
      }
    ],
    H5Links: [
      {
        title: 'Games',
        children: [
          {
            title: 'Pachinko',
            link: '/pachinko?gameType=pachinko'
          },
          {
            title: 'Live Casino',
            link: '/live-casino'
            // content: [
            //   {
            //     title: "Baccarat",
            //     link: "/live-casino?gameType=baccarat"
            //   },
            //   {
            //     title: "Roulette",
            //     link: "/live-casino?gameType=roulette"
            //   },
            //   {
            //     title: "Black Jack",
            //     link: "/live-casino?gameType=black"
            //   }
            // ]
          },
          {
            title: 'Slots',
            link: '/slots'
            // content: [
            //   {
            //     title: "Popular games",
            //     link: "/slots?gameType=slotsPopuler"
            //   },
            //   // {
            //   //   title: "Feature Buy-in",
            //   //   link: "/slots?gameType=slotsFeature"
            //   // },
            // ]
          },
          {
            title: 'Bounty Fisher',
            link: '/fish'
          },
          {
            title: 'Sport',
            link: '/fb?gameType=sport'
          }
        ]
      },
      {
        title: 'About',
        content: [
          {
            title: 'About Queen Casino',
            link: '/static/about'
          },
          {
            title: 'Terms & Conditions',
            link: '/static/termsAndConditions'
          },
          {
            title: 'Campaign Term of Use',
            link: '/static/generalTermsOfUse'
          },
          {
            title: 'Sports Betting Rules',
            link: '/static/sportRules'
          },
          {
            title: 'Site Policies', // 新页面待开发
            link: '/sitePolicies'
          },
          {
            title: 'Bonus and Quest Rules',
            link: '/bonusRules'
          }
        ]
      },
      {
        title: 'Info',
        content: [
          {
            title: 'Guide',
            link: '/help/guide'
          },
          // {
          //   title: "Pachinko Beginner's Guide",
          //   link: "https://kui-pachi777.queen-casino.com/"
          // },
          {
            title: 'About Deposit',
            link: '/help/about-deposit-withdrawal'
          },
          {
            title: 'About Withdrawal',
            link: '/help/about_withdrawal'
          },
          {
            title: 'FAQ',
            link: '/faq'
          },
          {
            title: 'Contact Us',
            link: '/contact'
          },
          {
            title: 'Sitemap',
            link: '/sitemap'
          },
          {
            title: 'Affiliate',
            link: 'https://onlinegaming-affiliate.com/'
          }
        ]
      }
    ],
    credits:
      'QUEEN CASINO is operated and managed under the jurisdiction of Zen Estratega SRL., which has a TOBIQUE license No. 365/JAZ.',
    tip1: `By accessing, using, or navigating through our site, we use certain browser cookies to ensure that you agree to the <a href="/static/termsAndConditions">terms and conditions </a> described in our Terms of Use and to provide you with a balanced and comfortable experience. QUEEN CASINO never infringes on your privacy by using cookies. For information on how cookies are used and how to disable or manage the use of cookies, please refer to our <a href="/static/privacy">privacy policy</a>.`,
    tip2: `If you have any problems while using our site, please see our <a href="/static/faq">FAQ</a> and <a href="/static/sportRules">Sports Betting Rules</a>. Please refer to the description.`,
    ambassador: 'Ambassador',
    followUS: 'Follow Us',
    bottom: 'All rights reserved.',
    blog: 'Blog',
    news: 'News',
    streams: 'Stream',
    copyrights: '© {{0}} QUEEN CASINO  All Rights Reserved.'
  },
  headerNav: {
    liveCasino: 'LIVE CASINO',
    slots: 'SLOTS',
    tableGames: 'TABLE GAMES',
    sportsBet: 'SPORTS BET',
    eSports: 'E-SPORTS',
    virtual: 'VIRTURAL',
    promotions: 'Promotions'
  },
  service: 'Live Chat',
  serviceTitle: 'Leave a message for us',
  serviceCloseHint: 'You can find me here again',
  serviceCloseBtn: 'I got it',
  validtor: {
    changeSuc: 'Changed successfully!',
    usenameEpty: 'Please Input UserName',
    nameErr: 'Please enter 4-18 numbers or characters',
    phoneEpty: 'Please input phone number',
    emailEpty: 'Please input email address',
    japaneseNameErr: '片假名のみ使用してください',
    emailError: 'Email address error',
    passwordEpty: 'please input password',
    passwordError: 'Please use 6-32 characters of alphabets, numerals and symbol',
    pwdSame: 'Passwords do not match',
    codeError: 'Please input 6-digit Code',
    phoneVerify: 'Please input correct phone number',
    resetSuc: 'Reset successful!'
  },
  game: {
    goPlay: 'Real Play',
    funPlay: 'Fun Play',
    nodata: 'No Data',
    dailyBet: 'Daily Bet',
    winBet: 'Win Amount',
    loading: 'Loading...',
    all: 'All Games',
    hotGame: 'Popular',
    newGame: 'New',
    buyGame: 'Feature Buy-in',
    fun: 'Play For Fun',
    real: 'Play For Real',
    tips: 'You are not logged in. Please log in and start turning your spins to wins!',
    noSeachData: 'Oh no!<br/><br/>No matching results for ',
    otherSearch: 'Others searches for',
    popularity: 'Popularity',
    games: 'Games',
    maintenance: 'This game is under maintenance and will be back soon.',
    probability: 'Probability:',
    ball: '1 Ball:',
    roll: '1 Roll:',
    ceiling: 'Ceiling:',
    token: '1 Token:',
    type: 'Type:',
    typeGame: 'TYPE',
    responsible: 'Responsible Gambling',
    play: 'Play',
    pcIslot: 'Please use the mobile version for playing.',
    quickDeposit: 'Quick deposit',
    quitGame: 'Quit game',
    instructions: 'Instructions',
    addFavorite: 'Add Favorite',
    removeFavorite: 'Remove Favorite',
    addSuccess: 'Added successfully',
    cancelSuccess: 'Cancel success',
    openSmallWindow: 'Open small window',
    openedWindow: 'Small window is open',
    quitGameAsk: 'Are you sure to quit game?',
    quitGameFirst: 'Four games have been added, please quit game first.',
    depositFirst: 'Please deposit first',
    selectGames: 'Select Games',
    selectMoreGames: 'Select more games',
    moreopularPGames: 'More popular games',
    nosupport: 'This game does not support multi window mode',
    hide: 'Hide',
    goHomeAsk: 'Exit all games and back to homepage?',
    notSuppot: 'This game does not support multi-machine mode'
  },
  video: {
    buffering: 'Buffering...',
    replay: 'Replay',
    wrongRequest: 'Request Error',
    nomore: 'No more videos',
    tag: 'Video tag',
    seach: 'Search for the video name or author',
    more: 'More videos',
    sort1: 'All',
    sort2: 'Latest Release',
    sort3: 'Most Played'
  },
  footerHtml: {
    text: `
    Queen Casino is operated by Zen Estratega SRL, registration number: 3-102-897673, registered address: 400, Calle La Ceiba, San Rafael, Escazú, Province of San Jose, Costa Rica. Contact us at
    <span ><a href="mailto:support@queen-casino.com">support@queen-casino.com</a>.</span>
    Queen Casino retains a license from Tobique gambling license. Queen Casino passed all compliance and is legally authorized to conduct gaming operations for all games of chance and wagering.
     `,
    support: `
              <span>
                Support
                <a href="mailto:support@queen-casino.com">support@queen-casino.com</a>
              </span>
              <span>
                <span
                  style="margin-left: 2px"
                  class="line"
                >
                  |
                </span>
                Partners
                <a href="https://onlinegaming-affiliate.com">onlinegaming-affiliate.com</a>
              </span>`
  },
  notificTab: {
    reward: 'Reward',
    promotions: 'PROMOTIONS',
    news: 'NEWS'
  },
  levelUp: {
    willLevel:
      "<p style='padding-top: 8px;'>Upgrade <span>LV{{level}}</span> soon. After upgrade, you can get <span class='money'>${{bonu}}</span> bonus</p>",
    level1: "<p style='padding-top: 8px;'>Congratulations upgrade LV{{level}}</p>",
    level2: ' your <span>${{bonu}}</span> bonus has arrived.'
  },
  announcement: {
    preMsgContent2: '',
    preMsgContent0: '',
    preMsgContent1:
      'We are pleased to announce that KAMIKAZE is coming back to Queen Casino from Aug 12th!<br/><br/>【Opening Hours】<br/>10:00 - 08:00 (JPT) daily<br/>Wednesdays: 12:00 - 08:00 (JPT)<br/><br/>Please note that business hours may change due to emergency maintenance.<br/><br/>Thank you for your understanding.'
  },
  limitLogin: {
    title: "Sorry! It looks like you're trying to access Queen Casino from a restricted area!",
    bottom: 'Privacy Policy',
    Anti: 'Anti Money Laundering',
    Security: 'Security'
  },
  loading: 'Now Loading...'
} as const
