export const verification = {
  // export const VERIFICATION = {
  title: 'Verification',
  btn: 'Account information',
  kyc: "When you submit KYC document, we compare the details you fill in personal page and your national identification document, if they're not complete or exact match, account verification can be delayed.",
  photoVerify2: 'Uploaded images must be less than 10MB',
  kyecLevel: 'KYC Level',
  successOperation: 'Operation successful, please wait for review',
  operationSuccess: 'Successful operation',
  table: {
    date: 'Date',
    fileName: 'File name',
    size: 'Size'
  },
  status: {
    unVefiry: 'Confirming', //待认证
    verifying: 'Pending', //认证中
    Approved: 'Approved', //认证成功
    Rejected: 'Rejected' //认证失败
  },
  statusBtn: {
    Authenticated: 'Confirming', //待认证
    verifying: 'Pending'
  },
  uploadModel: {
    unUploadPhoto: 'No photos uploaded',
    takeNeed: 'Shooting requirements',
    takeTips:
      'Please take photos according to the sample image to ensure that facial features are clear in the photo',
    takeBtn: 'Take photo/upload'
  },
  moduleOne: {
    title001: 'Identity Information',
    title002: 'Phone & E-Mail',
    subTitle001:
      'The name must be consistent with the credit card and bill.Mobile phone number and email address where verification codes can be received.',
    text001: 'Email verification:',
    text002: 'Authenticated',
    text003: 'Your email address has been approved.',
    text004: 'Certain website features require that you verify your email.',
    text005:
      'Email Verification is the process of verifying an email address is valid and it belongs to a real person. The whole purpose of email verification is to ensure that a real person with a valid email address will receive and interact with the sent email.',
    pending: 'Pending',
    pendingDes: 'Email verification is pending',
    Verified: 'Authenticated',
    Approved: 'Approved'
  },
  moduleTwo: {
    modelTitle: 'KYC L2 Verification',
    modelId: 'ID / Age verification',
    modelHoldID: 'Holding your ID',
    modelWaitHold: 'Awaiting document upload',
    modelUpload: 'Add file',
    modelSave: 'Save',
    title001: 'ID Card / Drivers License / Passport',
    title002: 'Selfie taken with ID (with clear text on the ID)',
    subTitle001: 'Valid only for documents within the validity period',
    text001: 'Identity verification documents:',
    text002: 'Pending',
    text003: 'Identity verification is pending',
    text004: 'Select files',
    text005: 'Add file',
    text006: 'Save',
    text007: 'Please upload ID / Age verification document',
    fail: 'Rejected',
    failTitle: 'Account has been rejected.',
    failDes: 'Please contact customer support.',
    success: 'Authenticated',
    successDes: 'Identity successfully verified',
    uploadIDTitle: 'ID / Age verification',
    uploadIDDes: 'Awaiting document upload',
    uploadBillTitle: 'Address verification',
    uploadBillDes: 'Awaiting document upload',
    onlyUploadOne: 'Please upload the following documents',
    uploadSuccess: 'File uploaded successfully'
  },
  moduleThree: {
    modelBills: 'Address verification',
    modelTitle: 'KYC L3 Verification',
    title001: 'Utility bill',
    subTitle001: 'Only valid for documents issued within 3 months',
    text001: 'Certain website features require that you verify your identity.',
    text002: 'To verify your identity, please upload the following documents:',
    text003: 'ID Card / Drivers License / Passport',
    text004: 'Utility bill',
    text005: '(Only valid for documents issued within 3 months)',
    text006: 'Supported file types:',
    text007: '.gif, .jpeg, .jpg, .png, .tif, .tiff',
    text008: '(Maximum of 5 MB)',
    text009: 'Invalid document formats:',
    text010: '- black & white',
    text011: '- PDF / Word / Excel'
  },
  moduleFour: {
    modelTitle: 'KYC L4 Verification',
    title001: 'Income certificate',
    subTitle001:
      'Please upload your proof of income. All documents must be placed on a flat surface with all four corners framed so that all information is clearly visible.',
    text001: 'UPLOAD DATE',
    text002: 'FILE NAME',
    text003: 'FILE SIZE'
  }
} as const
