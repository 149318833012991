export const responsible = {
  nav1: 'お問い合わせ',
  nav2: '役立つヒント',
  bannerText: '健全にゲームを楽しもう！',
  bannerText2: '~Have Fun Play Healthy~',
  bannerBtn: 'お問い合わせ',
  title1: '心のコントロールを保つ方法',
  text1_1: 'コントロールを保つために、次の点を考慮して下さい。',
  text1_2: 'ギャンブルとは娯楽の一種であり、お金を稼ぐ手段ではありません。',
  text1_3: '損失金を取り戻そうとしてはいけません。',
  text1_4: '予算を決め、予算内でのみお楽しみ下さい。',
  text1_5: 'ギャンブルで日常生活に支障をきたさないようにして下さい。',
  text1_6: 'ギャンブルを始める前に、購入や賭け、そして損失にも制限をはっきり決めて下さい。',
  text1_7: '無理のない範囲で遊べる金額を設定し、ご利用金額の管理をしてください。',
  text1_8: '一時的にギャンブルを中止されたい場合は、タイムアウトの依頼するか、自己規制して下さい。',
  title2: 'ギャンブル依存',

  text2_1:
    'ギャンブル依存とは、悪い結果にも関わらず、継続的にギャンブルする欲望を抑えられないことです。ご自身、もしくは周りの方が過度にギャンブルを行っていないかを次の点を確認してみて下さい。',
  text2_2: '仕事や学校を休んでまでギャンブルをしていませんか？',
  text2_3: '現実逃避のためにギャンブルをしていませんか？',
  text2_4: '失望やイライラ時にすぐにギャンブルをしたいと思いますか？',
  text2_5: 'お金が底をつくまでギャンブルをしていませんか？',
  text2_6: '最近、ギャンブルをやめようと試みたけれども失敗しませんか？',
  text2_7: 'ギャンブルのために嘘をついたり、隠したりしたことがありますか？',
  text2_8: '他人からギャンブルを批判されたことがありますか？',
  text2_9: 'ギャンブルの資金を調達するために嘘をついたり、盗んだり、借金をしましたか？',
  text2_10: 'ギャンブルのための資金を他のことに使うことにためらいを感じますか？',
  text2_11: '家族や友人、趣味への関心を失っていませんか？',
  text2_12: '負けた後に取り戻すためにギャンブルを続ける必要性を感じますか？',
  text2_13: '口論や不満、失望時にギャンブルを衝動的に行いますか？',
  text2_14: 'ギャンブルによって意気消沈や自暴自棄になることがありますか？',
  text2_15: '多くの「はい」の回答がある場合、ギャンブル依存症の可能性があります。',
  title3: 'ポイント',
  text3_1: '健全なギャンブル体験を支える、大事なポイントをご紹介します。',
  cardTitle1: 'すべては「楽しむ」ために',
  cardText1:
    'ギャンブルや高リスクの賭けは、経済的な問題を解決する方法ではありません。ギャンブルはお客様の楽しみのために存在しています。プレイするのは、心から楽しめると感じるときにしましょう。生活の中で笑顔を増やす一環として、ギャンブルを楽しんでみてください！',
  cardTitle2: '予算の管理',
  cardText2:
    'お支払いできる範囲内で、プレイを楽しみましょう。ギャンブルを頻繁に行う場合は、将来的なゲームプレイを持続するために、自分の限度内で毎月のプレイ予算を設定することがおすすめです。自分に合った予算設定で、楽しみながらゲームをお楽しみください。',
  cardTitle3: '自己規制の設定',
  cardText3:
    'お客様がギャンブルに費やす時間と資金を管理するために、当社では「自己規制設定」をご用意しています。自分自身を大切にするための有効なツールですので、安心してご利用いただけます。楽しみながら賢くギャンブルをお楽しみください。',
  cardTitle4: '適度な休息',
  cardText4:
    'ギャンブルを楽しんでいると、時間の感覚を忘れてしまうことがあります。そんなときは、ぜひギャンブルから一時離れて、外出してみたり、大切な家族や友人と過ごしたり、新しいことにチャレンジしてみたりすることをおすすめします。心と体をリフレッシュさせることで、より充実した時間を過ごせるはずです。',
  cardTitle5: '自己分析',
  cardText5:
    '日常的に自分のギャンブルの傾向を理解することは、健康なギャンブルのサイクルを保つ上で非常に効果的です。ギャンブルがあなたの生活にどのような変化をもたらしているのか、もう一度じっくり考えてみましょう。あなたの健康と幸福を大切にするために、適切な選択をすることが大切です。',
  cardTitle6: '過度な飲酒を避ける',
  cardText6:
    'お酒は判断力を鈍らせてしまいます。お酒とギャンブルは別々に楽しむことが、賢明な選択と言えるでしょう。お互いを分けて楽しむことで、より健康的でバランスの取れた体験を享受できます。自分の心身のケアを大切にしながら、楽しみを見つけていきましょう。',
  title4: '自己規制を設定しよう',

  text4_1: '「自己規制機能」を利用して、ログイン制限を設定できます。',
  text4_2: 'サイト利用の一時中断をご希望の場合、最長90日間の自己規制を選択できます。',
  text4_3: 'マイアカウントの「自己規制」から設定が可能です。自己規制を適用すると、',
  text4_4: '入金やアカウントへのアクセスが制限され、規制期間が終了するまで解除できません。',
  text4_5:
    '生活に支障をきたすなど緊急を要しており、90日以上の自己規制をただちに希望している場合は、<a href="www.support@queen-casino.com">support@queen-casino.com</a> までメールでお申し込みください。',
  title4Btn: '自己規制ページへ',

  title7: '未成年賭博の防止',
  text7_1:
    '18歳未満のギャンブルを防止するため、ご本人様情報の登録が完了した時点でお客様の年齢や身元の自動チェックを行います。お客様が18歳以上であることを確認できない場合は、72時間以内に身分証明書のコピーをご提出いただくようお願いしています。',
  text7_2:
    'この手続きは、未成年の方々を保護し、安全な環境を提供するための大切な措置です。私たちはお客様の安全を最優先に考えています。未成年者のギャンブルは健康な成長や未来に悪影響を及ぼす可能性がありますので、正確な年齢確認は非常に重要です。',
  text7_3:
    'ご協力いただき、未成年者のギャンブルを防止するために一緒に取り組んでいきましょう。お客様の安全と幸福を守るために、私たちは努力を惜しみません。どんな質問やご相談でもお気軽にお問い合わせください。',
  title8: '未成年をギャンブルから守るには',

  text8_1:
    '最高のサービスを提供するためには、お客様のご協力が不可欠です。特に未成年者がインターネットにアクセスできる場合には、以下の点にご留意いただけると幸いです。',
  text8_2:
    'ログイン名やパスワード、口座情報などの重要な情報を未成年者に知らせないようにお願いします。これにより、未成年者が無断でアクセスしたり利用したりするリスクを減らすことができます。',
  text8_3:
    'サイトの利用を終えた際には必ずログアウトしてください。これにより、他の人がアカウントにアクセスすることを防ぐことができます。',
  text8_4:
    '未成年者の保護のためには、インターネット上でフィルタリング・ソフトウェア（アクセス制限ソフトウェア）をインストールすることがおすすめです。これにより、コンテンツの表示を制御したり、特定のウェブサイトをブロックしたり、パスワードをかけたりすることができます。',
  text8_5:
    '未成年者を守るための対策は非常に重要です。私たちはお客様の安全を最優先に考えていますので、これらの対策をご活用いただくことをおすすめします。何かご質問やご相談がありましたら、いつでもお気軽にお問い合わせください。あなたとあなたの大切な人々の安全と幸せをサポートします！',
  title9: '助けはありますか?',
  text9_1:
    '20歳未満のご家族や友人のギャンブルが心配な場合は、訓練を受けた専門家が対応してくれる支援機関があります。20歳未満のギャンブルは、金銭的および行動上のリスク以外にも、法的影響を伴う可能性があることをご留意ください。',
  text9_2:
    '下記の第三者企業のアプリを使って、潜在的に有害なサイトへの20歳未満のアクセスを制限することをお勧めします。',
  title11: 'ヘルプ&それでも不安な場合',
  text11_1: 'もしもご自身のギャンブルをコントロールすることが難しく感じられる場合は、',
  text11_2: 'どんな小さな兆候でも周りの方々に助けを求めることが大切です。',
  text11_3: '正しい判断をするために、信頼できる支援機関にも是非ご相談ください。',
  supportBtn: '関連情報を見る',
  supportTitle1:
    'Gamstopでは、登録者の希望期間中のギャンブル関連のウェブサイトやアプリへのアクセスを制限できる、無料サービスを提供しています。',
  supportTitle2:
    '無料カウンセリングやナショナル・ギャンブル・ヘルプラインなど、ギャンブル関連の問題に対するサポート、アドバイス、情報を提供するイギリスの主要機関です。',
  supportTitle3:
    'サポートグループ、フォーラム、リソースデータベースやEメールサポートなど、ギャンブル依存者、およびそのご家族や友人へのオンラインによるサポートを提供しています。',
  supportTitle4:
    'ギャンブル依存の問題とその治療に関する情報やリソースを提供する独立したチャリティー団体です。',
  supportTitle5: 'ギャンブル依存者のための無料の居住治療とリハビリを提供する専門機関です。',
  supportTitle6:
    'ギャンブル依存により影響を受けたすべての人を支援する機関です。ギャンブル依存者、およびその家族や友人が参加できるサポートグループを提供しています。',
  title12: 'お問い合わせ',
  text12_1:
    'なにから始めたらいいかわかりませんか? カスタマーサポートスタッフが、ライブチャット、またはEメールでお客様をお手伝いします。',
  title12CardTitle1: 'チャットサポート',
  title12CardText1: 'チャットサポートは24時間、ご利用いただけます。',
  title12CardBtn1: 'チャットを始める',
  title12CardTitle2: 'メールサポート',
  title12CardText2:
    '日本語カスタマーサポートよりご返信させていただきます。どうぞお気軽にお問い合わせください。',
  title12CardBtn2: 'メールを送る'
} as const
