export const benefit = {
  // export const BENEFIT = {
  title: 'Be promoted to VIP in real time by betting with cash! Experience our customized service for you!',
  title2: 'QueenCasino Loyalty Club offers players with exclusive benefits!!',
  btnLeft: 'Play Game',
  title3:
    'The Loyalty Club is by invitation only.  Only customers who have passed a rigorous screening process by the relevant department will be invited.',
  title4: 'Queens Casino The Loyalty Club  By Invitation Only',
  btnRight1: 'Learn More',
  btnRight2: 'Play Game',
  conTitle: 'Privilege Details',
  conTitle2:
    "As a player, if you manage to reach the pinnacle of achievement, you'll be generously rewarded with a bonus ranging from $17,500 to $500,000.",
  conTitle3:
    "Additionally, you'll gain access to an enhanced level of service and an unparalleled gaming experience that is truly one-of-a-kind. Get ready to embark on an exciting journey with us and indulge in endless enjoyment!",
  fit1: 'キャッシュバック',
  benText2: 'Exclusive event invitations',
  benText3: 'Cash Back',
  benText4: 'Exclusive Bonus',
  benText5: 'Dedicated support service',
  benText6: 'Customized Global Travel',
  benText7: 'Experience exclusive events around the world',
  benText8: 'Wager-free rewards',
  benText9: 'Top priority for withdrawal processing',
  benText10: 'Rakeback Bonus',
  benText11: 'Level Up Quest',
  benText12: 'Birthday Bonus',
  benText13: 'Exclusive-Customer Service',
  benText14: 'Legend Club customized tour (Invitation only)',
  benText15: 'Luxury Hotel Suites',
  benText16: 'Weekly/Monthly Bonus',
  benContent: 'Receive rakeback of 0.05% to 0.3%<br/>LV21~LV100: Once a week<br/> LV101 and above: Any time.',
  benContent1: 'Each promotion success is celebrated with a quest to push you on to the next level.',
  benContent2: 'Based on the level of the month, birthday bonus can be obtained on the birthday!',
  benContent3:
    'Legend Club service, providing exceptional assistance through careful request handling by listening to your needs.',
  benContent4: 'Embark on a journey of ultimate luxury',
  benContent5:
    'Indulge in the royal treatment by joining our Legend Club program and staying at our top-rated partner hotels.',
  benContent6: 'Unlock more bonuses and promotions exclusively for Legend Club members',
  benContent7: 'Calculate the total weekly loss value and return cash to you!',
  Iron: 'Iron',
  Bronze: 'Bronze',
  Silver: 'Silver',
  Gold: 'Gold',
  Platinum: 'Platinum',
  Emerald: 'Emerald',
  Diamond: 'Diamond',
  Legend: 'Legend',
  queenCasino: 'Queen Casino',
  NextLevel: 'Next Level',
  loyaltyClub: 'The Legend Club',
  invitationOnly: 'By Invitation Only',
  benefitsInfo: {
    levelUpFreeSpins: 'Level Up Free Spins'
  }
} as const
