export const system = {
  contact: 'お問い合わせ',
  qcTeam: '— QCチーム —',
  403: {
    tips403: '403 エリアの禁止',
    tip1: '申し訳ございませんが、アクセスしようとしているページは、現在のエリアからアクセスが制限されています.',
    tip2: '申し訳ございませんが、このサイトのサービスは現在お住まいの国ではご利用いただけません.'
  },
  404: {
    tips404: '404 ページが見つかりませんでした',
    tips: '404 エラーが表示される主な理由は 3 つあります:',
    tip1: '1.リンク先のコンテンツが削除された可能性があります. ',
    tip2: '2.コンテンツを別のURLに移動した後、適切なリダイレクト設定が行われていません.',
    tip3: '3.ご自身がURLを手動で入力し、間違った URL を入力してしまった.',
    tipResult:
      'このうち、ご自身がURLを手動で入力した場合の404 エラーを 100% 防ぐことはできませんが、その他の 2 つについては解決策がありますので、見つけたらすぐに適切な対処を行うことをお勧めします.'
  },
  500: {
    tips505: '500サーバーエラー',
    tips: '500 エラーが発生する主な理由は3つあります:',
    tip1: '1.権限エラー',
    tip2: '2.不具合のあるサードパーティのプラグインやテーマ',
    tip3: '3..htaccessファイルが破損している',
    solution: '解決方法：',
    solutionItem: '1.ページの更新.',
    solutionItem1: '2.しばらく時間をおいてから再度アクセスしてください.',
    solutionItem2: '3.ブラウザのクッキーを削除してください.',
    solutionItem3: '4.プラグインやテーマを無効にしてみてください.',
    solutionResult: '上記の解決策でも問題が解決しない場合、サーバー管理者までお問い合わせください.'
  },
  maintain: {
    btnText: 'メジャーアップデート',
    tips: 'サイトメンテナンスのお知らせ',
    tipsText:
      '現在、ウェブサイトのメンテナンスを行っています。<br/> ご不便をおかけし大変申し訳ありません。<br/>できるだけ早く再開できるよう努めておりますので、<br/>ご理解のほどよろしくお願い申し上げます。'
  }
} as const
