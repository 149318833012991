export const addToHome = {
  title: 'インストール方法',
  content: '「ホーム画面に追加」をタップしてインストール。いつでもどこでも遊べます！',
  btn1: 'ホーム画面に追加',
  btn2: '今後表示しない',
  iosStepsTitle1: 'Safari ブラウザで Web サイトを開きます。',
  iosStepsContent: 'スマホのホーム画面でSafari ブラウザから Web サイトを開きます。',
  btnSkip: 'スキップ',
  next: '次へ',
  iosTitle1: 'Safari ブラウザで Web サイトを開きます.',
  iosContent1: 'スマホのホーム画面でSafari ブラウザから Web サイトを開きます。',
  iosTitle2: '「共有」をタップします.',
  iosContent2: 'Safari APP を使用してshinqueen.comにアクセスし、「共有」ボタンをタップします。',
  iosTitle3: '「ホーム画面に追加」をタップします。',
  iosContent3:
    'ポップアップリストで「ホーム画面に追加」をタップして、ホーム画面に追加します。「ホーム画面に追加」 ボタンを見つけるためには、画面を下にスワイプする必要がある場合があります。',
  iosTitle4: '「追加」をタップします。',
  iosContent4: '「ホーム画面に追加」するには、右上の「追加」をタップしてください。',
  iosTitle5: 'これでインストールは完了です。',
  iosContent5: 'ホーム画面にクイーンカジノを表示することができます。',
  btnEnd: 'わかりました',
  androidTitle1: 'Chromeで Web サイトを開きます.',
  androidContent1: 'スマホのホーム画面でChromeブラウザから Web サイトを開きます.',
  androidTitle2: '「その他」をタップします.',
  androidContent2:
    'Chrome APP を使用してshinqueen.comにアクセスし、右上隅にある「詳細」ボタンをタップします:',
  androidTitle3: '「ホーム画面に追加」をタップします.',
  androidContent3: 'ポップアップリストで「ホーム画面に追加」をタップして、ホーム画面に追加します.',
  androidTitle4: '「追加」をタップします。',
  androidContent4: '「追加」をタップしてホーム画面に追加します.',
  androidTitle5: 'これでインストールは完了です',
  androidContent5: 'ホーム画面にクイーンカジノを表示することができます。'
} as const
