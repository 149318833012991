export const misc = {
  // export const MISC = {
  accountDisabled: {
    title: 'Account suspended',
    text001: 'You have excluded yourself.',
    text002: 'Thank you.'
  },
  faq: {
    title: 'FAQ',
    quickSearch: 'Quick Search',
    selectPlaceholder: 'Please Select',
    rules: 'Bonus and Quest Rules'
  },
  landpage: {
    title: 'Challenge yourself to make a deposit with attractive Cryptocurrency!',
    descrtion:
      'Here we will provide you with a convenient method of depositing with Cryptocurrency that no need KYC. Check out the benefits of Cryptocurrency, how to open an exchange account, and more, and try making a deposit with Cryptocurrency!',
    moduleTitle1: 'Instant deposit',
    moduleTitle2: 'High Security for Peace of Mind in Every Trade',
    moduleTitle3: 'Exclusive offers unique to Cryptocurrencies',
    moduleTitle4: 'Deposit with Cryptocurrency now!',
    moduleText1:
      'Deposits made in Cryptocurrency will be reflected in your account in just a few seconds. We provide an environment where you can play as soon as you want without the stress of waiting for payment confirmation.',
    moduleText2:
      'Transactions with Cryptocurrencies are highly anonymous and secure as there is no need to enter personal information such as credit card or account information.',
    moduleText3:
      'In addition to regular deposit promotions, Queen Casino also runs special exclusive offers promotions that only apply to Cryptocurrency deposits.',
    moduleText4:
      'There are many attractions to depositing with Cryptocurrency, such as no KYC required, instant deposit, high security, and abundant promotions! Please try making a deposit using Cryptocurrency!',
    text1: 'Here are the representative Cryptocurrency exchanges in Japan！',
    text2:
      '(When using Cryptocurrency, we recommend that you first go through these Japanese Cryptocurrency exchanges and then go through an overseas Cryptocurrency exchange to maintain your anonymity.)',
    btn: 'Deposit with Cryptocurrency'
  },
  namePromptDialog: {
    title: 'Please enter the name of the bank cash card holder.',
    countdownText: 'Please confirm again',
    note01:
      'If the name you enter does not match the name of the bank account holder, you will not be able to make a deposit. ',
    note02: 'Please check the name printed on your bank cash card before entering it.'
  },
  notice: {
    tab1: 'Marketing Info',
    tab2: 'Notification',
    detailBtn: 'View Details',
    noShowToday: 'Do Not Show Today',
    empty: 'No information',
    oneClickRead: 'Click to read',
    oneClickDelete: 'Click to delete',
    all: 'All',
    message: 'Website Message',
    notification: 'System Announcement',
    announcement: 'Important Notice',
    updataNotification: 'Update Notification',
    messageType: 'Message type',
    reset: 'Reset',
    confirm: 'Confirm',
    isEnd: 'Data has been finalized ',
    deleteOne: 'Are you sure to delete this message?',
    deleteAll: 'Are you sure to delete all messages?',
    notify: 'Notification',
    deletedSuccessfully: 'Deleted successfully',
    showAll: 'show all'
  },
  password: {
    text001: 'Passwords should contain at least 6 characters: letters, digits and special symbols.',
    text002:
      'Your password should not contain your username, name, email address or year of birth. You can increase the strength of your password by using a mixture of letters, digits and symbols. Please remember that passwords are case sensitive.',
    oldPwd: 'CURRENT PASSWORD',
    oldPwdPla: 'Current password',
    newPwd: 'NEW PASSWORD',
    newPwdPla: 'New password',
    newPwdConfirm: 'CONFIRM NEW PASSWORD',
    newPwdConfirmPla: 'Confirm new password',
    btn001: 'EDIT',
    btn002: 'UPDATE',
    btn003: 'CANCEL',
    errText001: 'Required field',
    errText002: 'Passwords do not match',
    modifySuccess: 'Password changed successfully, Please login again.',
    inputPwd: 'new password',
    inputComPwd: 'Confirm new password'
  },
  preferences: {
    title: 'Account Preferences',
    text001: 'You can set the display of the site.',
    text002: 'TIMEZONE',
    text003: 'LANGUAGE',
    text004: 'SPORTS VIEWS FORMAT',
    text005: 'ODDS FORMAT',
    btn001: 'EDIT',
    btn002: 'UPDATE',
    btn003: 'CANCEL',
    language: {
      en: 'English',
      js: '日本語'
    },
    sportsViewsFormat: {
      text001: 'Asian',
      text002: 'American',
      text003: 'European'
    },
    oddsFormat: {
      text001: 'Decimal',
      text002: 'Hong Kong',
      text003: 'Malay',
      text004: 'Indonesian',
      text005: 'American'
    },
    modifySuccess: 'Your data has been successfully saved'
  },
  router: {
    home: 'HOME'
  },
  test: {
    promo: 'Promo',
    slot: 'Slot',
    ori: 'Ori'
  }
} as const
