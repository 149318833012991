export const help = {
  // export const HELP = {
  title: 'Beginners guide',
  selectAll: '--- Select Menu ---',
  help: {
    helpTitle: 'BEGINNERS GUIDE',
    navHead1: 'Beginners guide',
    navContent1: 'Game Lobby',
    navHead2: 'Registration Guide',
    navContent2_1: 'User Registration (PC)',
    navContent2_2: 'User Registration (Mobile)',
    navHead3: 'Deposit/ Withdrawal Guide',
    navContent3_1: 'Payment Method List',
    navContent3_2: 'Deposit Method (PC)',
    navContent3_3: 'Deposit Method (Mobile)',
    navContent3_4: 'About withdrawal methods',
    navHead4: 'About services',
    navContent4_1: 'About bonuses',
    navContent4_2: 'About Q-PON',
    navContent4_3: 'About VIP Status'
  },
  novice: {
    noviceTitle1: 'How to see Game Lobby , Before Log in (PC)',
    noviceContent1_1_1: 'Queen Casino Official site is game lobby itself.',
    noviceContent1_2_1: '',
    noviceContent1_2_2: '',
    noviceContent1_3_1: 'Home',
    noviceContent1_3_2: 'Go to Queen Casino Home Page',
    noviceContent1_4_1: 'Header Menu',
    noviceContent1_4_2: 'You can choose Game Genres and Promotion page. You can enjoy free trial.',
    noviceContent1_5_1: 'User registration',
    noviceContent1_5_2: 'Go to user registration page from this button.',
    noviceContent1_5_3: '',
    noviceContent1_6_1: 'Login',
    noviceContent1_6_2: 'You can login from this button.',
    noviceContent1_6_3: '',
    noviceContent1_6_4: 'Yes.',
    noviceContent1_7_1: 'Promotion',
    noviceContent1_7_2: 'Information of new promotions.',
    noviceContent1_8_1: 'Support',
    noviceContent1_8_2: 'Capable to chat with English customer support team.',

    noviceTitle2: 'How to see Game Lobby , After Log in (PC)',
    noviceContent2_1_1: 'After log in Queen Casion, you can see remained balance and deposit button.',
    noviceContent2_2_1: '',
    noviceContent2_2_2: '',
    noviceContent2_3_1: 'User Icon',
    noviceContent2_3_2: 'You will see the menu items on your account in this icon.',
    noviceContent2_4_1: 'VIP Status',
    noviceContent2_4_2: 'You can see your VIP Status.',
    noviceContent2_5_1: 'User Name',
    noviceContent2_5_2: 'You can check your username here.',
    noviceContent2_6_1: 'Balance',
    noviceContent2_6_2: 'Shows your remained balance',
    noviceContent2_7_1: 'Promotion',
    noviceContent2_7_2: 'Information of new promotion',
    noviceContent2_8_1: 'Deposit',
    noviceContent2_8_2: 'Can chat with Japanese native customer support team.',

    noviceTitle3: 'How to see Game Lobby , Before Log in (mobile)',
    noviceContent3_1_1: '',
    noviceContent3_2_1: 'You can see the Menu here.',
    noviceContent3_2_2: '',
    noviceContent3_3_1: 'Go to Queen Casino Home Page.',
    noviceContent3_3_2: '',
    noviceContent3_4_1: 'Click here if you are our member already.',
    noviceContent3_4_2: '',
    noviceContent3_4_3: '',
    noviceContent3_4_4: '',
    noviceContent3_5_1: 'Information of new promotion.',
    noviceContent3_5_2: '',
    noviceContent3_6_1: 'Can chat with Japanese native customer support team.',
    noviceContent3_6_2: '',

    noviceTitle4: 'How to see Side Menu (Mobile)',
    noviceContent4_1_1: 'After Log in, you can see Remained balance and deposit button on Header Bar.',
    noviceContent4_2_1: 'You can select the language ( English, Japanese, Thai language)',
    noviceContent4_2_2: '',
    noviceContent4_3_1: ' You can choose Game Genres and Promotion page.',
    noviceContent4_3_2: '',
    noviceContent4_4_1: 'You can check Terms & Condisions and explanation of site.',
    noviceContent4_4_2: '',

    noviceTitle5: 'How to see My Menu (Mobile)',
    noviceContent5_1_1: 'When log in, you can see User icon at upper right side of Header bar',
    noviceContent5_2_1: 'When clickng Icon, you can see my menu and check remained balance and other menus.',
    noviceContent5_3_1: 'User name',
    noviceContent5_4_1: 'Remained Balance',
    noviceContent5_5_1: 'Deposit, Withdrawal Button',
    noviceContent5_6_1: 'Play history, other menus'
  },
  accountList: {
    accountListTitle1: 'Types of deposit methods',
    accountListContent1_1_1: `<div style="text-align: center">The following are the methods you can use to deposit in QUEEN CASINO</div>`,
    bankList: [
      { name: 'Bank transfer', description: 'You can transfer money from an ATM' },
      {
        name: 'Credit Card',
        description: 'MasterCard, JCB, American Express, Diners Club, Discover are acceptable'
      },
      {
        name: 'WlandPay',
        description: 'MasterCard and JCB are acceptable.'
      },
      { name: 'CoinPayment', description: 'Bitcoin and cryptos are acceptable.' },
      {
        name: 'EcoPayz',
        description:
          'ecoPayz is one of the most widely used and trusted payment methods and it is accepted at practically every online casino.'
      },
      {
        name: 'iWallet',
        description:
          'iWallet is one of the most widely used and trusted payment methods and it is accepted at practically every online casino.'
      }
    ],
    accountListContent1_2_1: 'Bank transfer',
    accountListContent1_2_2: 'You can transfer money from your local ATM or bank.',
    accountListContent1_3_1: 'Credit Card',
    accountListContent1_3_2: `<p>
                               MasterCard, JCB, American Express,<br />
                               Diners Club, Discover<br />
                               Compatible with
                               </p>`,
    accountListContent1_4_1: 'WlandPay',
    accountListContent1_4_2: `<p>MasterCard, JCB<br />
                             Compatible with </p>`,
    accountListContent1_5_1: 'CoinPayment',
    accountListContent1_5_2: 'Supports Bitcoin and many other cryptocurrencies.',
    accountListContent1_6_1: 'ecoPayz',
    accountListContent1_6_2: 'A payment service that allows deposits and withdrawals at many online casinos.',
    accountListContent1_7_1: 'iWallet',
    accountListContent1_7_2: 'A payment service that allows deposits and withdrawals at many online casinos.',
    accountListTitle2: 'Limit amount by deposit type',
    accountListContent2_1_1:
      '* We will consult with you about the maximum deposit amount and increase depending on your account status.',
    accountListContent2_2_1: 'type',
    accountListContent2_2_2: 'fee',
    accountListContent2_2_3: 'one-time transaction limit',
    accountListContent2_2_4: 'time required',
    accountListContent2_3_1: `<tbody>
                                    <tr>
                                      <td>Bank Transfer</td>
                                      <td>free<br>
                                      (only bank transfer fee will be charged to the customer)</td>
                                      <td>minimum transaction amount : 20 USD<br>
                                      maximum transaction amount : 10,000 USD</td>
                                      <td align="center">Immediately after confirming payment</td>
                                    </tr>
                                    <tr>
                                      <td>CoinPayment</td>
                                      <td>(only transfer fee will be charged to the customer)</td>
                                      <td>minimum transaction amount : 50 USD<br>
                                      maximum transaction amount : 5,000 USD</td>
                                      <td align="center">Immediately</td>
                                    </tr>
                                    <tr>
                                      <td>WlandPay</td>
                                      <td>free</td>
                                      <td>minimum transaction amount : 10 USD<br>
                                      maximum transaction amount : 1,000 USD</td>
                                      <td align="center">Immediately</td>
                                    </tr>
                                    <tr>
                                      <td>ecoPayz</td>
                                      <td>free</td>
                                      <td>minimum transaction amount : 10 USD<br>
                                      maximum transaction amount : 5,000 USD<br>
                                      <br>
                                      * We will consult with you about the maximum deposit amount and increase depending on your account status.</td>
                                      <td align="center">Immediately</td>
                                    </tr>
                                    <tr>
                                      <td>iWallet</td>
                                      <td>free</td>
                                      <td>minimum transaction amount : 10 USD<br>
                                      maximum transaction amount : 5,000 USD<br>
                                      <br>
                                      * We will consult with you about the maximum deposit amount and increase depending on your account status.</td>
                                      <td align="center">Immediately</td>
                                    </tr>
                                  </tbody>
       `,
    accountListTitle3: 'Types of withdrawal methods',
    accountListContent3_4_1: `<div style="text-align: center">The following are the methods you can use to withdraw from QUEEN CASINO</div>`,
    accountListTitle4: 'Limits and Time Required by Withdrawal Type',
    accountListContent4_4_1: `<table class="table">
                                  <thead>
                                      <tr>
                                          <th style="min-width: 110px;">type</th>
                                          <th>fee</th>
                                          <th>one-time transaction limit</th>
                                          <th style="text-align: center;">time required</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr>
                                          <td>Bank Transfer</td>
                                          <td>free<br>
                                          (only bank transfer fee will be charged to the customer)</td>
                                          <td style="width: 300px">minimum transaction amount : 50 USD<br>
                                          maximum transaction amount : 5,000 USD</td>
                                          <td align="center">within 12 hours</td>
                                      </tr>
                                      <tr>
                                          <td>CoinPayment</td>
                                          <td>(only transfer fee will be charged to the customer)</td>
                                          <td>minimum transaction amount : 50 USD<br>
                                          maximum transaction amount : 5,000 USD</td>
                                          <td align="center">within 1-2 hours</td>
                                      </tr>
                                      <tr>
                                          <td>ecoPayz</td>
                                          <td>free</td>
                                          <td style="width: 300px">minimum transaction amount : 10 USD<br>
                                          maximum transaction amount : 5,000 USD</td>
                                          <td align="center">within 12 hours</td>
                                      </tr>
                                      <tr>
                                          <td>iWallet</td>
                                          <td>free</td>
                                          <td style="width: 300px">minimum transaction amount : 10 USD<br>
                                          maximum transaction amount : 5,000 USD</td>
                                          <td align="center">within 12 hours</td>
                                      </tr>
                                  </tbody>
                              </table>`
  },
  depositPc: {
    tu: {
      tu1: 'click the deposit button',
      tu2: 'select deposit type',
      tu3: 'enter required information and complete your deposit'
    },
    content: [
      {
        dt: 'How to deposit with PC',
        dd: [
          {
            type: 'text',
            text: 'after logging in to QUEEN CASINO, deposit will be completed in the following 3 steps.'
          },
          { type: 'tu', text: '', width: 100 },
          {
            type: 'text',
            text: 'You can use any methods to make a quick and easy deposit, but please note that iWallet requires you to open an account first. Here is a step-by-step guide on how to make a deposit.'
          }
        ]
      },
      {
        dt: 'The selection of deposit method',
        dd: [
          { type: 'text', text: 'Click [deposit] at the right side of the top page.' },
          { type: 'img', text: 'depositPC-en-US-1.jpg' },
          {
            type: 'text',
            text: 'you will be redirected to the deposit page so please check and select your preferred deposit method.'
          },
          { type: 'img', text: 'depositPC-en-US-2.jpg' },
          {
            type: 'text',
            text: 'The procedure will change depending on the deposit method, so please see the respective sections.'
          }
        ]
      },
      {
        dt: 'Bank Transfer',
        dd: [
          {
            type: 'text',
            text: 'Queen Casino accepts bank transfers, making instant deposits very easy.'
          },
          { type: 'img', text: 'depositPC-en-US-3.jpg' }
        ]
      },
      {
        dt: 'Once click Hi-BANQ, the following screen will be displayed.',
        dtg: true,
        dd: [
          { type: 'img', text: 'depositPC-en-US-4.jpg', width: 50 },
          {
            type: 'text',
            width: 50,
            text: `<div>
                      <ul class="num-li">
                        <li>Amount to deposit (minimum of 20 dollars)</li>
                      </ul>
                      <div>Please fill in all the above items and click the [Deposit] button.</div>
                      </div>`
          }
        ]
      },
      {
        dt: 'Move to the Hi-BANQ page',
        dtg: true,
        dd: [
          { type: 'img', text: 'depositPC-en-US-5.jpg', width: 50 },
          {
            type: 'text',
            text: 'Please check the details and click the [Deposit] button.',
            width: 50
          }
        ]
      },
      {
        dt: 'You will receive an email when the deposit application is completed.',
        dtg: true,
        dd: [
          {
            type: 'text',
            text: 'When the deposit is completed, redirect to Queen Casino page as the following screen.'
          },
          { type: 'img', width: 50, text: 'depositPC-en-US-6.jpg' },
          {
            type: 'text',
            width: 50,
            text: 'Within a few minutes, you will receive an email from Hi-Banq regarding payment guidelines.'
          }
        ]
      },
      {
        dt: 'Credit Card',
        dd: [
          {
            type: 'text',
            text: 'we also accept deposits through credit cards.'
          }
        ]
      },
      {
        dt: 'the following screen will be displayed once you check credit card.',
        dtg: true,
        dd: [
          { type: 'img', text: 'depositPC-en-US-7.jpg' },
          {
            type: 'text',
            text: 'For credit card payment, we have partnership with WlandPay, and you can deposit from them.'
          }
        ]
      },
      {
        dt: 'Payment with WlandPay',
        dtg: true,
        dd: [
          { type: 'img', width: 50, text: 'depositPC-en-US-8.jpg' },
          {
            type: 'text',
            width: 50,
            text: `<div>
                      <div>Please enter the items in order from the top without making any mistakes.</div>
                      <ul class="num-li">
                        <li>amount of deposit (minimum of 10 dollars)</li>
                        <li>credit card number (16 digits)</li>
                        <li>expiration date (month・year)</li>
                        <li>CVV number (3 digits) * Usually located on the right side of the signature line on the back of the card</li>
                        <li>Telephone number (No hyphen</li>
                      </ul>
                      <div>you will find information on each of the deposit methods below.</div>
                      </div>`
          }
        ]
      },
      {
        dt: 'CoinPayment (Crypto Currency)',
        dd: [
          {
            type: 'text',
            text: 'You can make deposits in Bitcoin and other Crypto currencies.'
          },
          { type: 'img', text: 'depositPC-en-US-9.jpg' },
          {
            type: 'text',
            text: 'Please check the details and click the [Deposit] button.'
          }
        ]
      },
      {
        dt: 'The following screen will be displayed once you check CoinPayment.',
        dtg: true,
        dd: [
          {
            type: 'img',
            width: 50,
            text: 'depositPC-en-US-10.jpg'
          },
          {
            type: 'text',
            width: 50,
            text: `<div>
                      <div>Please enter the items without making any mistakes.</>
                      <ul class="num-li">
                        <li>Select Crypto Currency</li>
                        <li>Amount of deposit</li>
                      </ul>
                      <div>Click "Generate Address" to start generating the address for deposit.</div>
                      </div>`
          }
        ]
      },
      {
        dt: 'Copy the generated address and complete the transfer from your wallet app.',
        dtg: true,
        dd: [
          { type: 'img', width: 50, text: 'depositPC-en-US-11.jpg' },
          {
            type: 'text',
            width: 50,
            text: `<div>
                        <div>When the address is generated, click on it to copy the address.</div>
                        <div>You can make a transfer to the address from your crypto currency application.<br>
                        Your payment will be reflected as soon as we confirm the receipt of your transfer.</div>
                    </div>`
          }
        ]
      },
      {
        dt: 'iWallet',
        dd: [
          {
            type: 'text',
            text: 'iWallet is currently accepted by many online casinos as payment method, so it is highly recommended.'
          },
          { type: 'img', text: 'depositPC-en-US-12.jpg' }
        ]
      },
      {
        dt: 'the following screen will be displayed once you check iwallet.',
        dtg: true,
        dd: [
          { type: 'img', width: 50, text: 'depositPC-en-US-13.jpg' },
          {
            type: 'text',
            width: 50,
            text: `<div>
                      <div>Please enter the items without making any mistakes.</div>
                      <ul class="num-li">
                        <li>amount of deposit (minimum of 10 dollars)</li>
                      </ul>
                      <div>Click the [Deposit] button to start the deposit process for iWallet.</div>
                      </div>`
          }
        ]
      },
      {
        dt: 'Log in to iWallet and complete the deposit process.',
        dtg: true,
        dd: [
          {
            type: 'text',
            text: `<div>log in to iWallet to check your account ID or your secure ID.<br>
                      Register from this URL if you do not have an iWallet account yet.</div>`
          },
          {
            type: 'link',
            text: 'https://iwl.hk/',
            link: 'https://iwl.hk/'
          }
        ]
      },
      {
        dt: 'ecoPayz',
        dd: [
          { type: 'img', text: 'depositPC-en-US-14.jpg' },
          {
            type: 'text',
            text: 'ecoPayz is currently accepted by many online casinos as payment method, so it is highly recommended.'
          }
        ]
      },
      {
        dt: 'Once click ecoPayz, the following screen will be displayed.',
        dtg: true,
        dd: [
          { type: 'img', width: 50, text: 'depositPC-en-US-15.jpg' },
          {
            type: 'text',
            width: 50,
            text: `<div>
                        <div>Please enter the items in order from the top without making any mistakes.</div>
                        <ul class="num-li">
                          <li>Please enter the deposit amount (minimum of 10 dollars)</li>
                          <li>After entering the deposit amount, click the [Continue] button.</li>
                          <li>You will be directed to your EcoPayz account, so please log in and check the details.</li>
                        </ul>
                        <div>Your deposit will now be reflected in your Queen Casino account.<br>
                        register from this URL if you do not have an ecoPayz account yet.</div>
                        </div>
                        <br/>
                      <a href="https://www.ecopayz.com/ja-jp/ecopayz"  class="a-link card-c" target="_blank">https://www.ecopayz.com/ja-jp/ecopayz</a>`
          }
        ]
      }
    ]
  },
  depositH5: {
    tu: {
      tu1: 'click the deposit button',
      tu2: 'select deposit type',
      tu3: 'enter required information and complete your deposit'
    },
    content: [
      {
        dt: 'How to deposit with Smartphone',
        dd: {
          title: {
            type: 'text',
            text: 'after logging in to QUEEN CASINO, deposit will be completed in the following 3 steps'
          },
          content: []
        },
        tu: true
      },
      {
        dt: 'click the deposit button and you will be redirected to the deposit page',
        dtg: true,
        dd: {
          title: null,
          content: [
            { type: 'img', text: 'depositH5-en-US-1.jpg' },
            {
              type: 'text',
              text: [
                `<ul class="num-li">
                        <li>After logging in, tap the menu button displayed on the left side of the upper menu bar to display the menu items.</li>
                        <li>Select My Account from the menu items and tap [Deposit].</li>
                        <li>you will be redirected to the deposit page so please check and select your preferred deposit method</li>
                        <li>You can also view the deposit page by tapping [deposit] displayed at the bottom of the game list page.</li>
                      </ul>
                       `
              ]
            }
          ]
        }
      },
      {
        dt: 'Bank Transfer',
        dd: {
          title: {
            type: 'text',
            text: 'Queen Casino accepts bank transfers, making instant deposits very easy.'
          },
          content: [
            { type: 'img', text: 'depositH5-en-US-2.jpg' },
            {
              type: 'text',
              text: 'Click Bank Transfer Button'
            }
          ]
        }
      },
      {
        dt: 'the following screen will be displayed once you check bank transfer.',
        dtg: true,
        dd: {
          content: [
            { type: 'img', text: 'depositH5-en-US-3.jpg' },
            {
              type: 'text',
              text: `<ul class="num-li">
                          <li>amount to deposit (minimum of 20 dollars)</li>
                        </ul>
                        <div>Please fill in all the above items and click the [Deposit] button.</div>
                        `
            }
          ]
        }
      },
      {
        dt: 'Move to the Hi-BANQ page',
        dtg: true,
        dd: {
          content: [
            { type: 'img', text: 'depositH5-en-US-4.jpg' },
            {
              type: 'text',
              text: 'Please check the details and click the [Deposit] button.'
            }
          ]
        }
      },
      {
        dt: 'You will receive an email when the deposit application is completed.',
        dtg: true,
        dd: {
          content: [
            { type: 'img', text: 'depositH5-en-US-5.jpg' },
            {
              type: 'text',
              text: 'Within a few minutes, you will receive an email from Hi-Banq regarding payment guidelines.'
            }
          ]
        }
      },
      {
        dt: 'Credit Card',
        dd: {
          content: [
            {
              type: 'text',
              text: 'We also accept deposits through credit cards.'
            }
          ]
        }
      },
      {
        dt: 'Payment with WlandPay',
        dtg: true,
        dd: {
          content: [
            { type: 'img', text: 'depositH5-en-US-6.jpg' },
            {
              type: 'text',
              text: 'Click WlandPay Button'
            },
            { type: 'img', text: 'depositH5-en-US-7.jpg' },
            {
              type: 'text',
              text: `<div>
                        <div>Please enter the items in order from the top without making any mistakes.</div>
                        <ul class="num-li">
                          <li>amount of deposit (minimum of 10 dollars)</li>
                          <li>credit card number (16 digits)</li>
                          <li>expiration date (month・year)</li>
                          <li>CVV number (3 digits) * Usually located on the right side of the signature line on the back of the card</li>
                          <li>Telephone number (No hyphen</li>
                        </ul>
                        <div>you will find information on each of the deposit methods below.</div>
                        </div>`
            }
          ]
        }
      },
      {
        dt: 'CoinPayment (Crypto Currency)',
        dd: {
          title: { type: 'text', text: 'You can make deposits in Bitcoin and other Crypto currencies.' },
          content: [
            { type: 'img', text: 'depositH5-en-US-8.jpg' },
            {
              type: 'text',
              text: 'Please check the details and click the [Deposit] button.'
            }
          ]
        }
      },
      {
        dt: 'The following screen will be displayed once you check CoinPayment.',
        dtg: true,
        dd: {
          content: [
            { type: 'img', text: 'depositH5-en-US-9.png' },
            {
              type: 'text',
              text: `<div>
                        <div>Please enter the items without making any mistakes.</div>
                        <ul class="num-li">
                          <li>Select Crypto Currency</li>
                          <li>Amount of deposit</li>
                        </ul>
                        <div>Click "Generate Address" to start generating the address for deposit.</div>
                        </div>
                       `
            }
          ]
        }
      },
      {
        dt: 'Copy the generated address and complete the transfer from your wallet app.',
        dtg: true,
        dd: {
          title: { type: 'text', text: 'Deposits can be made in Bitcoin and many other cryptocurrencies.' },
          content: [
            { type: 'img', text: 'depositH5-en-US-10.jpg' },
            {
              type: 'text',
              text: `<div>When the address is generated, click on it to copy the address.</div>
                         <br />
                       <div>
                         You can make a transfer to the address from your crypto currency application.
                         <br />
                         Your payment will be reflected as soon as we confirm the receipt of your transfer.
                       </div>`
            }
          ]
        }
      },
      {
        dt: 'iWallet',
        dd: {
          content: [
            { type: 'img', text: 'depositH5-en-US-11.jpg' },
            {
              type: 'text',
              text: 'Click iWallet Button'
            }
          ]
        }
      },
      {
        dt: 'the following screen will be displayed once you check iwallet.',
        dtg: true,
        dd: {
          content: [
            { type: 'img', text: 'depositH5-en-US-12.jpg' },
            {
              type: 'text',
              text: `<div>
                        <div>Please fill in the items and tap [Deposit] to start the deposit process.</div>
                        <br>
                        <ul class="num-li">
                          <li>amount to deposit (minimum of 10 dollars)</li>
                        </ul>
                        <div>Please fill in the items and tap [Deposit] to start the deposit process.</div>
                        <br>
                        <div>log in to iWallet to check your account ID or your secure ID<br>
                        register from this URL if you do not have an iWallet account yet.</div>
                        <br>
                        <a
                           href="https://iwl.hk/"
                           target="_blank"
                           class="a-link card-c"
                         >
                           https://iwl.hk/
                         </a></div>
                        `
            }
          ]
        }
      },
      {
        dt: 'ecoPayz',
        dd: {
          content: [
            { type: 'img', text: 'depositH5-en-US-13.jpg' },
            {
              type: 'text',
              text: 'Click ecoPayz Button'
            }
          ]
        }
      },
      {
        dt: 'the following screen will be displayed once you check ecoPayz.',
        dtg: true,
        dd: {
          content: [
            { type: 'img', text: 'depositH5-en-US-14.jpg' },
            {
              type: 'text',
              text: `<div>
                        <div>Please enter the items in order from the top without making any mistakes.</div>
                        <ul class="num-li">
                          <li>amount to deposit (minimum of 10 dollars)</li>
                          <li>After entering the deposit amount, click the [Continue] button.</li>
                          <li>You will be directed to your EcoPayz account, so please log in and check the details.</li>
                        </ul>
                        <div>Your deposit will now be reflected in your Queen Casino account.<br>
                        register from this URL if you do not have an ecoPayz account yet.</div>
                        <br>
                        <a href="https://www.ecopayz.com/" class="a-link card-c" target="_blank">https://www.ecopayz.com/</a></div>`
            }
          ]
        }
      }
    ]
  },
  withdrawHelp: {
    content: [
      {
        dt: 'How to withdraw',
        dd: {
          content: []
        }
      },
      {
        dt: 'After logging in, you will be directed to the withdrawal page.',
        dtg: true,
        dd: {
          title: {
            type: 'text',
            text: 'We recommend creating an ecoPayz account as it is currently compatible with many online casinos.'
          },
          content: [
            { type: 'img', text: 'withdraw-1.jpg' },
            {
              type: 'text',
              text: ` <p>
                         After logging in, from the My Account menu displayed at the top of the screen page
                         <span class="marker-yellow">Click the [Withdrawal] button</span>
                         please.
                       </p>
                       <p>You will be redirected to the withdrawal page. </p>`
            }
          ]
        }
      },
      {
        dt: 'Select a withdrawal method.',
        dtg: true,
        dd: {
          title: {
            type: 'text',
            text: 'You can select your preferred withdrawal method from the displayed options.'
          },
          content: [
            { type: 'img', text: 'withdraw-2.jpg' },
            {
              type: 'text',
              text: ` <p>
                     Currently at Queen Casino,
                     <strong class="marker-yellow">This withdrawal method is available. </strong>
                   </p>`
            }
          ]
        }
      },
      {
        dt: 'For withdrawals by bank transfer',
        dd: {
          content: [
            { type: 'img', text: 'withdraw-3.jpg' },
            {
              type: 'text',
              text: `<p>
                           Please fill in the following necessary information,
                           <strong class="marker-yellow">Finally click withdraw to complete the withdrawal application</strong>
                           becomes.
                         </p>
                         <ul class="num-li">
                           <li>
                             <p>Amount</p>
                           </li>
                           <li>
                             <p>Bank Name</p>
                           </li>
                           <li>
                             <p>Account Name</p>
                           </li>
                           <li>
                             <p>Account number</p>
                           </li>
                           <li>
                             <p>Branches</p>
                           </li>
                           <li>
                             <p>Prefecture</p>
                           </li>
                           <li>
                             <p>City</p>
                           </li>
                         </ul>
                         <p>Once you have completed the above steps, click the [Withdrawal] button. </p>
                         <div class="pick-box-2">
                           <p>
                             In the [Address] field, enter the address without the prefecture, and in the [Prefecture] field, enter the prefecture.
                             <br />
                             Only USD (US dollar) can be selected as the currency used.
                           </p>
                         </div>`
            }
          ]
        }
      },
      {
        dt: 'This completes the withdrawal application procedure.',
        dtg: true,
        dd: {
          content: [
            { type: 'img', text: 'withdraw-4.jpg' },
            {
              type: 'text',
              text: `<p>
                         <strong class="marker-yellow">When this screen is displayed, the withdrawal application procedure is completed</strong>
                         is.
                       </p>
                       <p>Please wait while the withdrawal process is approved. </p>`
            }
          ]
        }
      }
    ]
  },
  'registration-guide': {
    content: [
      {
        dt: 'User Registration (PC)',
        dd: {
          width: 100,
          content: [
            {
              type: 'text',
              text: `<p>Registration in Queen Casino is very easy. You can easily register using either a desktop or a smartphone as well. In this page, we will guide you on how to register using a desktop in the easiest way possible.</p>`
            }
          ]
        }
      },
      {
        dt: 'You will be redirected to the user registration page',
        dtg: true,
        dd: {
          width: 100,
          content: [
            { type: 'img', text: 'login-en-US-1.jpg' },
            {
              type: 'text',
              text: 'Click the [User Registration] button from the header bar at the top of the page.'
            }
          ]
        }
      },
      {
        dt: 'Please enter your email address, password, and the required information.',
        dtg: true,
        dd: {
          title: {
            type: 'text',
            text: `<p>Fill in all the blanks, read the terms of use, and check I agree to the Terms of Service & Confirm I am at least 18 years old.<br>
                      <strong>Click "Sign Up" when all the formats are completed.</strong></p>`
          },
          width: 100,
          content: [
            { type: 'img', text: 'login-en-US-2.png' },
            {
              type: 'text',
              text: `<p>Input items are in order from the top</p>
                         <ul class="num-li">
                          <li>Email</li>
                          <li>6-digit Code</li>
                          <li>Password</li>
                          <li>Referral Code</li>
                          <li>Check I agree to the Terms of Service & Confirm I am at least 18 years old.</li>
                        </ul>
                         <p>Click "Sign Up" button when all the entries are completed</p>
                       `
            }
          ]
        }
      },
      {
        dt: 'E-mail address verification.',
        dtg: true,
        dd: {
          title: {
            type: 'text',
            text: 'An email regarding email address verification will be sent to your registered email address.'
          },
          width: 100,
          content: [
            { type: 'img', text: 'login-en-US-3.jpg' },
            {
              type: 'text',
              text: `<p><strong>Click "Email Address Verification" button</strong> in the body of the email.</p>`
            }
          ]
        }
      },
      {
        dt: 'You have now completed your registration.',
        dtg: true,
        dd: {
          width: 50,
          content: [
            { type: 'img', text: 'login-en-US-4.jpg' },
            {
              type: 'text',
              text: `<p>User registration is completed when this registration screen is displayed.<br>
                    A welcome email has also been sent to you. Please kindly check your email.</p>`
            }
          ]
        }
      }
    ]
  },
  'registration-mobile-guide': {
    content: [
      {
        dt: 'User Registration (Mobile)',
        dd: {
          width: 100,
          content: [
            {
              type: 'text',
              text: `<p>Registration in Queen Casino is very easy. You can easily register using either a desktop or a smartphone as well. In this page, we will guide you on how to register using a desktop in the easiest way possible.</p>`
            }
          ]
        }
      },
      {
        dt: 'You will be redirected to the user registration page',
        dtg: true,
        dd: {
          width: 50,
          content: [
            { type: 'img', text: 'login-h5-en-US-1.jpg' },
            {
              type: 'text',
              text: 'Click User Registration Button at the upper on screen. You can move to the page fill in format page for new user registration.'
            }
          ]
        }
      },
      {
        dt: 'Please enter your email address, password, and the required information.',
        dtg: true,
        dd: {
          title: {
            type: 'text',
            text: 'Fill in all the blanks, read the terms of use, and check I agree to the Terms of Service & Confirm I am at least 18 years old. Click "Sign Up" when all the formats are completed.'
          },
          width: 50,
          content: [
            { type: 'img', text: 'login-h5-en-US-2.png' },
            {
              type: 'text',
              text: `<p>Input items are in order from the top</p>
                         <ul class="num-li">
                          <li>Email</li>
                          <li>6-digit Code</li>
                          <li>Password</li>
                          <li>Referral Code</li>
                          <li>Check I agree to the Terms of Service & Confirm I am at least 18 years old.</li>
                        </ul>
                           <p>Click "Sign Up" button when all the entries are completed</p>
                       `
            }
          ]
        }
      },
      {
        dt: 'E-mail address verification.',
        dtg: true,
        dd: {
          title: {
            type: 'text',
            text: 'An email regarding email address verification will be sent to your registered email address.'
          },
          width: 100,
          content: [
            { type: 'img', text: 'login-h5-en-US-3.jpg' },
            {
              type: 'text',
              text: `<p><strong>Click "Email Address Verification" button</strong> in the body of the email.</p>`
            }
          ]
        }
      },
      {
        dt: 'You have now completed your registration.',
        dtg: true,
        dd: {
          width: 50,
          content: [
            { type: 'img', text: 'login-h5-en-US-4.jpg' },
            {
              type: 'text',
              text: `<p>User registration is completed when this registration screen is displayed.<br>
                      A welcome email has also been sent to you. Please kindly check your email.</p>`
            }
          ]
        }
      }
    ]
  },
  'guide-bonus': {
    content: [
      {
        dt: 'About bonuses',
        dd: {
          width: 100,
          content: [
            {
              type: 'text',
              text: `<p>Queen Casino offers <strong class="marker-yellow">various types of deposit bonuses and special no deposit bonuses</strong> on every deposit. </p>
                       <p>Bonuses are given to help you play more and win bigger. <br>
                       <strong class="marker-yellow">You need to meet certain conditions to withdraw the bonus</strong>. </p>
                       <p>You can freely choose whether or not to receive the bonus, so please check the conditions and decide whether to receive the bonus depending on the game you play and your strategy. </p>
                       `
            }
          ]
        }
      },
      {
        dt: 'About Queen Casino bonuses',
        dd: {
          width: 100,
          content: [
            {
              type: 'text',
              text: `<p><strong class="marker-yellow">Queen Casino bonuses are reflected together in the bonus count when you make a deposit and receive a bonus. </strong>If you win or lose and bet X X times the sum of your deposit and bonus amount, the funds will be transferred to your cash account and you will be able to withdraw them. </p>`
            },
            {
              type: 'text',
              text: `
                         <table class="bordered">
                           <thead>
                             <tr>
                               <th class="center" width="20%">&nbsp;</th>
                               <th class="center">separate type</th>
                               <th class="center new-td">All-in-one</th>
                             </tr>
                           </thead>
                           <tbody>
                             <tr>
                               <th class="center" scope="row">Account after deposit</th>
                               <td>Deposit amount to cash account<br>
                               Bonus to bonus account</td>
                               <td class="new-td"><strong class="marker-yellow">Total deposit + bonus amount</strong> will be credited to your bonus account</td>
                             </tr>
                             <tr>
                               <th class="center" scope="row">Deposit $100, get $150 bonus</th>
                               <td>$100 in cash account, $150 in bonus account</td>
                               <td class="new-td"><strong class="marker-yellow">$250</strong> credited to bonus account</td>
                             </tr>
                             <tr>
                               <th class="center" scope="row">What is consumed? </th>
                               <td>Consumed from cash, automatically consumed from bonus account when cash balance reaches 0</td>
                               <td class="new-td"><strong class="marker-yellow">If you have a cash account and a bonus that is not tied to a deposit, it will be consumed from your bonus account balance</strong>. </td>
                             </tr>
                             <tr>
                               <th class="center" scope="row">What are the withdrawal conditions? </th>
                               <td>○○ times the bonus</td>
                               <td class="new-td"><strong class="marker-yellow">(deposit + bonus) times XX</strong></td>
                             </tr>
                             <tr>
                               <th class="center" scope="row">When does the withdrawal condition count? </th>
                               <td>No cash balance, only bonus wagered amount</td>
                               <td class="new-td">Get your bonus and <strong class="marker-yellow">bet started</strong></td>
                             </tr>
                             <tr>
                               <th class="center" scope="row">When can I withdraw? </th>
                               <td>If you bet the same amount as your deposit, the bonus will be removed, but you can withdraw at any time</td>
                               <td class="new-td last"><strong class="marker-yellow">You cannot withdraw $1 until you meet the bonus withdrawal requirements</strong></td>
                             </tr>
                           </tbody>
                         </table>
                       `
            }
          ]
        }
      },
      {
        dt: 'How to claim and get bonuses',
        dd: {
          width: 100,
          content: [
            {
              type: 'text',
              text: 'Queen Casino offers a lot of great bonuses. This page will show you how to claim and get bonuses in an easy-to-understand manner.'
            }
          ]
        }
      },
      {
        dt: 'Go to the bonus page and claim the bonus you can get.',
        dtg: true,
        dd: {
          width: 100,
          content: [
            {
              type: 'text',
              text: `
                   <p>When there is a <strong class="marker-yellow">bonus available, you will see a notification in the header menu</strong>. Open the My Account menu and go to the Bonus page. </p>
                 `
            },
            { type: 'img', text: 'bonus-ja-JP-1.jpg' },
            {
              type: 'text',
              text: `
                   <p>You will be redirected to the bonus page. Find the desired bonus and <strong class="marker-yellow">click the 'Get it' button</strong>. </p>
                 `
            },
            { type: 'img', text: 'bonus-ja-JP-2.jpg' },
            {
              type: 'text',
              text: `
                  <p>A confirmation screen will pop up. If everything is correct, click <strong class="marker-yellow">"YES" to complete the bonus application</strong>. </p>
                 `
            },
            { type: 'img', text: 'bonus-ja-JP-3.jpg' }
          ]
        }
      },
      {
        dt: 'A message will be displayed when the bonus application is complete.',
        dtg: true,
        dd: {
          width: 100,
          content: [
            { type: 'img', text: 'bonus-ja-JP-4.jpg' },
            {
              type: 'text',
              text: 'Once the bonus has been delivered, it will be reflected in your bonus balance in My Menu. Please use the bonus and enjoy Queen Casino.'
            }
          ]
        }
      }
    ]
  },
  vipRule: {
    ruleTitle: ` <span class="text-center">“One-rank higher” status for you.<br>
              Best in class VIP membership privilege</span>`,
    ruleTitleD: `We provide the special experience to you who love QUEEN CASINO. <br />
                Once a VIP, you do not just get the title, you get the treatment from QUEEN CASINO as below.`,
    vipLevelD: 'Entry to VIP programme is by invitation only.',
    ruleLevel: [
      {
        level: '',
        rule: 'Diamond Deposit: 100,000 USD',
        imgClass: 'diamond',
        tips: 'Invite to VIP land casino and more!'
      },
      {
        level: 'Platinum',
        rule: 'Platinum Deposit: 50,000 USD',
        imgClass: 'platinum',
        tips: 'Birthday Bonus Campaign + Luxury Gift'
      },
      {
        level: 'Gold',
        rule: 'Gold Deposit: 10,000 USD',
        imgClass: 'gold',
        tips: 'Birthday Bonus Campaign + Gift'
      },
      {
        level: 'Silver',
        rule: 'Silver Deposit: 5,000 USD',
        imgClass: 'silver',
        tips: 'Birthday Bonus Campaign'
      },
      {
        level: 'Bronze',
        rule: 'Bronze Deposit: 2,000 USD',
        imgClass: 'bronze',
        tips: ''
      }
    ],
    hTitle1: 'Requirement for VIP membership',
    hTitle2: 'Queen Casino VIP Plan',
    hTitle3: 'Queen Casino VIP Membership Program Terms and Conditions',
    ruleTable: `
           <table class="table">
    <thead>
      <tr>
        <th class="blank">&nbsp;</th>
        <th class="bronze-th">Bronze King</th>
        <th class="silver-th">Silver King</th>
        <th class="gold-th">Gold King</th>
        <th class="platinum-th">Platinum King</th>
        <th class="diamond-th">Diamond King</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Conditions of the VIP upgrade<br>
        (By Invitation)</td>
        <td>Minimum turnover 40,000 USD(Monthly)<br>
        Deposit : 2,000 USD</td>
        <td>Minimum turnover 100,000 USD(Monthly)<br>
        Deposit : 5,000 USD</td>
        <td>Minimum turnover 500,000 USD(Monthly)<br>
        Deposit : 10,000 USD</td>
        <td>Minimum turnover 1,000,000 USD(Monthly)<br>
        Deposit : 50,000 USD</td>
        <td>Minimum turnover 2,000,000 USD(Monthly)<br>
        Deposit : 100,000 USD</td>
      </tr>
      <tr>
        <td>VIP welcome bonus</td>
        <td>30 USD</td>
        <td>50 USD</td>
        <td>100 USD</td>
        <td>120 USD</td>
        <td>150 USD</td>
      </tr>
      <tr>
        <td>Credit Advantage</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>Advance credit up to 3,000 USD</td>
      </tr>
      <tr>
        <td>VIP Manager</td>
        <td>Phone Number</td>
        <td>VIP Manager Service + Phone Number</td>
        <td>VIP Manager Service + Phone Number</td>
        <td>VIP Manager Service + Phone Number</td>
        <td>VIP Manager Service + Phone Number</td>
      </tr>
      <tr>
        <td>Deposit and withdraw will be a priority</td>
        <td>-</td>
        <td>-</td>
        <td>Yes</td>
        <td>Yes</td>
        <td>First Priority</td>
      </tr>
      <tr>
        <td>Deposit and withdraw limit</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>Yes</td>
        <td>Flexible</td>
      </tr>
      <tr>
        <td>Yearly anniversary VIP membership</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>Yes</td>
        <td>Yes</td>
      </tr>
      <tr>
        <td>Birthday Treats</td>
        <td>30 USD Bonus</td>
        <td>50 USD Bonus</td>
        <td>100 USD Bonus</td>
        <td>120 USD Bonus</td>
        <td>150 USD Bonus</td>
      </tr>
      <tr>
        <td>Special Event</td>
        <td>-</td>
        <td>-</td>
        <td>Don’t miss it!</td>
        <td>Don’t miss it!</td>
        <td>Invite to VIP land casino and more!</td>
      </tr>
    </tbody>
  </table>`,
    ruleList: [
      'Queen Casino VIP Membership Program is a complete invitation system. Only customers who have passed strict screening by the department in charge will be invited.',
      'VIP rank evaluation period is from 0:00 (Japan time) on the first day of every month to 23:59 (Japan time) at the end of each month.',
      'A new VIP rank will be determined on the 5th of every month. Eligible members will be notified of the rank by email.',
      'VIP welcome bonus and VIP rank bonus withdrawal conditions are 40 times the amount presented, and the maximum withdrawal amount is 10 times the amount presented. Also, until the withdrawal conditions are cleared, the maximum amount of bet once Please note that the bet amount is 10 USD.',
      'In order to continue the VIP rank, it is necessary to meet the required deposit amount and total bet amount for each rank.If the conditions are not met, the rank will unfortunately be demoted.',
      'Queen Casino reserves the right to revoke your VIP Membership if you are found to be violating the Queen Casino Terms of Use.',
      'Queen Casino reserves the right to change, modify, add or revoke the VIP Membership Program without prior notice.',
      'Queen Casino reserves the right to judge or reject any decision to grant VIP Membership.'
    ]
  },
  sitemap: {
    title: 'Sitemap'
  },
  contact: {
    title: 'Contact us',
    supportEmail: 'Support email',
    emailAddress: 'EMAIL ADDRESS',
    emailAddressPlaceholder: 'Email',
    emailAddressRoleMsg: 'Invalid email syntax',
    fullName: 'FULL NAME',
    fullNamePlaceholder: 'Full name',
    mobileNumber: 'MOBILE NUMBER',
    sendTitle: 'TITLE',
    sendTitlePlaceholder: 'Title',
    sendMessage: 'MESSAGE',
    sendMessagePlaceholder: 'Textarea placeholder',
    fileLabel: 'Attach File',
    fileSelect: 'Select files',
    fileRequire_1: 'The maximum amount of files is 5',
    fileRequire_2: 'The maximum size of each file is 5MB',
    fileRequire_3:
      'The supported file types are: <br/>responsible/gif, responsible/jpeg, responsible/png,<br/> application/pdf',
    fileType: `image/gif, image/jpeg, image/png, application/pdf`,
    noEmpty: 'Required field',
    send: 'SEND'
  }
} as const
