export const stat = {
  about: {
    title: 'QUEEN CASINO | オンラインカジノ・クイーンカジノ について',
    youtube: 'https://www.youtube.com/embed/b1jSkTwqwJY',
    subTitle: [
      'オープン以来、アジア市場知名度ナンバーワンに君臨し続けるクイーンカジノが',
      'さらに熱く！魅力的に生まれ変わりました！'
    ],
    content: [
      {
        dt: 'クイーンカジノについて',
        dd: `
            <p>クイーンカジノは楽しい要素がすべてつまったエキサイティングな"本格オンラインカジノサイト"です。
            私たちは最前線に立って、世界で一番スタイリッシュで楽しめる"オンラインカジノサイト"を目指しています。クイーンカジノはTOBIQUE(<a  target="_blank"  href="https://thetgc.ca/">thetgc.ca</a>)によって管理されております。クイーンカジノは認められた市場内で慎重に運営をしています。</p>
            <p>クイーンカジノはこの業界での長年の経験を持っており、プロフェッショナルなサポートスタッフ陣によりサービスを提供します。クイーンカジノでは、最も人気のあるライブバカラ、スロット、ブラックジャック、カリビアンポーカー、ルーレット、RNGポーカーの人気カジノゲームを数多く提供しております。お得なボーナスプログラムや便利な入出金方法と共に、クイーンカジノのゲームとサービスをお楽しみください。</p>
          `
      },
      {
        dt: '最高のゲーム体験を提供します',
        dd: '<p>クイーンカジノではゲームをダウンロードする必要が無く、最先端のデザインでブラウザ上から直接遊ぶことができます。 さらにモバイルカジノも提供しており、AndroidやiPhone、iPadから、いつでもどこでも遊ぶことができます。</p>'
      },
      {
        dt: 'スムーズで迅速な入出金方法',
        dd: '<p>クイーンカジノでは様々な入出金方法をご用意しております。</p>'
      },
      {
        dt: '守秘義務とセキュリティー',
        dd: '<p>クイーンカジノでお客様が作成できるアカウントは、1アカウントのみとなります。システムの完全性、公平性の維持及び、お客様の安全性を確保するため、定期的にシステムチェックを行います。 クイーンカジノでは高度なセキュリティー対策を24時間体制で監視しており、お客様が最も安全なオンラインゲーミングサイトでプレイできる事を保障いたします。</p>'
      },
      {
        dt: 'トップクラスのカスタマーサポート',
        dd: `
            <p>クイーンカジノのカスタマーサポート部署は24時間年中無休で対応しております。お客様のご質問や不具合等に可能な限り迅速かつ効率的、丁寧にお答えできるスタッフを常に配置しております。</p>
            <p>ユーザーのニーズを満たす最高のカジノを構築するためには、プレイヤーの皆様のご意見を反映することが重要だと考えておりますので、ご意見･ご感想がございましたら、お気軽に<a href="mailto:support@queen-casino.com">support@queen-casino.com</a>までお寄せください。</p>
          `
      }
    ],
    footer: `
        <p>安全で楽しい、素敵な環境で最高のゲームを体験してください。 お客様のご意見、ご感想は当社にとって大変貴重でございます。</p>
        <p>まだQUEEN CASINO | オンラインカジノ・クイーンカジノ に登録されていないお客様は、ぜひこの機会に<a class="go-register">ユーザー登録</a>して、最新のオンラインカジノゲームを誰よりも早くお楽しみください！</p>
      `
  },
  security: {
    title: 'セキュリティ',
    subTitle:
      'ログイン後、QUEEN CASINO から、また当サイトへ送信されるすべての情報は、256 ビットの Secure Socket Layer (SSL) テクノロジーを使って暗号化されます。お客様のクレジットカードの詳細情報は暗号化され、QUEEN CASINOへインターネット経由で送信されるのは1回のみです。送信された情報は、QUEEN CASINOの安全なシステム内に暗号化した状態で保管されます。',
    content: [
      {
        dt: '製品',
        dd: `<p>公平なゲームを保証するため、当社の製品は、名前の通り、無作為の番号を生成する、Random Number Generator (RNG) を使用しています。これにより、当社またはお客様による操作がまったくないことが保証されています。<br>
          当社では、このようにしてお客様に公平なゲームを提供し、厳格な国際規格に準拠しております。</p>`
      }
    ]
  },
  sportRules: {
    title: 'スポーツベッテイング規則',
    subTitle:
      'これらの規則は、「QUEEN CASINO」が承認するベットの利用規約です。当サイトが承認した全てのベット（お客様が誤ってベットした場合や当サイト上のシステムエラーが発生した場合も含む）は、以下の規則に基づいて決済され、これらの規則や各ベットの条件について、お客様が当サイトの利用規約に同意の基アカウントを作成しているとみなされます。',
    content: [
      {
        dd: `
            <h3 style="color: #fc005b">概要（Premier version）</h3>
            <h3 class="fg">•\t一般規定</h3>
              <ul>
               <li>⚬\t一般的規約は、スポーツ別のルールに記載がない場合、すべてのスポーツに適用されます: </li>
               <li>⚬\t市場の結果が公式に確認されない場合、確認が得られるまで決済を延期する権利を留保します.</li>
               <li>⚬\tもし試合が中断された場合でも、その後再開され（中断されたポイントから再開）、元の予定された開始時間から48時間以内に完了する場合、すべての賭けはその試合について有効とみなされます.</li>
               <li>⚬\tそれ以外の場合、試合が再開されても元の予定された開始時間から48時間以内に終了しない場合、これは放棄として扱われ、元の試合に関する賭けは無効となります。ただし、元の試合の中断前に結果が既に確定していた賭けを除きます.</li>
               <li>⚬\t何らかの理由で試合が中断され、完全に再試合される場合、これは放棄として扱われ、元の試合に関する賭けは無効となります。ただし、元の試合の中断前に結果が既に確定していた賭けを除きます.</li>
               <li>⚬\t3x3、ストリートボール、Big3の試合バージョンに関して、決済は公式の競技規則に基づいて行われます。試合が開始されたが、完了しない場合、特定の市場の結果が既に確定している場合を除き、賭けは無効となります.</li>
               <li>⚬\t2-Wayの市場では、以下で明示的に指定されていない限り、プッシュルールが適用されます。単一の賭けでは賭金が返還され、複数/パーレイの場合は選択が出走取消として扱われます.</li>
               <li>⚬\t市場で既に結果が知られている時点で提供された場合、私たちはいかなる賭けも無効にする権利を留保します.</li>
               <li>⚬\t名前に明らかな間違いがない場合、賭けは有効となります.</li>
               <li>⚬\t明らかに誤って表示された、または計算された価格の場合、私たちは賭けを無効にする権利を留保します。これには、市場平均に対するペイアウトの変動が100％を超える場合も含まれます.</li>
               <li>⚬\t2試合制の対戦として行われるすべての試合について。チ勝者を決定するために2回対戦する必要があるノックアウトトーナメントによく見られます。この場合、2試合目の規定時間終了時に集計スコアが同点の場合、2試合目の試合の結果を決定するために延長戦が含まれます.</li>
               <li>⚬\t試合の放送が中断されたり削除されたりし、試合が通常通りに終了した場合、すべての市場は最終結果に従って決済されます。市場の結果が公式に確認できない場合、無効とする権利を留保します.</li>
               <li>⚬\t市場の誤った決済の場合、いつでもそれを訂正する権利を留保します.</li>
               <li>⚬\t試合が一般的に受け入れられているフォーマットに準拠していない場合（例：異常な期間の長さ、集計手順、試合のフォーマットなど）、私たちはいかなる市場も無効にする権利を留保します.</li>
               <li>⚬\t明示されていない限り、すべての市場は通常時間のみを対象とします.</li>
               <li>⚬\t試合が完了しないか、または行われない場合（例：失格、中断、撤退、抽選の変更など）、すべての未決定の市場は無効と見なされます.</li>
               <li>⚬\tインプレイのアジアンハンディキャップに賭けがされた場合、賭けが行われた時点での試合のスコアラインは0対0として扱われます。これはサッカーとeサッカーにのみ適用されます.</li>
               <li>⚬\tビデオ・アシスタント・レフェリー（VAR）- もし私たちが賭けを決済し、後にVARの判定によって、その決済が間違っていたことが明らかになった場合、私たちはその決済を取り消す権利を留保します（VARの判定が試合の結論または賭けに関連する他の時間枠の前に発生した場合に限ります）。VARレビューが発生した出来事と関連するVARの判定の間に賭けが行われた場合、その賭けは無効と見なされます（VARレビュー（およびその後の判定）が最終的にフィールド上の審判またはVARレビュー（およびその後の判定）によって行われた決定を変更しなかった場合、またはVARレビュー（およびその後の判定）が賭けに対する実質的な影響を持たなかった場合を除きます）。VARレビューに影響を受けなかったすべての賭けは有効となります.</li>
               <li>⚬\t試合のいかなる時点でも選手がアクティブな選手としてフィールド/コート/リンクなどに入場した場合、その選手がプレーした時間の長さに関わらず、すべての選手選択はアクティブとして見なされます.</li>
            </ul>
            <h3>•\tサッカー</h3>
            <ul>
              <li>⚬\tすべての市場（ハーフタイム、前半の市場、延長戦、PK戦を除く）は、明示されていない限り、通常の試合時間のみを対象とします.</li>
              <li>⚬\t試合が中断され、初回キックオフ後48時間以内に再開された場合、すべての未決定の賭けは最終結果で決済されます。それ以外の場合、すべての未決定の賭けは無効と見なされます.</li>
              <li>⚬\t通常の90分間：市場は、明示されていない限り、予定された90分間のプレーの結果に基づいています。これには、追加された負傷時間や中断時間が含まれますが、延長戦、PK戦のために割り当てられた時間、またはゴールデンゴールは含まれません.</li>
            </ul>
            <h3>•\t決済およびキャンセル規則:</h3>
            <ul>
              <li>⚬\tゴール、レッドカードまたはイエローレッドカード、およびペナルティがすでに発生した場合に市場がまだオープンの状態である場合、私たちは賭けを無効にする権利を留保します.</li>
              <li>⚬\t試合が最終ホイッスル前に放棄された場合、すべての未決定の市場は無効とされます.</li>
              <li>⚬\t試合が中断または延期され、初回キックオフ日から48時間以内に再開されない場合、賭けは無効となります.</li>
              <li>⚬\tチーム名やカテゴリーが誤って表示されている場合、私たちは賭けを無効にする権利を留保します。名前に明らかな間違いがない場合は、賭けが有効となります.</li>
            </ul>
            <h3>•\t試合放棄 - ライブ:</h3>
            <ul>
              <li>⚬\t公式試合が90分間のプレーが完了する前に放棄された場合、その試合は無効となります。ただし、放棄時点で結果が確定していたチケットに関しては除きます.</li>
              <li>⚬\t親善試合が80分間のプレーが完了する前に放棄された場合、その試合は無効となります。ただし、放棄時点で結果が確定していたチケットに関しては除きます.</li>
            </ul
            <h3>•\tブッキング市場:</h3>
            <ul>
              <li>⚬\tイエローカードは1枚、レッドカードは2枚としてカウントされます。2枚目のイエローカードは決済目的で無視されます（例：プレーヤーごとの最大カード数は3枚です）.</li>
              <li>⚬\t決済は、通常の90分間のプレー中に示されたすべてのカードの利用可能な証拠に基づいて行われます.</li>
              <li>⚬\t試合後に示されたカードは考慮されません.</li>
              <li>⚬\t非プレーヤー（すでに交代された選手、マネージャー、ベンチ上の選手）のカードは考慮されません.</li>
              <li>⚬\tコーナーマーケット.</li>
              <li>⚬\tコーナーキックが与えられましたが、実際に蹴られなかった場合は考慮されません.</li>
            </ul>
            <h3>•\t次の得点者:</h3>
            <ul>
              <li>⚬\tオウンゴールは「次の得点者」の決済目的には考慮されず、無視されます.</li>
              <li>⚬\t試合に参加したすべての選手がランナーとして考慮されます。もしリストされていない選手が何らかの理由でゴールを決めた場合、リストされた選手に対するすべての賭けは有効です。もし選手がリストされているが試合に参加しない場合、その選手に関連するすべての賭けは無効とみなされます.</li>
              <li>⚬\t市場は公式データに基づいて決済されます.</li>
            </ul>
            <h3>•\tインターバル市場:</h3>
            <ul>
              <li>⚬\t市場はテレビで発表されたゴールの時間に基づいて決済されます。これが利用できない場合は、試合時計の時間が考慮されます.</li>
              <li>⚬\tゴール市場はボールがゴールラインを通過した時間に基づいて決済され、キックが行われた時間ではありません.</li>
              <li>⚬\tオフサイドは、審判が判定を下した時点を基準に決済されます。このルールは、ビデオアシスタントレフェリー（VAR）の状況に適用されます.</li>
              <li>⚬\tペナルティ市場は、審判が判定を下した時点を基準に決済されます。このルールは、ビデオアシスタントレフェリー（VAR）の状況に適用されます。与えられたが実行されなかったペナルティは考慮されません.</li>
              <li>⚬\t5分/15分のインターバル市場に関連する、インターバル時間41-45/31-45のイベント（ゴール、コーナー）は、追加の負傷時間や中断時間で考慮されます.</li>
              <li>⚬\tイベントは、0:00から09:59の間に発生する必要があります。これによって、最初の10分間に分類されます（例：時間帯11から20分は10:00から19:59まで）。指定された10分間が完了すると、賭けが有効となります（特定の市場の結果がすでに確定している場合を除く）.</li>
              <li>⚬\t「xx-xx分の間にゴールが入るか？」.</li>
              <li>⚬\t負傷時間中のゴールも賭けに含まれます。例えば、90+分の追加時間に入ったゴールは76〜90分の範囲に含まれます.</li>
            </ul>
            <h3>•\tアジアンハンディキャップ:</h3>
            <ul>
              <li>⚬\tアジアハンディキャップベッティングの基本コンセプト：アジアハンディキャップベッティングは、試合開始前に片方のチームに架空のアドバンテージまたはディスアドバンテージを与えることで、両チームの戦いを平準化することを目指しています。これは、試合の最終結果にハンディキャップを適用することによって行われます.</li>
              <li>⚬\tハンディキャップ値：アジアハンディキャップベットは、通常、四半期ゴール（0/+0.5、0/-0.5）、完全ゴール（+1、-1）、およびハーフゴールを含みます。たとえば、チームには-0.5、-1.0、-1.5などのハンディキャップがある場合、ベットが勝利するにはある程度の差で勝利する必要があります。逆に、チームには+0.5、+1.0、+1.5などのハンディキャップがある場合、ある程度の差で負けたり引き分けたりしても、ベットが勝利します.</li>
              <li>⚬\tオフサイドは、審判が判定を下した時点を基準に決済されます。このルールは、ビデオアシスタントレフェリー（VAR）の状況に適用されます.</li>
            </ul>
            <h3>•\t結果のシナリオ:</h3>
            <ul>
              <li>⚬\t勝利：ネガティブなハンディキャップのチームにベットし、そのチームがハンディキャップ値を上回って勝った場合、ベットが勝利します。同様に、ポジティブなハンディキャップのチームにベットし、そのチームが勝ったり引き分けたり、ハンディキャップ値よりも少ない差で負けた場合、ベットが勝利します.</li>
              <li>⚬\t敗北：ネガティブなハンディキャップのチームにベットし、そのチームが引き分けまたは負けた場合、ベットが負けます。同様に、ポジティブなハンディキャップのチームにベットし、そのチームがハンディキャップ値よりも多く負けた場合、ベットが負けます.</li>
              <li>⚬\tプッシュ：実際の結果がハンディキャップと完全に一致する場合、ステークが払い戻されます.</li>
              <li>⚬\tハーフウォン：この結果は、ハンディキャップベットを行い、ベットの半分が勝者とみなされる場合に発生します。四半期ハンディキャップで可能です.</li>
              <li>⚬\tハーフロスト：ベットの半分が失われる結果です。四半期ハンディキャップで可能です.</li>
            </ul>
            <h3>•\t四半期、ハーフ、全体のゴールハンディキャップ市場の例と決済ルール:</h3>
            <ul>
              <li>⚬\tお気に入りにベットした場合（例：チームA -0.25）.</li>
              <li>⚬\tチームAが勝った場合、ベットの全額が勝利します.</li>
              <li>⚬\t試合が引き分けに終わった場合、ステークの半分が返金され、残りの半分が損失と見なされます.</li>
              <li>⚬\tチームAが負けた場合、ベット全体が失われます.</li>
              <li>⚬\tアウェイにベットした場合（例：チームB +0.25）.</li>
              <li>⚬\tチームBが負けると、あなたは全額の賭けを失います.</li>
              <li>⚬\tハーフハンディキャップ：.</li>
              <li>⚬\tお気に入りに賭ける場合（例：チームA -0.5）：.</li>
              <li>⚬\tチームAが勝たなければ賭けに勝利できません.</li>
              <li>⚬\t試合が引き分けに終わるかチームAが負けた場合、賭けに負けます.</li>
              <li>⚬\tアンダードッグに賭ける場合（例：チームB +0.5）.</li>
              <li>⚬\tチームBが勝つか引き分ける必要があります.</li>
              <li>⚬\tチームBが負けると、賭けに負けます.</li>
            </ul>
  
  
            <h3>•\tホールハンディキャップ:</h3>
            <ul>
              <li>⚬\tホールハンディキャップの決済は簡単です：</li>
              <li>⚬\tお気に入りに賭ける場合（例：チームA -1）：</li>
              <li>⚬\tチームAは1ゴール以上で勝つ必要があります。そうでない場合、賭けに勝利できません。</li>
              <li>⚬\tチームAがちょうど1ゴールで勝った場合、引き分けとなります（賭け金が払い戻されます）.</li>
              <li>⚬\tチームAが引き分けまたは負けた場合、賭けに負けます.</li>
              <li>⚬\tアンダードッグに賭ける場合（例：チームB +1）：</li>
              <li>⚬\tチームBは勝つか引き分け、または1ゴール未満で負ける必要があります。そうでない場合、賭けに勝利できません.</li>
              <li>⚬\tチームBがちょうど1ゴールで負けた場合、引き分けとなります（賭け金が払い戻されます）.</li>
              <li>⚬\tチームBが1ゴール以上で負けた場合、賭けに負けます.</li>
              <li>⚬\t利点：アジアハンディキャップベッティングは引き分けの可能性を排除し、より多くのベッティングオプションと潜在的に高い勝率を提供します.</li>
              <li>⚬\tアジアハンディキャップベットがインプレイで行われる場合、ベットが置かれる前に与えられたゴールは決済の目的で割引されます。ベットが置かれた時点でのスコアラインは0-0として扱われます.</li>
            </ul>
  
  `
      },
      {
        dd: `
            <h3>
              <a href="/file/sportRules_common.pdf"  target="_blank" style="color: #fc005b">Full rules（Premier version）</a>
            </h3>`
      },
      {
        dd: `
            <h3 style="color: #fc005b">概要（Classic version）</h3> 
            <ul>
              <li>•\tこれらの規則は、当社が受け入れるすべてのベットに適用される規約条件を定義することを目的としています。お客様は、自身がこれらの規則とベットが承認されれる条件を予め把握し遊戯する責任があります。当社はこれらの規則をいつでも変更する権利を保有しており、そのような変更はこの公式サイトで通知されます。また、「お知らせ」に詳細が記載されている場合、それはベットの規則の一部と見なされます。<br/>内容に矛盾がある場合は、追加および訂正情報が規約を上書きする場合もございますので、ご注意ください。</li>
              <li>•\t当サイトはこれらのルールは公正であると考えており、ルールに関する問い合わせが必要な場合は、弊社のカスタマーサービスチームにご連絡ください。私共は皆さまに最高のカジノ体験を提供させていただくためにサービスを提供しております。私共のチームはお客様の問題およびご質問に対して迅速に対応することで思いやりのあるサービスを提供しております。</li>
              <li>•\t当社で行われるすべての賭けには、次の規則と規制が適用されます。個々のスポーツで提供されているルールは、その特定のスポーツまたは市場に関する以下のルールに優先します。</li>
              <li>•\tすべてのスポーツイベントにおける最低および最高の賭け金は当社によって決定され、事前の通知なしに変更される場合があります。</li>
              <li>•\t当社は、お客様がご自身のベッティングアカウント内で記載している情報に完全に依存しています。当社は、お客様による情報の虚偽表示に対して一切の責任を負いません。</li>
              <li>•\tベットする場合、プレイヤーは自分のアカウントでの取引に対して全て自己責任で行うことに同意します。ベットを確定する前に、間違いがないか必ず事前に確認を行ってください。ベットが完了すると、その後変更することはできませんのでご注意ください。当社は、プレイヤーによるベット漏れまたは重複ベットについて一切の責任を負いません。プレイヤーは、ベットされたすべての賭けが正常に行われていることを確認するために、ベット確定後にサイト内の「ベット状況」で確定されたベットを確認できます。</li>
              <li>•\t当社は、いつでもお客様のアカウントを閉鎖または一時停止する権利を保有します。</li>
              <li>•\t当社は、いつでも市場での賭けを一時停止する権利を留保します。</li>
              <li>•\t当社は、結果が判明しているイベントで行われたベットを無効にする権利を保有します。イベントの「ライブ」遅延により、正しい価格でベットが行われなかった場合、この期間中に行われた賭けは、勝敗に関わらず無効となります。</li>
              <li>•\tイベントに関する情報 (日付、時間、スコア、統計など) は、一般的な情報提供のみを目的としています。当社は、提供される情報の正確性については責任を負いません。</li>
              <li>•\t試合/イベントが中止、中断、延期され、公式キックオフ/開始時間から 36 時間経過しても開始および再開できなかった場合、個別に別段の記載がない限り、結果は無効とみなされ、賭けはキャンセルされます。無条件で決定される特定のマーケットは、それに応じて決済されます。これらのマーケットの決済手順は、個々のスポーツのルールに記載されています。クイーンカジノは公式な結果、イベントの審判および関係者により決定とは関係なく独自の裁量でベットをキャンセルする権利を保有します。 「パーレー」の場合、賭けは引き続き有効とみなされますが、パーレー内の選択は無効とみなされます。</li>
              <li>•\t試合/イベントが中止された場合、個々のスポーツのルールに別段の記載がない限り、現状の結果は無効とみなされ、賭けはキャンセルされます。試合結果無効の決定と定義はクイーンカジノの裁量で行うことができます。</li>
              <li>•\tチーム名またはその他の言語での情報と矛盾がある場合、当社はすべてのチーム名を英語で参照します。</li>
              <li>•\t当社は、いかなる状況においても、以下の事象によって生じた矛盾、損害、損失については責任を負いません。</li>
              <li>•\t当社のウェブサイト、サーバー、またはネットワークへのサービスの中断。</li>
              <li>•\t当社サーバー上のデータの消失または破損。</li>
              <li>•\tハッカーによる会社の Web サイト、サーバー、またはネットワークに対する悪意のある攻撃。</li>
              <li>•\t当社のウェブサイトにアクセスする際のインターネットサービスが遅い、または不規則である。</li>
              <li>•\t規約の解釈に矛盾が生じた場合には、当社の裁量が常に優先されます。</li>
            </ul>   
          `
      },
      {
        dd: `
            <h3>結果と決済</h3>
            <ul>
              <li>•\t各スポーツまたはイベントのルールに別段の記載がない限り、イベントの結果は、関連するスポーツまたは競技の公式運営機関によって最終結果が確認された後、決済されます。同社は、賭けを目的とした抗議や決定の覆りを認めません。当社は、決済処理後 36 時間以内に、人的エラー、システムエラー、または参照元の結果ソースによる間違いによる結果のみを再決済します。</li>
              <li>•\t結果は、関連するスポーツまたは競技の公式運営機関からの情報源を使用して決済されます。</li>
              <li>•\tベットを決済する際は、当社で取り扱っている最終のプレイヤーベットデータを重視するものとします。当社は、顧客が有効なベット取引き証明を作成できない限り、いかなる苦情も受け付けません。それ以外の場合は、会社の取引記録が最終の決済材料としてみなされます。</li>       
            </ul>
          `
      },
      {
        dd: `
             <h3>インプレイ/インランニングのルール</h3>
             <ul>
               <li>•\tインプレイとは、進行中のゲームまたはイベント中にベットできるマーケットです。ゲームまたはイベントが開始されるとベットは受け付けられ、ゲームまたはイベントがオッズ表示から外されるとベットは提供は終了します。</li>
               <li>•\tインプレイで行われたすべての賭けは、システムの承認の対象となります。これにより、各ベットの承認 (または拒否) が遅れる場合があります。</li>
               <li>•\tシステムによって処理されているベットはサイト内の「未確定」の中から「確認中」という文字が表示されます。これは、賭けがまだ確認または拒否されていないことを意味します。</li>
               <li>•\t賭け中に重大なイベントが発生した場合、賭けは拒否されることがあります。これらのイベントには、ゴール、レッドカード、ペナルティ、または技術的故障が含まれますが、これらに限定されません。</li>
               <li>•\t試合結果を左右するようなイベントが発生した後に行われたビデオ アシスタント レフェリー (VAR) の決定により、確定ベットが無効になる場合があります。無効となるベットは、重要なイベントの発生時から、最終的な VAR の決定が正式に発表されるまでとなります。</li>
               <li>•\tインプレイの場合、当社が決定した正しい時間、価格および状況下で賭けが行われることを保証するために、以下の条件を定めます。</li>
               <li>•\tゴール/ポイントがスコアリングされた時間は、当社が独断で判断します。当社は、リーグ/クラブの公式 Web サイト、メディア Web サイト、または「ライブスコア」Web サイトに記載されているゴール/ポイントの得点時間については一切言及しません。</li>
               <li>•\tゲーム/イベントでイベントが発生した後に賭けが行われたと疑う合理的な理由がある場合、当社は理由や証拠などを提供することなく、そのような賭けをキャンセルする権利を留保します。</li>
               <li>•\tスコア、オッズ、またはハンディキャップゴールラインを更新するために当社の Web サイトにアクセスできない場合、当社は、アクセス障害時に行われたすべての保留中の賭けをキャンセルする権利を保有します。</li>
             </ul>
          `
      },
      {
        dd: `
            <h3>リザベーションベットのルール　（プレベット）</h3>   
            <ul>
              <li>•\tプレベットは、ベットが条件付きで有効となる非即時ベッティング方法です。当社は、いつでもプレベット機能の提供および撤回する権利を保有します。</li>
              <li>•\tすべてのプレベットは、システムの受付手順に従い、一般的なベットに関するサイト上の規定に準拠する必要があります。</li>
              <li>•\tベットスリップに「予約中」と表示されている場合は、予約ベットは成功したが無効であることを意味します。オプションのオッズが予約オッズに達すると、システムは自動的に賭けを処理します。</li>
              <li>•\tプレベットが条件を満たしてベットプロセスに入り、ウォレット残高がベットを完了するのに十分でない場合、プレベットは自動的に無効となり、ベットは継続されません。プレベットを成立させるために、ウォレット残高が十分であることを確認してください。</li>
              <li>•\tプレベットが決済またはキャンセルされた場合、関連するプレベットに対して行われたすべての賭けは無効とみなされます。</li>
              <li>•\tサッカーのスコア（ゴール、コーナーキック、レッド/イエローカードなど）が変化した後、リザーブ内のベットの精算がスコアに関連する場合、リザーブ内のベットは無効として確定されます。例えば、ゴール スコアが 0-0 の場合、リザーブ ベットは 2.5 を超えるゴールと 5.5 を超えるコーナーです。ゴールスコアが1-0になった後、2.5を超えるリザーブベットゴールは無効とされ、5.5を超えるリザーブベットコーナーも無効として設定されます。</li>
              <li>•\t1 人のユーザーに対して同時に存在するプレベットの数は当社によって設定され、いつでも調整できます。</li>
              <li>•\t予約の賭け金と予約内の賭けのオッズを変更できます。</li>
            </ul>
          `
      },
      {
        dd: `
            <h3>試合時間に関するルール</h3>
            <ul>
              <li>•\tイベントが予定時刻より前に開始された場合、イベント開始前に行われたベットのみが有効とみなされます。インプレイ ベットを除き、ゲーム開始後に行われたすべてのベットは無効または無効とみなされます。</li>
              <li>•\t怪我やロスタイムが発生するサッカーイベントの場合、これはレギュラータイムの一部とみなされます。</li>
              <li>•\t個別のスポーツ規則に別段の定めがない限り、延長戦の得点は通常のレギュラータイムにはカウントされません。</li>
            </ul>
          `
      },
      {
        dd: `
            <h3>エラー</h3>
            <ul>
              <li>•\t当社は人為的または機械的なエラーが起こらないよう注意をしておりますが、明らかな誤りがあった場合には賭けを無効にする権利を保有します。これには以下が含まれますが、これらに限定されません。</li>
              <li>•\t明らかに不正確な表示または表示金額である場合。</li>
              <li>•\tハンディキャップ、オーバー/アンダー、トータル、ライン、スプレッドなどの誤り。</li>
              <li>•\t日程、日付、時間が間違っている。</li>
              <li>•\tベットを無効にする場合は、可能な限りお客様に連絡するよう努めます。</li>
            </ul>
          `
      },
      {
        dd: `
            <h3>不規則な賭け</h3>
            <ul>
              <li>•\t当社のプラットフォームに不正行為または損害を与えていると当社が疑うメンバーは、警告や通知なしにベットが無効になり、アカウントが停止されます。異常な行為には、本ウェブサイトの運営を妨害するためのデバイス、自動デバイス、ソフトウェア、プログラムなどの使用が含まれます。</li>
              <li>•\tより良いオッズを受け入れる/任意のオッズを受け入れる/オッズの変更は受け入れられません。</li>
              <li>•\tオッズの変更を受け入れるか受け入れないかの機能は、システムによって設定された「ベッティング ルール」によって選択できます。</li>
              <li>•\t「より良いオッズを受け入れる」という用語は、提供されるオッズが選択時に最初に示されたものよりも高いリターンを示している限り、ベットスリップに追加されたすべての選択が自動的に処理できることを意味します。ベットスリップ内の調整されたオッズが最初に示された値よりも低い値を示した場合、ベットは自動的に処理されません。</li>
              <li>•\t「オッズを受け入れる」とは、調整されたベットスリップラインが元のリターンよりも高いか低い値を示しているかに関係なく、このオプションに追加されたすべてのベットスリップが自動的に処理できることを意味します。</li>
              <li>•\t「オッズの変更は受け付けられない」とは、すべてのベットスリップがこのオプションに追加されることを意味します。ベットスリップラインが変更される限り、ベットは自動的に処理されません。</li>
            </ul>
          `
      },
      {
        dd: `
            <h3>強化された価格スペシャル / マルチプル (価格ブースト)</h3>
            <ul>
              <li>•\t当社は、いつでもすべての高いオッズ/マルチプルの賭けを提供および撤回する権利を留保します。</li>
              <li>•\tすべての高オッズベットの賭け金額を制限します/マルチプルベットはいつでも調整できます。</li>
              <li>•\tすべての高オッズ/マルチプル ベットのルールは、一般ルールおよび上記の各スポーツ ルールに規定されているルールと同じです。 - サッカーの例 - マンチェスター ユナイテッドの勝利と以上 / 2.5 未満」は、「1 X 2 とゴール数のオーバー / アンダー」サッカールールに準拠します。</li>
              <li>•\tこれらのサッカー マーケットでは、通常の時間に基づいてより高いオッズ/マルチプル ベットが提供され、延長戦やPK戦は含まれません (別途明記されていない限り)。</li>
              <li>•\tマルチベット（プライスブースト）の場合、結果が無条件に決定した時点でイベントが中断された場合、ベットはそれに応じて「勝ち」または「負け」として精算されます。 - - サッカーの例: 2.5 ゴール以上を選択し、中断時のスコアが 2-1 だった場合、その選択は「勝利」とみなされます。</li>
              <li>•\tマルチベット（プライスブースト）の場合、結果が未定のときにイベントが中断された場合、ベットは「無効」となります。 - - サッカーの例: 正しいスコア 2-2 (フルタイムで) を選択し、中断時のスコアが 1-1 だった場合、ベットは「無効」になります。</li>
              <li>•\tマルチベット (プライスブースト) の場合、1 つ以上の選択が「引き分け」または「無効」になった場合、オッズは再調整されます。</li>
            </ul>
          `
      },
      {
        dd: `
            <h3>
              <a href="/file/sportRules_ja.pdf"  target="_blank" style="color: #fc005b">全ルール（Classic version） </a>
            </h3>
            <ul>
              <li>※ なお、当サイトは英語版を優先します。</li>      
              <li>※ 最終変更：2024年01月12日。</li>
            </ul>`
      }
    ]
  },
  termsAndConditions: {
    title: 'サービス利用規約',
    content: [
      {
        dd: `
            <h3 style="margin-top:0px;">1.　一般</h3>
            <p>1.1. 本文書に定義されたこれらの一般利用規約（以下「利用規約」）は、Zen Estratega SRLが管理するブランドであるQueen-CasinoおよびShinQueenを通じて提供されるゲームおよびプレイの使用を規定します（以下「ウェブサイト」）。
            <br/ ><br/ >1.2. Zen Estratega SRLは、2024年2月16日にコスタリカに登録された会社で、住所は400, Calle La Ceiba, San Rafael, Escazú, Province of San Jose, Costa Rica、登録会社番号は3-102-897673です。
            <br/ ><br/ >1.3. Zen Estratega SRLを「当社」、「私たち」、「会社」と呼び、プレイヤーを「あなた」または「プレイヤー」と呼びます。
            <br/ ><br/ >1.4. 本契約の現行バージョンは、ウェブサイト上に新バージョンが公開されるまで有効です。
            <br/ ><br/ >1.5. これらの利用規約は、当社が提供するゲームのプレイを可能にするすべてのプラットフォームおよびデバイスにも適用されます。「ゲーム」とは、ウェブサイトで利用可能なカジノおよびその他のゲームを指します。
            <br/ ><br/ >1.6. 「ゲーム」、「ゲームズ」、「ゲーム」とは、ウェブサイトで提供されるすべてのゲーム製品を指します。
            <br/ ><br/ >1.7. 当社はこれらの利用規約を改訂する権利を有し、これらは本ウェブサイトに掲載された時点から有効となります。利用規約が変更された場合、プレイヤーも当社もその変更に従うものとします。これらの利用規約の変更に同意しない場合は、アカウントにログインすることができず、アカウントに残高がある場合は引き出すことができます。詳細については、support@queen-casino.comまでお問い合わせください。必要に応じてチャットも利用可能です。
            <br/ ><br/ >1.8. 登録する前に本利用規約をしっかりお読みの上、必ず理解してください。本利用規約にご同意いただけない場合には、ウェブサイトの使用および使用の継続をおやめください。
            <br/ ><br/ >1.9. これらの利用規約は、当社のウェブサイトの登録プロセスで必要な情報を確認し、登録する際に適用されます。アカウントを登録することで、これらの利用規約を読み、理解し、受け入れることに同意したものとみなされます。ウェブサイトを利用することにより、現行の利用規約を受け入れることに同意するものとします。
            <br/ ><br/ >1.10. プレイヤーは、これらの利用規約に従うことを理解し、同意するものとします。これらの利用規約は随時改訂されることがあります。
            <br/ ><br/ >1.11. これらの利用規約は複数の言語で公開されていますが、すべて同じ原則に基づいており、プレイヤーに情報を提供するために翻訳されています。ここでは、英語で書かれたバージョンのみが、プレイヤーと当社の間の法的関係を確立します。これらの利用規約の英語版と非英語版の間に差異がある場合は、英語版が優先されます。
            </p>
  
            <h3>2.　義務</h3>
            <p>2.1. 当社が提供する各ウェブサイトで開設できるアカウントは1つのみです。重複するアカウントが発見された場合、当社はそれを終了させる権利を有します。
            <br/ ><br/ >2.2. プレイヤーは18歳以上であるか、居住国の法律に従いそれ以上の法定年齢を満たしている必要があります。居住国でギャンブルが合法となる年齢を知ることはプレイヤーの責任です。
            <br/ ><br/ >2.3. プレイヤーは、ウェブサイトのアカウントにアクセスするためのユーザー名やパスワードなどのアカウント情報を自己責任で管理するものとします。これらの情報が不正使用されている疑いがある場合は、直ちに当社に報告し、アカウントや資金への不正アクセスを防止するための適切な措置を講じてください。
            <br/ ><br/ >2.4. プレイヤーは、本人のみとしてプレイすることができ、他人や会社を代わりにプレイすることはできません。
            <br/ ><br/ >2.5. プレイヤーは、個人的で楽しみと娯楽の目的だけ、ゲームに参加することができます。
            <br/ ><br/ >2.6. プレイヤーは、ギャンブルが合法である法域に居住している必要があります。オンラインギャンブルを禁止している法域、またはTobique Gaming Commissionがオンラインギャンブルを違法と見なすその他の法域からウェブサイトにアクセスし、アカウントを登録することは許可されていません。Queen-Casinoは、イギリス、アメリカ、オンタリオ、ニューブランズウィック、およびFATF制裁国に所在する（短期滞在者を含む）個人のアクセスを禁止します。
            <br/ ><br/ >2.7. プレイヤーは、残高やアカウントを他人に譲渡、売却、または抵当に入れることはできません。これには、アカウント、賞金、預金、賭け、これらに関連する権利や請求権の所有権など、あらゆる価値のある資産の譲渡が含まれます。この禁止には、負担、質権、譲渡、使用権、取引、仲介、抵当、および信託やその他の第三者、会社、自然人または法人、財団、および/または団体との協力における贈与などが含まれます。
            <br/ ><br/ >2.8. プレイヤーは、契約期間中に提供する情報（住所やメールアドレスなど）が完全で真実かつ正確であり、情報に変更が生じた場合は直ちに当社に通知するか、詳細を変更することに同意するものとします。
            <br/ ><br/ >2.9. プレイヤーは、アカウントに十分な資金がある場合にのみ、ゲームに参加することができます。
            <br/ ><br/ >2.10. プレイヤーは、違法行為や不正資金を使用することはできません。
            <br/ ><br/ >2.11. プレイヤーは、ウェブサイトから得た賞金に対して、関連する法律に基づいて適用される税金やその他の料金を報告および会計処理する責任を負います。
            <br/ ><br/ >2.12. アカウントへの入金および出金に関しては、有効で合法的に所有するクレジットカードやその他の金融機関を使用するものとします。
            <br/ ><br/ >2.13. 当社が提供するゲームをプレイすることで、アカウントに預けた資金を失うリスクがあることを理解し、同意するものとします。
            <br/ ><br/ >2.14. プレイヤーは、ゲームへの参加に関して、詐欺的、共謀的、不正行為、その他の違法行為に関与しないものとし、ソフトウェア支援の方法や技術、ハードウェア装置を使用して参加しないものとします。当社は、このような行為が発覚した場合、賭けを無効にする権利を有します。
            <br/ ><br/ >2.15. プレイヤーは、当社が定める規則および利用規約、ならびに適用されるすべての法律、規則、および規制に従って、個人的かつ娯楽目的でのみソフトウェアを使用するものとします。
            <br/ ><br/ >2.16. プレイヤーは、ウェブサイトを利用する他のプレイヤーや当社のサポートスタッフに対して礼儀正しく接し、無礼または卑猥なコメントを避けるものとします。
            </p>
            <h3>3.　アカウント</h3>
            <p>3.1. お金を入金し、賭けを行うためには、まず個人として当社に登録し、アカウント（「アカウント」）を開設する必要があります。
            <br/ ><br/ >3.2. 一人につきアカウントは一つのみ開設でき、個人として登録する必要があります。同一名義または同一人物に関連する複数のアカウントが開設された場合、それらを閉鎖する権利を当社は有します。
            <br/ ><br/ >3.3. 登録フォームに要求されたすべての必須情報を入力する必要があります。これらの情報はすべて真実で正確でなければなりません。提供する情報が真実で完全かつ正確であることを保証するのはプレイヤーの責任です。当社は確認手続きを行い、虚偽または誤解を招く情報が提供された場合、アカウントへのアクセスをブロックまたは閉鎖する場合があることを通知します。登録フォームに提供された必須情報が変更された場合、その情報を更新し続ける必要があります。
            <br/ ><br/ >3.4. 複数の登録アカウントがあることに気付いた場合は、直ちに当社に通知する必要があります。通知しない場合、アカウントのアクセスがブロックされる可能性があります。
            <br/ ><br/ >3.5. 登録プロセスの一環として、ウェブサイトにログインするためのユーザー名とパスワードを選択する必要があります。ログイン情報を安全に保管することは、お客様の責任です。ログイン情報は誰にも開示しないでください。お客様ご自身の情報が第三者へ開示による発生したお客様の損害については、当社は一切の賠償責任を負わないものといたします。
            <br/ ><br/ >3.6. アカウント資金を他のプレイヤーに送金したり、他のプレイヤーからアカウントに資金を受け取ったり、ユーザーアカウントを譲渡、売却、取得したりすることはできません。
            <br/ ><br/ >3.7. 当社は、単独の裁量でアカウントを拒否または閉鎖する権利を有しますが、当社がすでに行った契約上の義務は、法律によって当社に付与された権利に従って履行されるものとします。
            <br/ ><br/ >3.8. 当社は、プレイヤーが共謀して不正行為を行うことを禁止しており、いかなる種類のロボットおよびプログラム設備でゲームに参加することを許可しません。
            <br/ ><br/ >3.9. アカウントを閉鎖をご希望の場合、いつでもカスタマーサポートに書面で連絡することで閉鎖することができます。 アカウントを閉鎖すると、本利用規約も終了することになります。 アカウント閉鎖の理由がギャンブル依存症の可能性に関連している場合は、プレイヤーがそれを明示する必要があります。
            <br/ ><br/ >3.10. プレイヤーアカウントが閉鎖された場合、アカウントに関連する情報は閉鎖された時点から最低6年間保存されます。関連情報には、プレイヤーの名前、住所、電話番号、メールアドレス、およびアカウントの取引詳細が含まれます。
            </p>
  
  
            <h3>4.　入金</h3>
            <p>4.1. 入金処理には手数料がかかる場合があります。手数料が発生する場合は、入金プロセス中に表示されます。入金はクレジットカードや銀行振込で行うことができます。
            <br/ ><br/ >4.2. 最低入金額および最高入金額は当社が定め、詳しくはウェブサイトをご参照ください。
            <br/ ><br/ >4.3. アカウントへの資金の入金は、ウェブサイトに指定された方法を使用して行われ、随時変更されることがあります。使用する方法に関する出金のタイミングの詳細は、ウェブサイトの関連ページに記載されており、随時変更されることがあります。
            <br/ ><br/ >4.4. 入金金額は追加の確認手順が必要でない限り（詳細は4.5をご覧ください）、利用可能なすべての入金オプションが確認されてから合理的な期間内に口座に送金されます。
            <br/ ><br/ >4.5. 当社は、支払いを確認するためにプレイヤーの身元を確認する追加の手続きおよび手段を使用する権利を有します（顧客確認）。
            <br/ ><br/ >4.6. 当社が技術的または人的エラーその他の理由で、プレイヤーのアカウントにプレイヤーに帰属しない賞金を誤って入金した場合、技術的エラー、人工エラー、およびその他の理由を問わず、賞金は当社のものであり、プレイヤーのアカウントから返還必要があります。 当社がエラーを発見する前に、プレイヤーが自分に属さない資金を引き出した場合、法律で規定されている他の救済策や訴訟には影響しません。誤って支払った金額はプレイヤーが当社に対して負っている負債とみなされます。誤った入金が発生した場合、直ちに電子メールで当社に通知する義務があります。
            <br/ ><br/ >4.7. プレイヤーは有効で合法的に所有するクレジットカードやその他の決済方法のみを使用するものとします。
            <br/ ><br/ >4.8. プレイヤーの最低入金額は、選択した入金方法によって異なります。この特定の金額は、入金手続中に当社の関連セクションに表示されます。取引を進める前に各方法の定められた最低金額を確認してください。
            <br/ ><br/ >4.9. 違法な手段で得た資金を入金することは法律で禁止されています。
            <br/ ><br/ >4.10 会社は、米ドル（USD）、日本円（JPY）、暗号通貨（USDT/USDC/BTC/ETH/DOGE/LTC/TRX/DAI/XRP/ADA）での入金を受け付けます。ウェブサイトからの出金もこれらの通貨で行われます。複雑な手続きや追加の為替換算を避けるためにも、入金または出金の選択した方法がこれらの通貨をサポートしていることを確認してください。なお、為替手数料はお客様のご負担となりますのでご注意ください。
            <br/ ><br/ >4.11. 当社は、ランダムにおよび独自の裁量でセキュリティチェックを実行します。プレイヤーは、セキュリティチェックを実施する際に当社が追加情報を要求する場合があること、および当社が要求した適切な情報を受け取るまで、プレイヤーのアカウント、登録、または支払いを一時停止または終了する場合があることに同意するものとします。
            <br/ ><br/ >4.12. 当社は、カラーコピーの身分証明書のみを受け付けます。
            <br/ ><br/ >4.13. アカウントへの入金は、ゲームプレイに見合ったものである必要があります。したがって、当社の裁量で、適切なレベルのゲームプレイに使用されていない入金額に関連してユーザーが出金リクエストを行った場合や、当社が為替レートの変動を悪用していると疑われる場合など、濫用の疑いがある場合、当社は絶対的な裁量でそれぞれの入金を部分的または全体的に取り消し、関連する費用を差し引き、ゲームアカウントを無期限に閉鎖する権利を有します。さらに、そのような場合には、出金リクエストを処理する前に、入金の満足できる証明と追加の身分証明書のコピーを要求および取得する権利を有します。そのリクエストの処理は完全に当社の裁量に委ねられます。
            <br/ ><br/ >4.14. 当社は、プレイヤーが盗難または偽造されたクレジットカードまたはアカウント番号の使用、偽造、および偽造、盗難またはその他の方法で不正取得されたデータまたは書類の提出などの不正行為に関与していると合理的に疑われる場合、アカウントを終了し、プレイヤーを禁止し、すべての資金を保持する権利を有します。
            </p>
  
            <h3>5.　出金</h3>
            <p>5.1. 利用可能な出金方法はウェブサイト上に明確に記載されており、プレイヤーの市場によって異なる場合があります。
            <br/ ><br/ >5.2. 出金は、プレイヤーの銀行口座またはウェブサイト上で選択可能なその他の出金方法に対して行われます。 選択した出金方法で許可されている場合、以前に入金に使用したのと同じ方法でプレイヤーに返金されます。
            <br/ ><br/ >5.3. 「ボーナスマネー」と表示された資金やゲームが中断されたままになっている資金は出金できません。
            <br/ ><br/ >5.4. 会社に雇用されている検証チームは、単一または累積の入金または出金に対して追加の検証手続きを行い、さらに、支払額が低い場合やランダムなセキュリティチェックの場合でも、そのような検証手続きを実施する権利を留保します。これには、国民IDカードのコピー、パスポート、個人番号カード、住所証明書、および/または本人確認用の写真などが含まれる場合があります。
            <br/ ><br/ >5.5. すべての取引はマネーロンダリングを防ぐためにチェックされます。プレイヤーがウェブサイト上のゲームに関連する不審な行為に気付いた場合、直ちに当社に報告する必要があります。当社は、マネーロンダリング防止規則に従って要求された場合、アカウントを一時停止、ブロック、または閉鎖し、資金を保留することができます。
            <br/ ><br/ >5.6. 当社の製品はゲームプレイ中に即座に消費されることにご注意ください。プレイ中の返金、商品の返品、ゲーム中のキャンセルはできません。リアルマネーでゲームをプレイする場合、資金は即座にプレイヤーのアカウントから引き落とされます。
            <br/ ><br/ >5.7. プレイヤーは当社を銀行機関として扱ってはならず、利息を期待してはなりません。
            <br/ ><br/ >5.8. 出金処理には手数料がかかる場合があり、出金リクエスト時に明記されます。
            </p>
  
  
            <h3>6.　ボーナスと報酬制度の条件</h3>
            <p>6.1. 定義されたボーナスおよび報酬条件は、ウェブサイトに新しいバージョンが提供されるまで有効です。
            <br/ ><br/ >6.2. 当社は、付与日から60日以内に使用されなかったボーナスマネーを保留および取り消す権利を有します。個々のキャンペーンは、有効期限が短い場合があり、その場合、キャンペーンオファーを受け取った際にプレイヤーに通知されます。
            <br/ ><br/ >6.3. ボーナスを受け取る資格がある場合、ボーナスを引き出す前に賭け条件を満たす必要がある場合があります。当社は、賭け条件を満たすためにどのゲームとどれだけのゲーム貢献するかを選択する権利を有します。
            <br/ ><br/ >6.4. ウェルカムボーナスには、入金額とボーナス額の合計の25倍の賭け条件が適用されます。ボーナスから得た資金は、関連する賭け条件が完了するまで引き出せません。
            <br/ ><br/ >6.5. 特別の定めがある場合を除き、ボーナス資金はスロットゲームにのみ使用できます。
            <br/ ><br/ >6.6. 当社のウェルカムボーナスは、1世帯、IPアドレス、および/またはクレジットカード番号につき1回のみ受け取ることができます。ボーナスの悪用または不正行為の証拠が見つかった場合、アカウントを閉鎖し、既存の資金を没収する権利を有します。
            <br/ ><br/ >6.7. 各ボーナスキャンペーンには特定の詳細および条件がある場合があり、その場合、それらの情報はユーザーに提供されます。
            <br/ ><br/ >6.8. ボーナス資金でトリガーされたゲームラウンドおよびゲーム機能は、ボーナスが有効な間にプレイする必要があります。プレイヤーは、ボーナスの賭け条件を満たすまで、または新しい資金を入金するまで、ゲームラウンドやゲーム機能の完了を遅らせることはできません。そのような行為が確認された場合、プレイヤーは、カジノ管理者の裁量でボーナスおよび/または関連する賞金が無効になることに同意します。
            <br/ ><br/ >6.9. 当社は、ボーナスの不正使用が疑われる場合、プレイヤーのアカウントを閉鎖し、残高の払い戻し、残高の送金、またはアカウントで発生した賞金の返還を行わない権利を有します。不正利用には、現金の隠匿、ボーナスの隠匿、または複数アカウントの使用が含まれますが、これらに限定されません。
            <br/ ><br/ >6.10. アカウント残高が0.00 EUR/USDに達し、すべてのゲームラウンドが終了して決済された場合、賭け条件は無効と見なされることがあります。
            <br/ ><br/ >6.11. キャッシュバックボーナスは異なり、賭け条件は適用されません。このようなボーナスは、プレイヤーのリアルマネー/現金アカウント残高に直接クレジットされます。
            <br/ ><br/ >6.12. ボーナスが自動反映されない場合は、カスタマーサポートまでお問い合わせください。
            </p>
  
            <h3>7.　一時停止および非アクティブアカウント</h3>
            <p>7.1. 一時停止アカウントとは、12か月以内のアクセスがなく、リアルマネー残高を持つアカウントを指します。
            <br/ ><br/ >7.2. 非アクティブアカウントとは、30か月以内のアクセスがなく、リアルマネー残高を持つアカウントを指します。
            <br/ ><br/ >7.3. 当社は、アカウントが非アクティブになる1か月前に電子メールで通知します。アカウントが非アクティブとなると、当社はアカウントに対して月額$5の手数料を課す場合があります。
            </p>
  
  
            <h3>8.　責任あるギャンブル</h3>
            <p>8.1. プレイヤーは、アカウント（ログイン）へのアクセスを一定期間または無期限に制限することを任意で選択でき、その設定期間中はアカウントにアクセスできません。自己除外の期間は、7日間、30日間、90日間、または永久的に選択できます。これらの期間に変更はありません。
            <br/ ><br/ >8.2. 上記のすべての制限は、ウェブサイトの「セキュリティ」のページから行うことができます。、また、ライブチャットやsupport@queen-casino.comまでメールにてカスタマーサポートに連絡して依頼することもできます。
            <br/ ><br/ >8.3. 自己除外のリクエストは当社に対してのみ有効であり、当社が運営する他のサイトには適用されません。
            <br/ ><br/ >8.4. プレイヤーの継続的なサービスの利用がプレイヤーに悪影響を及ぼしていると当社が判断した場合、当社は独自の裁量でプレイヤーのアカウントを任意のプラットフォーム上でブロックまたは閉鎖する権利を有します。
            <br/ ><br/ >8.5. プレイヤーがギャンブル依存症であり、サービスの継続的な利用がプレイヤーに悪影響を及ぼしていると当社が判断した場合、当社は独自の裁量でプレイヤーのアカウントを任意のプラットフォーム上でブロックまたは閉鎖する権利を有します。
            <br/ ><br/ >8.6. アカウントを閉鎖する際、以下のことを認識するものとします：
            <br/ >- 自己除外の期間中、または永久的な自己排除が選択された場合、その期間中に新しいアカウントを開設しようとしないこと。
            <br/ >- 自己除外期間中にアカウントを開設した場合、当社は最初の発見時にすべてのアカウントを閉鎖するよう努めます。
            <br/ >- 当社はプレイヤーが当社の製品でギャンブルをするのを防ぐために合理的な措置を講じる責任を負います。これらの合意された条件を破らないようにする責任はプレイヤーにもあります。
            </p>
  
            <h3>9.　プライバシーポリシー</h3>
            <p>9.1. プレイヤーは、当社による個人データの収集と使用を承認し、これを受け入れるものとします。データ保護法に準拠した個人データの処理にアクセスするには、当社のプライバシーポリシーをご覧ください。
            </p>
  
  
            <h3>10.　例外的な状況と試合の中断</h3>
            <p>10.1. 予期しない技術的な問題や、第三者プロバイダーの技術的な問題など、当社の管理外の状況が発生した場合、当社は賭けをキャンセルし、プレイヤーに返金する権利を有します。
            <br/ ><br/ >10.2. 賭けが過剰である場合、またはプレイヤーの賭けパターンがシステムを悪用する形で行われていると判断した場合、当社は賭けを制限、キャンセル、および拒否する権利を有します。
            <br/ ><br/ >10.3. 返金が決定された場合、返金額はプレイヤーのアカウントに返金され、プレイヤーに通知され、決定後48時間以内に手続きが完了します。
            <br/ ><br/ >10.4. ゲームが終了しない状態で「スタック」している場合、例えば、ブラックジャックのアクティブハンドをプレイ中に接続が失われた場合、当社は定期的にそのような賭けを「クリーンアップ」し、賭け/賭け金をプレイヤーのアカウントに返金する権利を有します。これは月単位で実行されます。ゲームがサーバー上で中断または誤動作した場合、プレイヤーに返金されます。
            <br/ ><br/ >10.5. ボーナスキャンペーンが何らかの形で誤設定されている場合、当社はプレイヤーの残高とアカウントの詳細を修正する権利を有します。
            <br/ ><br/ >10.6. ゲームにバグや誤設定が含まれており、不正な動作や支払いを引き起こす場合、当社はそのゲームを削除し、プレイヤーの残高とアカウントの詳細を修正する権利を有します。
            <br/ ><br/ >10.7. 当社は、ゲームプレイ中のダウンタイム、サーバー障害、ラグ、またはゲームプレイに対する技術的または政治的干渉について責任を負いません。返金は管理者の裁量でのみ行われる場合があります。
            <br/ ><br/ >10.8. 当社は、ウェブサイトやそのコンテンツに関連して発生した、または発生したとされるいかなる損害や損失についても責任を負いません。これには、運用や送信の遅延や中断、データの喪失や破損、通信やラインの故障、サイトやそのコンテンツの誤用、またはコンテンツのエラーや脱漏が含まれますが、これらに限定されません。
            <br/ ><br/ >10.9. 当社は、適切と判断した場合、いつでもウェブサイトから任意のゲームを削除する権利を有します。
            </p>
  
  
            <h3>11.　クレーム</h3>
            <p>11.1. 当社のサービスに関するクレームは、ウェブサイトに記載された指示に従ってカスタマーサービスに連絡してください。メールで送信したい場合は、support@queen-casino.com宛に送信してください。
            <br/ ><br/ >11.2. クレームはサポート部門で処理され、サポート担当者がすぐに解決できない場合、当社の組織内でエスカレートされます。プレイヤーにはクレームの状況について合理的に通知されます。
            <br/ ><br/ >11.3. 何らかの理由で Zen Estratega SRLによるクレームの解決に満足できない場合、未解決の紛争をGaming Services Provider N.V Tobique Gaming Commissionに持ち込むことができます。
            </p>
  
            <h3>12.　責任の制限</h3>
            <p>12.1. プレイヤーは自己責任でウェブサイトにアクセスし、ゲームに参加するものとします。ウェブサイトおよびゲームは、明示的または黙示的にかかわらず、一切表明または保証しません。
            <br/ ><br/ >12.2. 前述の規定の一般性を損なうことなく、当社、当社の取締役、従業員、パートナー、サービスプロバイダーは以下のことを保証しません：
            <br/ >- ソフトウェア、ゲーム、およびウェブサイトがその目的に適していること。
            <br/ >- ソフトウェア、ゲーム、およびウェブサイトがエラーなしであること。
            <br/ >- ソフトウェア、ゲーム、およびウェブサイトが中断なくアクセスできること。
            <br/ >- ウェブサイトの使用またはゲームへの参加に関連して生じた、直接的、間接的、特別、結果的、偶発的またはその他の損失、費用、経費、または損害について責任を負わないこと。
            <br/ ><br/ >12.3. プレイヤーはゲームまたはその相互運用性に不具合がある場合、当該不具合中に行われた賭けは無効と見なされることを理解し、認めます。不具合のあるゲームから得た資金は無効と見なされ、その資金を使用してプレイされたすべての後続のゲームラウンドも同様に無効とみなされます。
            <br/ ><br/ >12.4. プレイヤーは、ウェブサイトの使用またはゲームへの参加に関連して生じるいかなる費用、経費、損失、損害、請求および責任について、当社、当社の取締役、従業員、パートナー、およびサービスプロバイダーを完全に補償し、免責することに同意するものとします。
            <br/ ><br/ >12.5. 法律で認められる範囲において、プレイヤーのウェブサイトの使用に関連して生じる当社の最大責任は、いかんを問わず（契約、不法行為、保証違反など）、1500米ドルを超えないものとします。
            </p>
  
            <h3>13.　違反、罰則および契約の終了</h3>
            <p>13.1. プレイヤーが本利用規約のいずれかの条項に違反した場合、または違反していると合理的に疑われる場合、当社はアカウントの開設、停止、または閉鎖、および当選金の支払いを保留し、これらの資金をプレイヤーが負う損害賠償のために充当する権利を有します。
            <br/ ><br/ >13.2. 当社が、プレイヤーがウェブサイトの使用中に違法および/または詐欺的な活動に関与していると疑い、本契約に違反、債権者と問題を抱えたりと当社の事業に対して有害であると判断した場合、当社の絶対的な裁量によりアカウントを凍結、または閉鎖し、賭けをキャンセルすることができます。
            <br/ ><br/ >13.3. プレイヤーは、当社が、規則、利用規約または条件に違反したかどうかを最終的に判断する決定権を有し、当社の ウェブサイトへの参加が一時停止または永久禁止されるかどうかを判断するの最終決定者は当社であることに同意するものとします。
            </p>
  
  
            <h3>14.　分離条項</h3>
            <p>14.1. 本利用規約のいずれかの条項が違法または法的強制力がないと判断された場合、その条項は本利用規約から切り離され、他のすべての条項はその解除にもかかわらず引き続き有効となります。
            </p>
  
            <h3>15.　譲渡</h3>
            <p>15.1. 当社は、本契約を譲渡またはその他合法的に委譲する権利を有します。プレイヤーは、本契約を譲渡またはその他委譲することはできません。
            </p>
  
            <h3>16.　完全契約</h3>
            <p>16.1. 本利用規約は、当社のプライバシーポリシーと併せて、ウェブサイトに関するお客様と当社との間の完全な合意を構成するものであり、詐欺の場合を除き、ウェブサイトに関するお客様と当社との間の電子的、口頭的、または書面によるすべての以前または同時期に行われたコミュニケーションおよびアドバイスに優先します。
            </p>
  
            <h3>17.　準拠法および管轄条項</h3>
            <p>17.1. これらの利用規約は、Tobique法に準拠しています。
            17.2.両当事者は、本利用規約についての違反、終了、または無効に関連して生じるいかなる紛争、論争、または請求も、Tobique法の専属管轄権に服従することに同意するものとします。
            </p>
            <br/ ><br/ ><p>※当サイトは英語版を優先します。
            <br/ >※最終変更：2024年6月21日</p>
          `
      }
    ]
  },
  generalTermsOfUse: {
    title: 'キャンペーン一般利用規約',
    subTitle:
      'QUEEN CASINOでは、入金毎にボーナスマネーを受け取れる機会を数多く、ご提供しております。初回入金ボーナス、セカンド入金ボーナス、サード入金ボーナスといった、入金ボーナスを始め、様々なタイプのキャンペーンがございます。',
    content: [
      {
        dd: `
            <h3>入金不要ボーナス</h3>
            <ul>
              <li class="leter-first">QUEEN CASINOでは、不定期でご登録頂いておりますお客様に、入金不要ボーナスの進呈をしております。(キーワードボーナスや新規登録ボーナス等） 入金不要ボーナスの出金条件、現金化可能上限金額は付与されるボーナスによって異なります。ボーナスごとの利用規約をご確認ください。</li>
              <li class="leter-first">例：初回登録ボーナス$20の出金条件は、進呈金額の30倍のBET実績となり、出金可能額は最大 $120です。</li>
            </ul>
          `
      },
      {
        dd: `
          <h3>入金ボーナス</h3>
          <ul>
            <li class="leter-first">入金ボーナスを獲得された方は、キャッシュウォレットの横にある「ボーナス」のウォレットにて、ボーナス額がご確認頂けます。ボーナスの出金条件を満たされますと、キャッシュウォレットに残高が移動し、出金可能となります。入金ボーナスも、基本的には、初回入金ボーナスと同じように、一定の規定金額以上のご入金で、入金額に対する●●％が入金ボーナスとして進呈されます。例：50%入金ボーナスの場合、20USDのご入金で、10USDのボーナス進呈</li>
            <li class="leter-first">入金ボーナスにつきましては、QUEEN CASINOのサイトもしくは、キャンペーンメールにて、ご案内をしておりますが、入金ボーナスのオファーは、変更する可能性があり、その際は、ボーナスに関する新しい利用規約を掲載致します。もし、特定の利用規約が述べられていない場合は、ボーナス一般利用規約をご参照ください。</li>
          </ul>
          `
      },
      {
        dd: `
          <h3>AG JACKPOTの出金条件</h3>
          <ul>
            <li class="leter-first">Asia GamingのJACKPOT（Baccarat）で獲得できる賞金は出金条件0倍です。賞金で1度何かのゲームをプレイした場合、出金条件は１倍のロールオーバーを達成していただく必要があります。</li>
          </ul>
          `
      },
      {
        dd: `
          <h3>クエスト(入金不要クエスト、入金クエスト)</h3>
          <ul>
            <li class="leter-first">クエストとは手持ちのキャッシュウォレットで賭け条件をクリアすることで、報酬としてキャッシュが貰えるプログラムです。クエストは通常、開始後30日間有効です。クエスト開始後は、賭け条件のクリアまたはキャンセルするまで継続され、クエスト併用利用は不可です。クエストにおけるライブカジノでの賭け金反映率は15%となります。</li>
            <li class="leter-first">QUEEN CASINOのサイトもしくは、キャンペーンメールにて、ご案内をしておりますが、入金ボーナスのオファーは、変更する可能性があり、その際は、ボーナスに関する新しい利用規約を掲載致します。もし、特定の利用規約が述べられていない場合は、ボーナス一般利用規約をご参照ください。</li>
          </ul>
          `
      },
      {
        dd: `
            <h3>無料スピン（無料スピンボーナス）</h3>
            <ul>
              <li class="leter-first">1.無料スピン１回分は30円、または$0.2に該当します。</li>
              <li class="leter-first">2.無料スピンウォレットの残高が1,600円 or $10以上になると自動的にボーナスが作成されます。</li>
              <li class="leter-first">3.マイアカウントページよりボーナスをご獲得ください。</li>
              <li class="leter-first">4.無料スピンは20倍のロールオーバーが必要です。</li>
              <li class="leter-first">5.無料スピンからの勝利金をボーナスウォレットからキャッシュウォレットへ移行させる場合、移行できる最大ボーナス額はボーナス×5で計算されます。</li>
            </ul>
          `
      },
      {
        dd: `
            <h3>ボーナスを利用してのご遊戯についての注意、又は禁止事項</h3>
            <ul>
              <li class="leter-first">入金を伴うボーナスは、出金条件が満たされるまでは、ボーナスアカウントに表示され、一切出金することができません。出金条件が満たされると、現金アカウント表示され、出金可能となります。</li>
              <li class="leter-first">システムによって自動進呈されたボーナス（初回入金ボーナスやリロードボーナス等）や手動で進呈されたボーナス(入金不要ボーナスや特別入金ボーナス）の出金条件は、ボーナスごとによって異なりますので、ホームページの各規約ページでご確認ください。
                  ※ボーナスBETのカウント率は、下記に記載の「ゲーム種別毎のBETカウントの割合」を、ご参照下さいませ。
              </li>
              <li class="leter-first">ボーナスマネーを使ってのシステムBETも禁止されております。</li>
              <li class="leter-first">出金条件を満たしていないボーナスの有効期限は14日間となります。</li>
              <li class="leter-first">キャンペーンの悪用の疑いを持った場合、もしくは悪用された場合、QUEEN CASINOの独断により、使用条件を改訂し、いかなる支払いまたは、勝ち額であっても保留もしくは、断る権利を有します。</li>
              <li class="leter-first">QUEEN CASINOの、全てのお客様は、ボーナスマネーを受け取ることにより、当キャンペーン一般利用規約内容、各キャンペーンの注意事項、利用規約を承認し、同意した事とみなします。
                    ボーナスを使ったゲームプレイで、ロールオーバーに反映されない賭け金額でプレイし、さらにその後のプレイがボーナスの濫用、不正行為だと判断された場合、当社で調査を行い、その結果がわかるまで、申請中の出金要請を含むプレイヤーのアカウント利用を制限する権利を有します。
              </li>
              <li class="leter-first">当キャンペーン一般利用規約内容への違反行為が発覚した場合、その行為が故意、過失を問わず、全ての状況において、QUEEN CASINOは、勝利金の没収、アカウントを凍結する権利を有します。</li>
              <li class="leter-first">スポーツベット・eスポーツベットは、配当がヨーロッパオッズで1.5倍以上が賭けの対象となります。1.5倍未満に賭けた場合、賭け金としてはロールオーバーに反映されませんので、ご注意ください。</li>
              <li class="leter-first">スポーツベット・eスポーツベットは、試合の結果によって生じる実行収益に基づいて賭け条件を計算します。</li>
              <li class="leter-first">ボーナス残高が1USD未満になった場合、ボーナスは失効致します。</li>
              <li class="leter-first">ゲーム種別毎のBETカウントの割合：
                <ul>
                  <li class="leter-first-circle">100% スロット/ビデオスロット</li>
                  <li class="leter-first-circle">100% スポーツベット</li>
                  <li class="leter-first-circle">100% eスポーツ</li>
                  <li class="leter-first-circle">100% バーチャルゲーム</li>
                  <li class="leter-first-circle">15% ライブカジノ</li>
                  <li class="leter-first-circle only">ボーナス、クエスト：ロールオーバーはベット額と勝利金を比較し、低い金額がロールオーバーの対象となります</li>
                  <li class="leter-first-circle">0% パチンコ・パチスロ</li>
                  <li class="leter-first-circle">0% iSLOT（ライブスロット）</li>
                  <li class="leter-first-circle">ボーナス、クエスト：ロールオーバーはベット額と勝利金を比較し、低い金額がロールオーバーの対象となります</li>
                </ul>
              </li>
              <li class="leter-first">QUEEN CASINOは、ウェブサイト、Eメール、その他電子メディアにて定期的にキャンペーンや、スペシャルオファーのご連絡をしており、各キャンペーン期間中の、特定の利用規約に関しても、記載しております。キャンペーンの最新版を受け取る為にも、登録しているメールアドレスは有効アドレスにてご登録下さいませ。</li>
              <li class="leter-first">最低出金可能額は 50.00 USD となります。 50.00 USD 以下の出金に対し、QUEEN CASINOは１回につき 5.00 USD の取引手数料を請求する権利を有します。</li> 
            </ul>
          `
      },
      {
        dd: `
            <p>※当サイトは英語版を優先します。</p>
            <p>※最終変更：2024年7月1日</p>
          `
      }
    ]
  },
  moneyLaundering: {
    title: 'マネーロンダリング防止ポリシー',
    content: [
      {
        dd: `
            <p>マネーロンダリング防止ポリシー</p>
            <p>キャッシュ入出金</p>
            <p>(Queen Casinoマネーロンダリング防止ポリシー)</p>
            <p>はじめに</p>
            <p>Queen Casinoは Zen Estratega SRL.によって運営されています。登録番号:3-102-897673 、登録住所:400, Calle La Ceiba, San Rafael, Escazú, Province of San Jose, Costa Rica。</p>
            <p>マネーロンダリング防止ポリシーの目的</p>
            <p>Queen Casinoは、すべてのユーザーに最高の安全を提供することを目指しており、そのために、ユーザーの身元を保証する2段階のアカウント認証を行っています。これは、登録情報が正しいこと、決済口座が盗難などで不正に使用されていないことを証明するためであり、マネーロンダリングを防ぐための一般的な枠組みを作るものです。また、国籍や出自、決済方法や出金方法によって、異なる安全対策を講じるものとします。</p>
            <p>Queen Casinoは、適切な手段を提供することを含め、マネーロンダリングリスクを管理・制限するための合理的な措置を講じます。</p>
            <p>Queen Casinoは、EUガイドラインに沿ったアンチ・マネー・ロンダリングの高水準の徹底に努めており、マネーロンダリング目的でのサービス利用を防止するために、経営陣及び従業員にこれらの基準に従うことを要求します。</p>
            <p>Queen Casinoのマネーロンダリング防止プログラムは、以下に準拠するように構成されています。</p>
            <p>EU:マネーロンダリング目的での金融システムの利用防止に関する2015年5月20日の欧州議会及び理事会指令2015/849</p>
            <p>EU: 資金移動に伴う情報に関する規則2015/847</p>
            <p>EU: 人に対する制裁や制限的措置を課す様々な規制及び特定の商品や技術に対する禁輸措置(すべてのデュアルユース商品を含む)</p>
            <p>BE: 2017年9月18日マネーロンダリング防止における現金の使用制限</p>
          `
      },
      {
        dd: `
            <h2>マネーロンダリングとは</h2>
            <ul>
              <li class="leter-first">犯罪行為や不正取引またはそれに関わる資金を違法な出所であることを隠したり偽装したりすること。または、そのような行為に関与している人または企業が法的措置を免れるのを手助けする目的で資金を変換や移転すること。
                    資金が犯罪行為またはそのような行為に関わるものであることを知りながら、資金の本質、出所、場所、処分、移動、権利、または所有権の真偽を隠蔽または偽装すること。
                    受領時に、犯罪行為またはそのような行為を手助けするために得たものと知りながら、資金を取得、所有または使用すること。
                    前項で言及した行為への関与や協力、行為の企て、ほう助、教唆、誘導、助言すること。
                    マネーロンダリングの対象となる資金を生み出す活動が他の加盟国の領域または第三国の領域で行われた場合でも、マネーロンダリングとみなされます。
              </li>
            </ul>
          `
      },
      {
        dd: `
            <h2>Queen Casino内のマネーロンダリング防止対策構成</h2>
            <ul>
              <li class="leter-first">マネーロンダリング防止法に基づき、Zen Estratega SRL.の管理下であるQueen Casinoは、マネーロンダリング防止において「最高レベル」に指定されています。
                さらに、マネーロンダリング防止法令遵守担当責任者が、マネーロンダリング防止ポリシー及びシステム内手続きの実施を担当しています。
                マネーロンダリング防止法令遵守担当責任者は、総括経営陣の直接の責任下に置かれています。
              </li>
            </ul>
          `
      },
      {
        dd: `
            <h2>マネーロンダリング防止ポリシーの変更と実施要件</h2>
            <ul>
              <li class="leter-first">本ポリシーの大幅な変更は、Zen Estratega SRL.の経営陣及びマネーロンダリング防止法令遵守担当責任者の承認が必要です</li>
            </ul>
          `
      },
      {
        dd: `
            <h2>2段階認証</h2>
            <ul>
              <li>
                <h3 class="leter-first">第1段階認証</h3>
                <ul>
                  <li>新規ユーザー登録時に、すべてのユーザーが第1段階認証を行う必要があります。第1段階認証で確認する情報は、ユーザー自身が入力しなければなりません。氏名、生年月日、性別、メールアドレスが明記されている必要があります。</li>
                </ul>
              </li>
              <li>
                <h3 class="leter-first">第2段階認証</h3>
                <ul>
                  <li>第2段階認証は、初めての出金の際、すべてのユーザーに対して行われます。第2段階認証が完了するまで、出金は保留されます。身分証明書を提出する必要があります。身分証明には公的な身分証明書のみ提出可能ですが、国によって身分証明書の種類が異なる場合があります。
                      また、第1段階認証時に入力された情報が正しいかどうかの電子チェックも行われます。電子チェックでは、2つの異なるデータベースを介して、記入された情報と身分証明書の情報が一致しているかどうかを確認します。電子認証に失敗した場合、または不可能だった場合、ユーザーは現住所を証明する政府発行または同等の証明書を提出する必要があります。
                  </li>
                </ul>
              </li>
            </ul>
          `
      },
      {
        dd: `
            <h2>身元証明と本人確認（KYC）</h2>
            <ul>
              <li class="leter-first">初めての出金を行う際に、ユーザーの身元証明を行うことは、マネーロンダリングに関連する規制とKYCポリシーの両方にとって重要な要素です。
                  本人確認は、以下の基本原則に依ります。
                  パスポート、身分証明書、運転免許証のいずれかのコピーが必要です。
                  身分証明書の四隅すべてが同じ画像内に表示されていて、上記の情報すべてがはっきりと読めるように撮影してください。必要に応じて、詳細を確認する場合があります。従業員は、必要であれば状況に応じて追加確認を行うことができます。
              </li>
            </ul>
          `
      },
      {
        dd: `
            <h2>住所証明</h2>
            <ul>
              <li class="leter-first">住所証明は、登録住所に送られた過去3ヶ月以内に発行された公共料金の請求書、または政府が作成した居住地を証明する公的な書類（例：電気代、水道代、銀行の明細書、住民票など）が必要です。
                    認証手続きをできるだけスピーディーに行うためにも、文書の四隅すべてが見え、すべての文字がはっきり読めるクリアな解像度で送信するようにしてください。
                    従業員は、必要であれば状況に応じて追加確認を行うことができます。
              </li>
            </ul>
          `
      },
      {
        dd: `
            <h2>第1段階認証の必要情報</h2>
            <ul>
              <li class="leter-first">基本情報は、Queen Casinoの設定ページからアクセスできます。各ユーザーは以下の情報を入力する必要があります。
                <ul>
                  <li class="leter-first-circle">氏名</li>
                  <li class="leter-first-circle">性別</li>
                  <li class="leter-first-circle">生年月日</li>
                  <li class="leter-first-circle">メールアドレス</li>
                </ul>
              </li>
              <li class="leter-first">入力された情報は安全に保管され、従業員は必要であれば状況に応じて追加確認を行います。</li>
            </ul>
          `
      },
      {
        dd: `
            <h2>リスクマネジメント</h2>
            <ul>
              <li class="leter-first">世界の各地域によってリスク及び財政状況が異なるため、Queen Casinoは、すべての国を2つの地域に分類します。</li>
              <li class="leter-first">地域1
                    地域1の国では、先に述べたように2段階認証を行われます。
              </li>
              <li class="leter-first">地域2
                    特定の地域は禁止されます。目まぐるしく変化する社会情勢に合わせ、高リスク地域は定期的に更新されます。
              </li>
            </ul>
          `
      },
      {
        dd: `
            <h2>さらなる追加の判定</h2>
            <ul>
              <li class="leter-first">マネーロンダリング防止法令遵守担当責任者が監督するAIは、異常な行動を探し、発見した場合はすぐにQueen Casinoの従業員に報告します。
                    リスクに基づいた少数及び一般的な経験に応じて、従業員は、AIや他の従業員によって行われたすべてのチェックを再確認し、状況に応じてやり直したり、追加のチェックを行うことがあります。
              </li>
              <li class="leter-first">さらに、データ・サイエンティストは最新の電子的・分析システムを用いて、ある程度の時間プレイを行わずに入出金を行うなどの異常な行動を探します。</li>
              <li class="leter-first">入出金に異なる銀行口座を使用しようとした場合、国籍・通貨の変更、行動やアクティビティの変更だけでなく、元の所有者によってアカウントが使用されているかどうかのチェックも行います。</li>
              <li class="leter-first">また、マネーロンダリングを防ぐために、ユーザーは最初の入金額に対して、入金時と同じ方法で出金を行う必要があります。</li>
            </ul>
          `
      },
      {
        dd: `
            <h2>全社的リスクアセスメント</h2>
            <ul>
              <li class="leter-first">Queen Casinoは、Queen Casino及びその事業内容に特有のリスクを特定し、理解するために、マネーロンダリング防止「全社的リスクアセスメント」を実施しています。本サイトが提供するサービス等の事業内容に内在するリスクを特定し、ドキュメント化した上で、マネーロンダリング防止リスクポリシーを決定しています。サービスが提供されるユーザー、これらのユーザーが行う取引、銀行が利用するデリバリーチャネル、銀行や顧客及び取引が行われる地理的位置、その他の質的及び顕在化したリスクが該当します。
                    マネーロンダリング防止リスクのカテゴリーの特定は、規制要件、規制上の期待、業界ガイダンスのQueen Casino理解に基づきます。また、ワールドワイド・ウェブがもたらす更なるリスクに対応するために、追加の安全対策が講じられます。
                    全社的リスクアセスメントは毎年行われます。
              </li>
            </ul>
          `
      },
      {
        dd: `
            <h2>継続的な取引の監視</h2>
            <ul>
              <li class="leter-first">マネーロンダリング防止コンプライアンスでは、ユーザープロファイルと比較して異常な取引や疑わしい取引を検知するために、「継続的な取引の監視」を実施しています。 この取引監視は3つの防衛線により行われます。
                  ・第1のコントロールライン
                  KYC認証が適切にされずにQueen Casinoへ疑わしい入金が行われることを防ぐために、効果的なマネーロンダリング防止ポリシーを備えた信頼できる決済サービス・プロバイダとのみ連携しています。
                  ・第2のコントロールライン
              </li>
              <li class="leter-first">Queen Casinoは、ユーザー、プレーヤー、または公認の代理人との接触があれば、当該アカウントでの取引についてデューデリジェンスを実施しなければならないように、ネットワークに認知させています。具体的には以下のようなものがあります。
                    アカウント上での決済取引実行のためのリクエスト
                    アカウント上の決済手段またはサービスに関連するリクエスト
              </li>
              <li class="leter-first">また、調整されたリスク管理による2段階認証により、Queen Casinoのすべてのユーザーに関する必要な情報が常に提供されます。
                    また、すべての取引は、経営陣の管理下にあるマネーロンダリング防止法令遵守担当責任者が監視している従業員が監督しなければなりません。
                    カスタマーサポートマネージャーに提出された特定の取引は（場合によってはコンプライアンスマネージャーを通じて）、デューデリジェンスの対象となる必要があります。
                    ひとつまたは複数の取引の異常性の判断は、基本的に、ユーザー情報（KYC）、ユーザーの金銭的行動及び取引相手の主観的な評価に依存します。
                    これらのチェックは自動化されたシステムによって行われ、従業員はさらなるセキュリティのためにクロスチェックを行います。
                    ユーザーアカウントで観察された取引のうち、合法的な活動と資金の出所を適切に把握できないものは、早急に疑わしいもの（正当化できないため）とみなされなければなりません。
                    Queen Casinoの従業員は、観察した取引のうち、ユーザーの合法的な活動や収入源を特定できない疑わしい取引について、マネーロンダリング防止部門に報告しなければなりません。
                    ・第3のコントロールライン
                    マネーロンダリング防止に対する最後のディフェンスラインとして、マネーロンダリングを完全に規制するために、疑わしいユーザーやリスクの高いユーザーを手動でチェックします。詐欺やマネーロンダリングが発見された場合は、当局に通知します。
              </li>
            </ul>
          `
      },
      {
        dd: `
            <h2>Queen Casinoでの疑わしい取引の報告について</h2>
            <ul>
              <li class="leter-first">Queen Casinoは、内部手順書の中で、どの場合に報告が必要か、また、報告の方法について、従業員向けに正確に記述しています。
                  疑わしい取引の報告は、内部手順に記載されている正確な方法に従って、マネーロンダリング防止対策チーム内で分析されます。
                  この分析結果と収集した情報に基づいて、マネーロンダリング防止対策チームは以下を決定します。
                  - 2017年9月18日の法律に規定された法的義務に基づき、金融庁への報告書の送付が必要かどうか
                  - ユーザーとの取引関係を終了させる必要があるかどうか
              </li>
            </ul>
          `
      },
      {
        dd: `
            <h2>手順</h2>
            <ul>
              <li class="leter-first">最低限のKYC基準を含むマネーロンダリング防止規則は、Queen Casinoのイントラネットサイトにある業務指示や手順書に翻訳されます。</li>
            </ul>
          `
      },
      {
        dd: `
            <h2>記録管理</h2>
            <ul>
              <li class="leter-first">本人確認のために取得したデータの記録は、取引関係が終了してから少なくとも10年間は保存しなければなりません。
                  すべての取引データの記録は、取引の実施または取引関係の終了後、少なくとも10年間は保存されなければなりません。
                  これらのデータは安全に暗号化され、オフライン及びオンラインで保存されます。
              </li>
            </ul>
          `
      },
      {
        dd: `
            <h2>従業員研修</h2>
            <ul>
              <li class="leter-first">Queen Casinoの従業員は、特別なトレーニングを受けて、リスクに基づく認証にて手動統制を行います。
                  研修・啓発プログラムは、以下の使用法に反映されています。
              </li>
              <li class="leter-first">財務に関わるすべての従業員を対象とした最新の規制に沿ったマネーロンダリング防止トレーニングプログラム</li>
              <li class="leter-first">新入社員全員を対象としたマネーロンダリング防止講習</li>
              <li class="leter-first">この研修プログラムの内容は、従業員がどのような業務に従事し、どのような役職に就いているかに応じて設定する必要があります。これらの講習は、Zen Estratega SRL.のマネーロンダリング防止対策チームに所属するマネーロンダリング防止専門家によって指導されています。</li>
            </ul>
          `
      },
      {
        dd: `
            <h2>内部監査</h2>
            <ul>
              <li class="leter-first">内部監査では、定期的にマネーロンダリング防止活動に関するミッションを設定し、報告書を作成します。</li>
            </ul>
          `
      },
      {
        dd: `
            <h2>データセキュリティ</h2>
            <ul>
              <li class="leter-first">ユーザーから提供されたすべてのデータは安全に保管され、他人に販売・譲渡されることはありません。法律によって関係当局から求められた場合、または、マネーロンダリングを防止するためにのみ、共有されます。
                  Queen Casinoは、EUデータ保護条例（正式には条例95/46/EC）のすべてのガイドラインと規則に従います。
              </li>
            </ul>
          `
      },
      {
        dd: `
            <h2>お問い合わせ</h2>
            <ul>
              <li class="leter-first">当サイトのマネーロンダリング防止ポリシーについてのお問い合わせは、以下のメールアドレスにご連絡ください。</li>
              <li class="leter-first">当社のマネーロンダリング防止ポリシー及びKYCポリシー、または、お客様のアカウント及びお客様個人に対して行われたチェックについてご要望がある場合は、以下のメールアドレスにご連絡ください。<a href="mailto:support@queen-casino.com">support@queen-casino.com</a></li>
            </ul>
          `
      },
      {
        dd: `
            <p>※なお、当サイトは英語版を優先します。</p>
            <p>最終変更：2023年09月06日</p>
          `
      }
    ]
  },
  privacy: {
    title: 'プライバシーポリシー',
    subTitle: [
      `これらのプライバシーポリシー（以下、「本ポリシー」といいます。）は、Queen Casinoウェブサイトおよび Zen Estratega SRL（www.queen-casino.com 含め、以下「当社」「当サイト」と称されます。）が運営するすべてのサービスにおける個人情報を含むユーザー情報の取扱いについて示すことを目的とします。`,
      `当社は、当サイトへのすべての訪問者のプライバシーを保護することに全力を尽くしており、当サイトを通じて利用可能なすべてのサービスおよび活動を、安全を保障されたオンライン環境で提供することに努めます。`
    ],
    content: [
      {
        dd: `
            <h2>収集する情報</h2>
            <ul>
              <li class="leter-first">当社がユーザーから収集する情報は、以下のとおりです。
                <ul>
                  <li class="leter-first-circle">ユーザーが当サイト内のフォームに入力した個人情報（当サイトを利用するためのユーザー登録時、当社が提供する各サービスへの申し込み、または資料の掲示や追加サービスの依頼時に要求する情報を含む）。なお、コンペやプロモーションに参加するとき、カスタマーサポートへの問い合わせ、苦情の申立、ウェブサイトの問題を報告する場合などには、ユーザーに個人情報の提出をお願いすることがあります。</li>
                  <li class="leter-first-circle">ユーザーが当サイトに連絡した場合の対応記録</li>
                  <li class="leter-first-circle">当社が調査目的で行うアンケートへの回答（アンケートへの回答は強制ではありません）</li>
                  <li class="leter-first-circle">ベット・プレイ・支払い・当サイト上で行われる取引・履行の詳細</li>
                  <li class="leter-first-circle">データ量・位置情報・ウェブログ・その他の通信データ（請求のための情報とそうでないものも含む）・アクセスしたページやリソースなど当サイトへのアクセス詳細</li>
                  <li class="leter-first-circle">当サイトでのチャットやメッセージボード（該当する場合）等での情報や会話</li>
                </ul>
              </li>
            </ul>
          `
      },
      {
        dd: `
            <h2>ユーザー情報の使用</h2>
            <ul>
              <li class="leter-first">当社はユーザーが提供した情報を収集・処理し、当組織と契約しているデータ処理者に情報を共有し、下記の用途に使用することがあります。
                <ul>
                  <li class="leter-first-circle">アカウント設定・最有効化・運用・管理・ユーザーから求められたサービスの提供※1</li>
                  <li class="leter-first-circle">ユーザーの個人プロフィールの設定※2</li>
                  <li class="leter-first-circle">ユーザーへのプロモーション情報の提供（プロモーションやイベントに関する情報の受け取りを希望しない場合は、『マイアカウント』の『設定』にてチェックを外してください。当社のマーケティングとプロモーション活動は、関連する法律に従って行っています。）※3</li>
                  <li class="leter-first-circle">ユーザーから得た情報の正確性を検証するため、第三者（金融機関、信用調査機関、年齢確認機関など）に情報を共有します。※4</li>
                  <li class="leter-first-circle">詐欺、不正行為、マネーロンダリングの防止、当社の利用規約の遵守、信用リスクの軽減、スポーツ/ゲームの誠実性の保守のため。それに伴い、ユーザーの個人情報やベッティング/ゲーミング履歴を規制当局・ベッティングやスポーツ機関・金融機関・年齢確認機関・警察・信用調査機関などの第三者に共有することがあります。※5</li>
                  <li class="leter-first-circle">関連するライセンスや規制当局の法律を遵守する義務と責任を果たすため。※6</li>
                  <li class="leter-first-circle">当社の利用規約やその他の規約を保障または適用させるため、また、当社・ユーザー・他者の権利、財産、安全を守るため。※7</li>
                  <li class="leter-first-circle">当社のサービス（アフィリエイトプログラムを含む）に使われるクレジットカードの支払い処理、およびオンライン決済時。※8</li>
                  <li class="leter-first-circle">ユーザーへのリサーチ、アンケート調査、分析、およびフィードバックを収集するとき。※9</li>
                  <li class="leter-first-circle">情報の収集や処理のためにインターネットを使用する際、情報の国際的な送受信が行われます。第三処理者がユーザーの個人情報を扱う場合は、本ポリシーに従い安全に行われるよう措置を講じます。</li>
                  <li class="leter-first-circle">上記※1~9に記載している目的でユーザー情報を処理するために第三者機関が入ることはありますが、ジョイントベンチャーの提携や当社の一部または全体の売却やその提案があった場合を除き、当社以外にユーザー情報を公開または売買することはありません。法律によって要求されない限り、提出された情報をユーザーの了承を得ずに共有・販売・流出することは一切ありません。</li>
                </ul>
              </li>
            </ul>
          `
      },
      {
        dd: `
            <h2>取引の管理につて</h2>
            <ul>
              <li class="leter-first">すべてのベット、プレイ、アカウントの取引履歴は当社のシステムに記録されます。最善のサービスを提供するため、取引はすべて定期的に監査が行われます。</li>
            </ul>
          `
      },
      {
        dd: `
          <h2>チャットとメールについて</h2>
          <ul>
            <li class="leter-first">カスタマーサポートとの会話内容は、社内サービスの向上、品質管理の改善、より迅速な回答をするために記録されます</li>
          </ul>
          `
      },
      {
        dd: `
          <h2>クッキーの使用について</h2>
          <ul>
            <li class="leter-first">クッキー」とは、ユーザーがウェブサイトを訪問した際やアプリケーションを利用したときにデバイス/ブラウザに一時的に保存される情報を含んだ、小さなファイルのことです。クッキーを使用すると、サーバーはブラウザから情報を収集することができます。</li>
            <li class="leter-first">当社では、以下の目的で当サイトにてクッキーを使用します。</li>
            <li class="leter-first">当サイトへのアクセスやアプリケーションを利用するユーザーを認証し、適切なサービスを提供するため。</li>
            <li class="leter-first">当サイトへのログイン許可、およびページを移動する際のログイン状態の保持の確認のため。ベット完了まで、ベッティング情報はクッキーに一時保存されます。これにより、当社のプロダクトとサービスを最大限に活用することができます。なお、当サイトを再訪問したときに前回と同じ状態をできるだけ復元するため、いくつかの設定（選択した言語、オッズ表示方法など）はより長い間クッキーに一時保存されます。</li>
            <li class="leter-first">当サイト上で行われるベットおよび口座取引に関する情報を自動的に収集するため。</li>
            <li class="leter-first">ユーザー/顧客による当サイトの閲覧パターンを把握するなどの分析目的、カスタマイズブラウザー情報を構築するため。現在当社では、Google社が提供するウェブ解析サービス「Googleアナリティクス」を利用しています。Googleアナリティクスはクッキーを使用し、ユーザーがどのように当サイトを利用しているかを分析します。また、別の第三者プロバイダーによるアナリティカルクッキーも使用しています。</li>
            <li class="leter-first">ユーザーにとって適切または役に立つ広告や情報などを提供するため。または、当社の広告キャンペーンの効率性を計るため。</li>
            <li class="leter-first">当サイト上での活動報告書の作成のため、または当サイト上の活動やインターネット使用に関するその他のサービスを提供するため。法律で要求された場合、または第三処理者が当社に代わって情報を処理する場合には、収集した情報を第三者に譲渡することがあります。</li>
            <li class="leter-first">当サイトおよびアプリケーションは、「Flashクッキー」「セッションクッキー」「パーシステントクッキー」を利用しています。「Flashクッキー」とは、Adobe Flashによって保存・アクセスされる少量の情報で、ウェブサイトに使われるブラウザーのプラグインです。「セッションクッキー」とは、ユーザーページ上での活動に関する情報をサーバーが保存し、次回ユーザーが最後にウェブサイトから離脱したところから再開できるようにする機能を持ちます。「パーシステントクッキー」とは、ユーザーがウェブサイトを再閲覧のためアクセスした際、ウェブサイトのサービスや機能をより楽しく、速く経験できるようにするためのものです。</li>
            <li class="leter-first">ユーザーはブラウザーのプライバシー設定にてサードパーティークッキーをブロックすることで、追跡機能のあるクッキーを拒否することが可能です（ブラウザーによって手順が異なります）。クッキーを管理、または削除する方法は、こちらのHP（<a href="www.aboutcookies.org" target="_blank">www.aboutcookies.org</a>）を参考にしてください。ただし、クッキーの使用を拒否することにより、当サイトの機能やサービスを最大限に利用できなくなる可能性もあります。（例: 選択したベットをベットスリップに移動させるのにクッキーによる記憶が必要となります。）</li>
          </ul>
          `
      },
      {
        dd: `
            <h2>セキュリティとプライバシーについて</h2>
            <ul>
              <li class="leter-first">当社は、不正アクセスからユーザー情報を守り、不正処理・遺失事故・破壊・損害を防止するため、安全対策を用います。当社は、ユーザーから提供される個人情報（登録情報詳細、デビットカードやクレジットカード情報など）を保護するために全力を尽くします。当社のセキュリティページは、Secure Sockets Layer（SSL）という方法でデータを暗号化しています。当サイトの一定の範囲にアクセスするために当社から与えられる、もしくはユーザーが設定したパスワードは自己責任で保管し、他人に教えないようにしてください。</li>
              <li class="leter-first">ユーザーを特定する個人情報は、インターネット上を通過する際は常に暗号化されます。SSLとは、ユーザーが当サイト上で送信した情報を自動的に暗号化する特別なプロトコルです。それを当社のサーバーがプライベートキーを使って情報を読み取ります。つまり、デビットカードやクレジットカード情報、個人情報などのユーザー情報は、プライベートキーもしくはパスワードを用いてのみ解読できる暗号に変換されるということです。ユーザーのブラウザーが適切に設定されている場合、当サイトの保護されたページに初めてアクセスする際は警告メッセージが表示されます。これは単に、当サイトがユーザー情報を保護しているという通知です。</li>
              <li class="leter-first">あいにく、インターネットを介した情報の送受信は完全に安全ではありません。上記6(b)項に記載されているように、当社はユーザーの情報を保護するために最善を尽くしますが、ユーザーがインターネット上で公開した情報の安全性をすべて保証できるわけではありません。ユーザーはインターネット使用においてのリスクを予め認め、過失がない限り、セキュリティが破られた場合の責任を当社に問わないものとします。個人情報保護のために、当社にメールを送信する際には、機密情報（デビットカード・クレジットカード番号など）を含めないようにすることをお勧めします。</li>
              <li class="leter-first">子会社やパートナー提携しているウェブサイト、広告のURLを当サイトに貼り付けることがあります。それぞれのウェブサイトは、独自のプライバシーポリシーや利用規約を持つ可能性があります。当社は、これらのポリシーに対する責任は一切負いません。各ウェブサイトに情報を送信する前に必ず確認してください。</li>
            </ul>
          `
      },
      {
        dd: `
            <h2>保持と処分について</h2>
            <ul>
              <li class="leter-first">当社は、決済情報を6年間保存することを規則としています。その他の情報は、法律などでより長い期間保存するよう定められていない限り、上記2(a)項に記載されているような利用や処理に必要な期間だけ保持されます。当社は、プライバシー情報の処分が適切に行われることを保証するため、妥当なすべての措置を取るものとします。</li>
            </ul>
          `
      },
      {
        dd: `
            <h2>ポリシーの変更について</h2>
            <ul>
              <li class="leter-first">当社は、本ポリシーを随時変更することがあります。変更した項目は、ウェブサイトに掲載された時点で有効になります。</li>
            </ul>
          `
      },
      {
        dd: `
            <h2>的確さ・情報へのアクセスにつて</h2>
            <ul>
              <li class="leter-first">ユーザーから収集した情報が正確かつ認証されていることを確認するため、必要な措置を講じます。</li>
              <li class="leter-first">ユーザーは当社が保持する自分についての情報を知る権利があり、最新かつ正確な情報を維持するため、更新が必要な場合は当社に連絡するようにしてください。</li>
              <li class="leter-first">法律上、当社は上記の情報を提供するのに、運営管理費として費用を請求する権利を保持します</li>
              <li class="leter-first">コメント・ご質問・ユーザー情報の使用に関するお問い合わせはすべて当サイト右下の『私たちとチャットする』から、またはメール：<a href="mailto:support@queen-casino.com">support@queen-casino.com</a> にいつでもしていただけます。</li>
            </ul>
          `
      },
      {
        dd: `
            <p>※当サイトは英語版を優先します。</p>
            <p>※最終変更：2023年09月06日</p>
  
          `
      }
    ]
  },
  responsibleGaming: {
    title: '責任あるギャンブル',
    subTitle: [
      `当社は、当サービスをお客様に最高のゲーム体験を提供させていただきたいと考えております。ギャンブルに依存しないように、当サイトのサービスを健康的な利用を強くお奨めします。`,
      `このセクションでは、ギャンブルを楽しんでいただくため、自制する方法と良いバランスを取る方法として、下記のガイドラインを提供します。`,
      `万が一、バランスが崩れた場合は、お客様に役立つコントロールツールを提供します。このため、お客様がギャンブルを制御できるように支援します。また、アドバイスする機密保持団体もたくさんあります。`,
      `もし、お客様がギャンブル依存症を自覚している場合は支援を受けることをお奨めします。ギャンブル依存症のご相談がありましたら、こちらに機密保持団体のサポートを詳しくご覧ください。`
    ],
    content: [
      {
        dt: 'コントロールを保つ',
        dd: `
            <p>コントロールを保つために、次の点を考慮して下さい</p>
            <p>・ギャンブルとは娯楽の一種であり、お金を稼ぐ手段ではありません</p>
            <p>・損失金を取り戻そうとしてはいけません</p>
            <p>・予算を決め、予算内でのみお楽しみ下さい</p>
            <p>・ギャンブルで日常生活に支障をきたさないようにして下さい</p>
            <p>・ギャンブルを始める前に、購入や賭け、そして損失にも制限をはっきり決めて下さい</p>
            <p>・入金限度額を設定し、使用金額を管理してください</p>
            <p>・一時的にギャンブルを中止されたい場合は、タイムアウトの依頼するか、自己規制して下さい</p>
          `
      },
      {
        dt: 'ギャンブル依存',
        dd: `
            <p>ギャンブル依存とは、悪い結果にも関わらず、継続的にギャンブルする欲望を抑えられないことです。ご自身、もしくは周りの方が過度にギャンブルを行っていないかを次の点を確認してみて下さい。</p>
            <p>・ギャンブルをするために、仕事や、学校を休んでいませんか？</p>
            <p>・現実逃避するためにギャンブルしていませんか？</p>
            <p>・失望したり、イライラしたとき、すぐにでもギャンブルをしたいと思いますか？</p>
            <p>・交通費やコーヒーが買えなくなるほど、お金全てなくなるまでギャンブルしていませんか？</p>
            <p>・最近ギャンブルを止めようとしたけれども、失敗していませんか？</p>
            <p>・ギャンブルでつぎ込んだ金額や時間を隠すために噓をついたことがありますか？</p>
            <p>・あなたのギャンブルを他人から批判されたことがありますか？</p>
            <p>・ギャンブル資金を工面するために、噓をついたり、盗難をはたらいたり、借入れしたりしたことがありますか？</p>
            <p>・“ギャンブルのための資金”を他のことで使うことをためらっていませんか？</p>
            <p>・家族や、友人や、趣味への興味を失っていませんか？</p>
            <p>・ギャンブルで負けてしまった後、もう一度勝って、失った金額をできるだけすぐに取り戻さなくてはならないと感じますか？</p>
            <p>・口論や、不満や、失望することがあると、ギャンブルをしたいという衝動に駆られますか？</p>
            <p>・ギャンブルが原因で意気消沈したり、自暴自棄になったりしませんか？</p>
            <p>上記の質問に”はい”の答えが多ければ多いほど、ギャンブル依存症の可能性があります。</p>
          `
      },
      {
        dt: '自己規制',
        dd: `
            <p>お客様が当サイトの使用を中止する必要を感じたときは、最長90日間まで自己規制をかけることができます。マイアカウントの『自己規制』より設定が可能です。一旦自己規制をかけられますと、アカウントへの入金ができなくなり、規制期間が終了するまでアカウントへのアクセスも制限されます。90日以上の規制をご希望の場合は、メール: </p>
            <p><a href="mailto:support@queen-casino.com?subject=%E8%B3%AA%E5%95%8F%E3%82%84%E5%95%8F%E9%A1%8C请通过 %20" target="_top">support@queen-casino.com</a> より申請してください。</p>
          `
      },
      {
        dt: '未成年賭博',
        dd: `
            <p>未成年賭博を防止するため登録が完了した時点で、お客様の年齢および身元確認の自動チェックが行われます。お客様が18歳以上である事実を確認できない場合は、72時間内に身分証明書のコピーを要求します。</p>
            <p>最善のサービスを提供するため、お客様の協力が必要です。もし未成年者がインターネットのアクセスできる場合、ログイン名、パスワード、口座の情報を知らせないようにしてください。</p>
            <p>サイトの利用を終了後に必ずログアウトしてください。 </p>
            <p>未成年者を保護するため、インターネットからフィルタリング・ソフトウェア（アクセス制限ソフトウェア）をインストールし、コンテンツの表示をコントロール、ウェブサイトをブロック、パスワードをかけることができます。</p>
          `
      }
    ]
  },
  faq: {
    title: 'よくある質問',
    subTitle: '',
    content: [
      {
        dt: '一般',
        dd: `
                  <h3><strong>Q: 新規登録するにはどうしたらよいですか。</strong></h3>
                  <p>A: 登録は無料でございます。ウェブサイトの右上の<strong>『今すぐプレイ』</strong>をクリックし、お客様情報画面にて、お客様の情報を入力してください。入力後、『登録』をクリックすると登録完了となります。<br>
                  登録したメールアドレスに認証メールが届きます。メール内のURLをクリックすると登録完了（認証完了）となり、ログインしてご利用が可能となります。</p>
                  <h3><strong>Q: 認証メールが届きません</strong></h3><p>A: メール認証については下記の公式ガイドをご参照ください。<br>
                  <a href="https://queen-casino.info/ja/registration-verify-guide/" target="_blank">https://queen-casino.info/ja/registration-verify-guide/</a><br>
                  <br>
                  また、LINEからのご登録でも認証可能でございます。下記リンクよりご登録ください。<br>
                  <a href="https://uyu4ktjm.autosns.app/line" target="_blank">https://uyu4ktjm.autosns.app/line</a><br>
                  <br>
                  ご利用のキャリアメールの迷惑メールフィルターの設定を変更を試しても届かない場合は、他のメールアドレスに変更することをお勧め致します。<br>
                  メールアドレスの変更は、当サイトライブチャットにてお問い合わせを頂ければいつでも変更可能でございます。</p><h3><strong>Q: 入金が全額ボーナスになってしまっていますが正常ですか？</strong></h3><p>A: 当サイトのボーナスは、入金してボーナスを獲得すると、どちらも一緒にボーナス残高に反映されます。勝ったり負けたりを繰り返しながら入金額とボーナス額合計の必要な倍数を賭けると、現金アカウントに資金が移動して、出金できるようになります。<br>
                  必要な倍数に関する詳細は、プロモーションページよりご確認いただくことができます。</p>
                  <h3><strong>Q: ひとりで複数アカウントを登録できますか?</strong></h3>
                  <p>A: 申し訳ございません。当サイトではアカウントの登録はひとりにつき1つのアカウントのみとなります。</p>
                  <h3><strong>Q: 運営に必要なライセンスを受けていますか。</strong></h3>
                  <p>A: はい。サイトはGaming TOBIQUEに依る厳しい審査を通り、ライセンスを取得しております。また、定期的に監査されているため、信頼度が高く安全です。</p>
                  <h3><strong>Q: どんな種類のゲームを提供していますか。</strong></h3>
                  <p>A: 当サイトでは、スポーツベッティング、eスポーツベッティング、ライブカジノ、スロットゲーム、バーチャルゲームを提供しております。</p>
                  <h3><strong>Q: 質問したい場合はどうしたらいいですか。</strong></h3>
                  <p>A: 24時間日本語対応のライブチャットまでお問い合わせください。ウェブサイトの右下の『ライブチャット』をクリックすると、即対応させていただきます。<br>
                  または、Eメール <a href="mailto:support@queen-casino.com">support@queen-casino.com</a>まで、ご連絡ください。</p>
              `
      },
      {
        dt: 'マイアカウント',
        dd: `
              <h3><strong>Q: パスワードを忘れてしまいました。どうすれば再設定できますか。</strong></h3>
              <p>A: ログインフォームの≪お忘れの場合≫をクリックし、登録メールアドレスを入力してください。新しいパスワード設定リンクが登録メールアドレスに届きますので、そちらからパスワードの再設定を行ってください。</p>
              <h3><strong>Q:ログインする際に問題が発生しました。どうしてですか。</strong></h3>
              <p>A: 再度、正しいメールアドレスとパスワードを入力してください。それでも問題が解決しない場合は、お手数ではございますが、カスタマーサポートまでご連絡ください。</p>
              <h3><strong>Q: 一度登録した内容を更新することができますか？</strong></h3>
              <p>A: 登録情報を更新する為に、ウェブサイトの右上にある『アカウントアイコン』をクリックし、『マイアカウント』を選択し,アカウント情報にて変更してださい。但し、更新できる項目は、住所・電話番号のみとなります。<br>
              その他の項目の変更が必要な場合は、お手数ではございますが、カスタマーサポートまでご連絡ください。</p>
              <h3><strong>Q: ユーザー名を変更することができますか。</strong></h3>
              <p>A: いいえ。アカウントの作成後にユーザー名を変更できないため、登録時に独自のユーザー名を登録することをお勧めします。</p>
              <h3><strong>Q: 登録したメールアドレスを変更することはできますか。</strong></h3>
              <p>A: はい。当サイトライブチャットにてお問い合わせを頂ければ変更可能でございます。</p>
              <h3><strong>Q: 通貨表示を円に変更することはできますか？</strong></h3><p>A: いいえ。ご利用いただける通貨はUSD（米ドル）のみで、表示の変更はできません。</p>
              <h3><strong>Q: 残高はどこで確認できますか。</strong></h3><p>A: 利用可能な残高は、既に右上に表示されております。</p>
              <h3><strong>Q: 入出金詳細を確認したい場合はどこを確認すればよいですか。</strong></h3>
              <p>A: 入出金の詳細を確認する場合は、『マイアカウント』内の『取引履歴』をクリックすると入出金の詳細が表示されます。</p>
              <h3><strong>Q: 取引/個人情報は安全ですか。</strong></h3>
              <p>A: はい。QUEEN CASINOでは、Secure Sockets Layer（SSL）256ビット通信よりすべて取引、個人情報、その他すべての情報を暗号化しています。<br>
              お客様の情報が安全かつ確実に保護されていることを保証するために、最高レベルのデータ暗号化のみを採用しています。</p>
              <h3><strong>Q: 自己規制を期限前に解除できますか。</strong></h3><p>A: いいえ。ご自身で設定された期日が到来するまでは、サイト側でも解除できません。</p>
            `
      },
      {
        dt: '入出金について',
        dd: `
            <h3><strong>Q: どのような入金方法がありますか。</strong></h3>
            <p>A: 下記のような入金方法を提供しています。</p>
            <ul>
              <li>
              <p><strong>銀行振込</strong>は、通常の国内送金と同じく、普段ご利用の金融機関のカウンター・ATM・モバイル送金手続きにて、安全かつ手軽に決済いただけます。入出金時の手数料も振込手数料のみとなっています。</p>
              </li>
              <li>
              <p><strong>i-Wallet</strong>（アイウォレット）は、安全なオンライン送金サービスで、QUEEN CASINOアカウントとの間で入出金をすることができます。アイウォレットで入金する際は、まず、アイウォレットにアカウントを登録し、十分な残高があることを確認してください。アイウォレットを登録するには、<a href="https://iwl.hk/jp/index.php" rel="noopener" target="_blank">アイウォレットのサイト</a> をご確認ください。</p>
              </li>
              <li>
              <p><strong>ecoPayz</strong>（エコペイズ）は、世界的に信頼されているオンライン決済サービスであり、世界中のサイトで安全かつ手軽に決済していただけます。入金する金額を入力し、続行を選択すると、エコペイズアカウントにリダイレクトされ、サインインして入金を確認します。エコペイズの登録方法や詳細は <a href="https://www.ecopayz.com/ja/" rel="noopener" target="_blank">エコペイズのサイト</a> にてご確認ください。</p>
              </li>
              <li>
              <p><strong>WLPay</strong> では、クレジットカードを使用して簡単にQUEEN CASINOアカウントに資金を入金することができます。<br>
              VISAとMasterCardでサポートされているほとんどの主要クレジットカードを受け付けます。</p>
              </li>
              <li>
              <p><strong>CoinPayment</strong>は、仮想通貨による決済サービスで、BTC、LTC、ETH、DOGE、DAI、USDCでの安全な入出金をご利用いただけます。</p>
              </li>
            </ul>
            <h3><strong>Q: 入金するにはどうすればいいですか。</strong></h3>
            <p>A: モバイル、PCからの入金方法は下記のとおりとなります。</p>
            <p style="padding-left: 30px;">【モバイルでの入金方法】<br>
            <strong>1.</strong> ログインする<br>
            <strong>2.</strong> ウェブサイトの右上の『アカウントアイコン』をタップ<br>
            <strong>3.</strong>『入金』をクリック<br>
            <strong>4.</strong> 入金方法を選択<br>
            <strong>5.</strong> 金額を入力<br>
            <strong>6.</strong>『入金する』をクリックし、入金完了<br>
            <br>
            <br>
            【PCでの入金方法】<br>
            <strong>1.</strong> ログインする<br>
            <strong>2.</strong> ウェブサイトの右上の『入金』をクリック<br>
            上記4～6の操作を行い、入金完了<br>
            <br>
            ※ ご利用の入金方法によっては、決済機関にログインするために関連する決済サイトへ移動されますので、ご注意ください。</p>
            <h3><strong>Q: 土日・祝日も入出金できますか。</strong></h3>
            <p>A: はい。全ての入出金方法において、24時間、365日対応しております。</p>
            <h3><strong>Q: 入金最低額はいくらですか。</strong></h3>
            <p>A: ecoPayz・iWalletが10 USD、WlandPayが30 USD、銀行振込・CoinPaymentが20 USDとなっております。</p>
            <h3><strong>Q: 入金には手数料はかかりますか。</strong></h3>
            <p>A: いいえ。QUEEN CASINOアカウントに入金する際には、手数料はかかりません。但し、お客様がご利用の決済機関により、資金移動時に手数料が発生するかもしれませんので、ご注意ください。</p>
            <h3><strong>Q: QUEEN CASINOでクレジットカードを使用するのは安全ですか。</strong></h3>
            <p>A: お客様から提供される個人情報を保護するために全力を尽くしております。当サイトのセキュリティページは、Secure Sockets Layer（SSL）256ビット通信より、暗号化されております。<br>
            クレジットカードの情報は暗号化され、QUEEN CASINOシステムに保存されず、QUEEN CASINOが提供するクレジット決済処理代行会社に暗号化されたチャネルを介して直接送信されます。<br>
            今後の取引の参照として利用するため、「トークン」という形で保存されます。</p>
            <h3><strong>Q: 入金後、アカウントの残高が更新されませんでした、どうすればいいですか。</strong></h3>
            <p>A: 一度、手動でログアウトしてから、再度ログインしてみてください。それでも更新されない場合は、入金状況をお調べしますので、お手数ではございますが、カスタマーサポートまでご連絡ください。</p>
            <h3><strong>Q: 出金するにはどうすればいいですか。</strong></h3>
            <p>A: 銀行振込、ecoPayz、iWallet、CoinPaymentで出金することができます。出金する際に、別の方法で通知されない限り、QUEEN CASINOアカウントに入金した際と同じ方法のみしかできませんので、ご注意ください。</p>
            <h3><strong>Q: 出金最低額はいくらですか。</strong></h3>
            <p>A: ecoPayz・iWalletが10 USD、銀行振込・CoinPaymentが50USDとなっております。</p>
            <h3><strong>Q: 出金申請するにはどうすればいいですか。</strong></h3>
            <p>A: ログインされている状態で、下記手順を行ってください。</p>
            <p style="padding-left: 30px;"><strong>1.</strong> ウェブサイトの右上にあるアカウントアイコンをクリック<br>
            <strong>2.</strong> 出金方法を選択<br>
            <strong>3.</strong> 利用機関のアカウント情報と金額を入力<br>
            <br>
            ・銀行振込の場合は、金融機関の口座情報を入力、<br>
            ・ecoPayzとiWalletの場合はアカウント番号を入力、<br>
            ・CoinPaymentの場合はウォレットアドレスを入力してください。<br>
            <br>
            <strong>4.</strong>『出金』をクリックし、出金申請完了</p>
            <h3><strong>Q: 出金時、手数料はかかりますか。</strong></h3>
            <p>A: いいえ。QUEEN CASINOアカウントで出金する際には、手数料はかかりません。但し、お客様がご利用の決済機関により、資金移動時に手数料が発生するかもしれませんので、ご注意ください。</p>
            <h3><strong>Q:『出金』をクリックしても出金申請できないのはどうしてですか。</strong></h3>
            <p>A: 現在有効な出金申請が存在します。出金が完了しますと、出金機能が有効になります。</p>
            <h3><strong>Q: 出金はいつ反映されますか？</strong></h3>
            <p>A: 全てのご本人様確認書類のご提出が完了しておりましたら、申請をいただいてから約12時間の反映となっております。</p>
          `
      },
      {
        dt: '本人確認について',
        dd: `
            <h3><strong>Q: 本人確認書類の提出が必要ですか。</strong></h3>
            <p>A: はい、ご本人様確認書類は各種お手続きをスムーズに行うためにご提出頂いています。迅速なお手続きの為に、ご登録情報は常に最新のものであり、ご本人様確認書類と一致していることをご確認ください。<br>
            ご本人様確認書類を提出することで、より安全なプレイ環境を提供させて頂くことに役立ちます。</p>
            <h3><strong>Q: どうして、本人確認が必要ですか。</strong></h3>
            <p>A: 当社の規制を果たす義務と別として、お客様のアカウントでの出金機能を有効にするため、アカウント認証するプロセスが完了しなければいけません。<br>
            アカウント認証プロセスは、顧客確認（Know Your Customer）という意味で、金融機関、銀行、または支払いプラットフォームがお客様の身元を確認するプロセスのことです。</p>
            <h3><strong>Q: 提出必要書類はどのようなものですか。</strong></h3>
            <p>A: 本人確認するために通常下記の書類が必要となります。</p>
            <p style="padding-left: 30px;"><strong>1.</strong> 身分証明書<br>
            &nbsp;&nbsp;&nbsp;&nbsp;運転免許証（両面）<br>
            &nbsp;&nbsp;&nbsp;&nbsp;パスポート（写真付きページ・住所記載ページ）<br>
            &nbsp;&nbsp;&nbsp;&nbsp;マイナンバーカード（表面のみ）<br>
            &nbsp;&nbsp;&nbsp;&nbsp;写真付き住民基本台帳カード（両面）<br>
            （上記の内のどれか１つ）<br>
            <br>
            必須項目は下記のとおりです。</p>
            <p style="padding-left: 30px;"><strong>✓</strong> 顔写真</p>
            <p style="padding-left: 30px;"><strong>✓</strong> お名前</p>
            <p style="padding-left: 30px;"><strong>✓</strong> 住所</p>
            <p style="padding-left: 30px;"><strong>✓</strong> 生年月日</p>
            <p style="padding-left: 30px;"><strong>✓</strong> 有効期限</p>
            <p style="padding-left: 30px;"><strong>2.</strong> 住所証明書（発行日から3ヶ月以内の住所確認書類 1点）</p>
            <p style="padding-left: 30px;"><strong>✓</strong> 住民票</p>
            <p style="padding-left: 30px;"><strong>✓</strong> 水道/電気/ガス代/電信通話/の請求書にて、ご住所、発行日が確認できる画像（郵送されたもの）</p>
            <p style="padding-left: 30px;"><strong>✓</strong> クレジットカードご利用明細書（郵送されたもの）</p>
            <p style="padding-left: 30px;">（上記のもの以外は審査対象外）</p>
            <p style="padding-left: 30px;">※  提出書類はカメラやスマートフォン、携帯電話で撮影し、写真がきれないように四隅をすべて含めてください。「フラッシュ」を使用し撮影される際は、必須項目がはっきり読めるようお願い致します。<br>
            アップロード可能なファイル拡張子はJPEG、BMP、PNGになります。</p>
            <h3><strong>Q: 本人確認書類をどのように提出したらいいですか。</strong></h3>
            <p>A: ログイン後、下記の操作を行ってください。</p>
            <p style="padding-left: 30px;">【モバイルでの操作方法】<br>
            <strong>1.</strong> ウェブサイトの右上の『アカウントアイコン』をタップ<br>
            <strong>2.</strong>『本人確認』をタップ<br>
            <strong>3.</strong>『ファイルを選択』をタップし、本人確認書類をアップロード<br>
            <strong>4.</strong>『保存』をタップし完了</p>
            <p style="padding-left: 30px;">【PCでの操作方法】<br>
            <strong>1.</strong> ウェブサイトの右上の『アカウントアイコン』をクリック<br>
            <strong>2.</strong>『マイアカウント』を選択<br>
            <strong>3.</strong>『アカウント認証』をクリック<br>
            <strong>4.</strong>『ファイルを選択』をクリックし、本人確認書類をアップロード<br>
            <strong>5.</strong>『保存』をクリックし完了</p>
            <h3><strong>Q: 本人確認完了後、他の書類を提出する必要がありますか。</strong></h3>
            <p>A: 新しい決済機関の利用またはクレジットカードを使用してQUEEN CASINOアカウントに入金された際は、追加書類の提出をお願いすることがあります。<br>
            これは、利用されている決済手段の所有権の確認することで、出金をスムーズに処理するためです。</p>
            <p style="padding-left: 30px;">必要に応じて、下記書類を提出してください。</p>
            <p style="padding-left: 30px;">クレジットカード（両面かつ全体（四隅を含くまれている））</p>
            <p style="padding-left: 30px;"><strong>✓</strong> カード名義</p>
            <p style="padding-left: 30px;"><strong>✓</strong> 有効期限</p>
            <p style="padding-left: 30px;"><strong>✓</strong> カード番号の初めの6桁と最後の4桁以外の数字を隠して撮影して下さい。 また、裏面のCVVセキュリティ番号も必ず隠して撮影してください。</p>
            <p style="padding-left: 30px;"><strong>✓</strong> 署名部分</p>
            <p style="padding-left: 30px;"><strong>決済機関</strong></p>
            <p style="padding-left: 30px;"><strong>✓</strong> 所有者の詳細を表示する決済機関アカウントのスクリーンショット。</p>
            <p style="padding-left: 30px;"><strong>✓</strong> QUEEN CASINOアカウントに最近行った入金を示す明細書のスクリーンショット</p>
            <p style="padding-left: 30px;"><br>
            ※ ご提出するスクリーンショットは、モニターの画面全体をキャプチャして表示する必要があります。隅を切り取ったスクリーンショットは受け付けられません。</p>
            <p style="padding-left: 30px;">※ 追加提出書類はカスタマーサポート <a href="mailto:support@queen-casino.com?Subject=本人確認書類" target="_top">support@queen-casino.com</a> へ送付してください。添付ファイル（拡張子：JPEG, BMP or PNG）</p>
          `
      },
      {
        dt: 'スポーツベットについて',
        dd: `
                  <h3><strong>Q: ベットをするにはどうすればいいですか。</strong></h3>
                  <p>A: ベットしたいスポーツを選択します。開催されるイベントのリストが表示され、クリックするとゲーム詳細オッズ(倍率)が確認できます。その中から興味のあるイベントを選択すると、利用可能なステーク(賭け金)の入力欄が表示されます。<br>
                  お客様がベットしたい金額を入力し、『ベットする』をクリックしてください。</p>
                  <h3><strong>Q: どのようにライブイベントをベットをすればよいですか。</strong></h3>
                  <p>A: ご登録のアカウントでログインしてください。ログイン後、『インプレイ』をクリックしベットしたいイベントを選択してください。次に、ウェブサイトの右側に『ベットスリップ』が表示されます。<br>
                  金額を入力し『ベットする』をクリックしベットすることができます。ベットが承認されると、緑色で『承認済み』とメッセージが表示されます。</p>
                  <h3><strong>Q: ベット金額の上限はいくらですか。</strong></h3>
                  <p>A: イベントによって最大ステーク(賭け金）額が異なります。それぞれの最大ステーク(賭け金）額を確認するには、『ベットスリップ』で選択した『マックスベット』をクリックしてください。</p>
                  <h3><strong>Q: ベットできているかを確認するにはどうすればよいですか。</strong></h3>
                  <p>A: 『マイアカウント』より、『決済ベット』と『未確定ベット』にて履歴を確認することができます。結果が出ていない試合などは、『未確定ベット』にてご確認いただけます。</p>
                  <h3><strong>Q: 間違えてベットしてしまいました。キャンセルできますか。</strong></h3>
                  <p>A: 残念ですが一度確定した賭けを取り消すことはできません。ベットをする際は選択を間違えないようにご注意願います。</p>
                  <h3><strong>Q: 結果を知りたいのですが、どうしたらよいですか。</strong></h3>
                  <p>A: 結果を知りたい場合は、ウェブサイトの右上にあるアカウントアイコンをクリックしてください。次に、『決済ベット』をクリックすると、結果が表示されます。<br>
                  試合中のマーケットや、決済待ちの場合は、『未確定ベット』に表示されます。</p>
                  <h3><strong>Q: どのくらいの時間でベット決済されるのですか。</strong></h3>
                  <p>A: 当サイトは、できるだけ早くベットを決済することを目指しております。ただし、－部のイベントでは公式結果を確認する時間が必要になります。長時間ベット決済されていない場合、カスタマーサポートまでご連絡ください。</p>
                  <h3><strong>Q: サッカーは延長も含まれる？野球で延長戦になった場合は？</strong></h3>
                  <p>A: サッカーの場合、特に明記しない限り、すべてのベットは規定時間にのみ決済され(中断時間を含む)、延長、またはPK戦は含まれません。<br>
                  野球の場合、特に明記がされていない限り、すべてのベットは延長（追加イニング）も含まれています。<br>
                  上記のとおり、競技によって規定が異なりますので、<a href="/static/sportRules">詳細はここから</a>ご確認ください。</p>
                  <h3><strong>Q: 画面内に表示されている[再生アイコン]や[サッカーコートアイコン]は何ですか？</strong></h3>
                  <p>A: [再生アイコン]<br>
                  ライブストリーミング(生配信）がお楽しみいただける機能となっております。（ご利用いただくにはご入金履歴が必要となります。）<br>
                  <br>
                  [サッカーコートアイコン]<br>
                  ライブセンター(図解にて試合進行）がお楽しみいただける機能となっております。（ご入金履歴がなくてもご利用いただけます。）</p>
              `
      },
      {
        dt: 'ボーナスについて',
        dd: `
          <h3><strong>Q: 登録ボーナスはどうすれば受け取れますか？</strong></h3>
          <p>A: 24時間日本語対応のライブチャットまでお問い合わせください。ウェブサイトの右下の『ライブチャット』をクリックすると、即対応させていただきます。<br>
          または、Eメール <a href="mailto:support@queen-casino.com">support@queen-casino.com </a>まで、ご連絡ください。</p>
          <h3><strong>Q: 入金ボーナスを受け取るにはどうしたらいいですか？</strong></h3>
          <p>A: ボーナスの獲得方法は、入金申請をする前にボーナスページより、獲得希望のボーナスの獲得ボタンを押して頂きますと、入金が反映されました際にボーナスも一緒にお客様のアカウントに反映されます。
          ボーナスが必要ないというお客様は、獲得ボタンを押さずにそのままご入金申請を頂ければ、ボーナスは進呈されません。</p>
          <h3><strong>Q: 新たにボーナスを獲得できません。</strong></h3>
          <p>A: ボーナス残高が残っていると、新たなボーナスを獲得できません。ボーナス残高が、0になっている事をご確認ください。</p>
          <h3><strong>Q: ボーナスの達成率はどこで確認できますか？</strong></h3>
          <p>A: QUEEN CASINOトップ画面右上のアイコンをタップして【ボーナス】をタップ、ボーナスの確認画面より、アクティブになっているボーナスが表示されます。<br>
          アクティブになっているボーナスの中に、【ロールオーバー】という項目があります。<br>
          【ロールオーバー】に、〇〇/〇〇という表記で金額が表示されており、左側の金額が実際にベットした金額、右側の金額が出金条件/現金化するのに必要なベット実績です。<br>
          ボーナスごとで、有効なベット金額やゲームカテゴリが異なりますので、詳細は各プロモーションページでご確認ください。</p>
          <h3><strong>Q: 誤って入金ボーナスを獲得してしまいました。取り消しできますか。</strong></h3>
          <p>A: ご入金後すぐに、ご遊戯されない状態で、カスタマーサポートへご連絡ください。ご遊戯を開始された後での取り消しはできません。</p>
          <h3><strong>Q: ボーナスの残りの端数が消えてしまいました。</strong></h3>
          <p>A: ボーナス残高が、1USD未満の金額になった場合、自動で削除されます。<br>
          少額のボーナスが残ってしまった場合に、残高を0にするためだけにゲームをプレイしなければいけない手間を省く目的で設定しております。<br>
          他の多くのカジノでも設定されています。</p>
          <h3><strong>Q: ゲームによって賭け金額としてカウントされる割合は違いますか？</strong></h3>
          <p>A: 下記のとおり、ゲームによって異なっております。</p>
          <p style="padding-left: 30px;"><strong>100%</strong> スロット/ビデオスロット</p>
          <p style="padding-left: 30px;"><strong>100%</strong> スポーツベット</p>
          <p style="padding-left: 30px;"><strong>100%</strong> eスポーツ</p>
          <p style="padding-left: 30px;"><strong>100%</strong> バーチャルゲーム</p>
          <p style="padding-left: 30px;"><strong>15%</strong> オアシスポーカー</p>
          <p style="padding-left: 30px;"><strong>15%</strong> ポンツーン</p>
          <p style="padding-left: 30px;"><strong>15%</strong> プントバンコ</p>
          <p style="padding-left: 30px;"><strong>15%</strong> カリビアンポーカー</p>
          <p style="padding-left: 30px;"><strong>15%</strong> ビデオポーカー（全てのタイプ）</p>
          <p style="padding-left: 30px;"><strong>15%</strong> ブラックジャック（全てのタイプ）</p>
          <p style="padding-left: 30px;"><strong>15%</strong> ルーレット（全てのタイプ）</p>
          <p style="padding-left: 30px;"><strong>15%</strong> バカラ（全てのタイプ）</p>
          <p style="padding-left: 30px;"><strong>15%</strong> インスタントゲーム</p>
          <h3><strong>Q: ボーナスに期限はありますか？</strong></h3>
          <p>A: 受け取り有効期限や現金化(ロールオーバー)達成期限がございます。<br>
          ボーナスごとで、期間が異なりますので、詳細は各プロモーションページでご確認ください。</p>
          <h3><strong>Q: ボーナスの出金限度額は決まっていますか？</strong></h3>
          <p>A: ボーナスによっては、出金できる金額が定められております。これは出金上限金額と記載されておりますので、キャンペーン規約をご確認下さい。</p>
          <h3><strong>Q: ボーナスの最大ベット額は決まっていますか？</strong></h3>
          <p>A: ボーナスごとにベット上限は異なります。<br>
          ボーナスを使ったゲームプレイで、ロールオーバーに反映されない賭け金額でのプレイは出金条件にはカウントされないと共に、その後プレイヤーがボーナス汎用、不正行為と判断された場合<br>
          当社で調査を行い、その結果がわかるまで、申請中の出金要請を含む、プレイヤーのアカウント利用を制限することもありますため、ご注意ください。</p>
          <h3><strong>Q: スポーツベット・eスポーツベットでボーナスを使用しての最小オッズの制限はありますか？</strong></h3>
  <p>A: ボーナス毎に出金条件にカウントされる配当倍率が定められております。詳細は各プロモーションページ・キャンペーン規約でご確認ください。</p>
          `
      }
    ]
  },
  verification: {
    tips: {
      title: ' どうして、本人確認が必要ですか。',
      content: `"クイーンカジノでは、お客様により安心してご利用いただくために本人確認書類の提出をお願いしております。
        これはクイーンカジノが保有するライセンスに基づく義務であり、厳しい監査・監視のもとでライセンスを維持するため必要となるものです。
        また、マネーロンダリング対策、お客様の資金保護のためにも必要となる大変重要な手続きとなりますのでご協力いただけますようお願い申し上げます。
        ご提出いただきました本人確認書類は厳重に管理いたしますので、ご安心くださいませ。"`
    }
  }
} as const
