export const deposit = {
  // export const DEPOSIT = {
  selectCurrency: 'Choose your preferred currency',
  gk: 'Get Crypto In Seconds',
  price: 'Market Price',
  preview: 'Preview',
  select: 'Easy Selection',
  reminder: 'Friendly reminder',
  deposit: 'Deposit',
  firstDeposit: 'First Deposit',
  getAmount: 'Get Bonus',
  finshInfo: 'Complete information',
  amountText: 'Amount',
  depositType: 'PICK A DEPOSIT METHOD',
  tip1: 'Prior application is required to receive the deposit bonus.',
  tip2: 'If you want to receive a deposit bonus, you must apply it from the bonus page before making a deposit.',
  tip3: 'There are many other great bonuses besides deposit bonuses, so be sure to check them out.',
  tip4: '※If you get an unintended bonus, please do not play and contact customer support.',
  tip5: 'It is no longer possible to delete the bonus after playing.',
  ok: ' Check bonus',
  banqtitle: 'BANK',
  banqtips: 'You can make a deposit from your online bank or ATM via.',
  time: 'Available hours for bank transfers',
  min: 'Minimum deposit amount：',
  max: 'Maximum deposit amount：',
  amount: 'AMOUNT',
  confirm: 'DEPOSIT',
  disctitle: 'AMOUNT ',
  disctips: 'Credit cards issued overseas cannot be used.',
  cardNnm: 'CARD NUMBER',
  month: 'MONTH',
  year: 'YEAR',
  lastName: 'FIRST NAME',
  firstName: 'LAST NAME',
  city: 'ADDRESS',
  status: 'CITY',
  postalCode: 'ZIP CODE',
  country: 'COUNTRY',
  phoneNumber: 'PHONE',
  email: 'EMAIL',
  ecopaytitle: 'EcoPayz',
  ecopaytips: 'An active ecoPayz account is required.',
  ecopayurl: 'ecoPayz Website',
  iwallettitle: 'IWallet',
  iwallettips: 'An active i-Wallet account is required.',
  orientaltitle: 'An active OrientalWallet account is required. You can create one at',
  orientalurl: 'OrientalWallet Website',
  notEnough: '{{card}} Min/Max: {{currency}}{{min}} - {{currency}}{{max}}',
  maxEnough: 'Amount too high',
  day: 'Weekday, Saturday, Sunday, Holiday',
  hour: '24hours',
  japan: '(Japan time)',
  err: 'Required field',
  kycTips1: 'After KYC is completed, more deposit methods will be provided.',
  kycTips2: 'Also, please complete KYC as soon as possible so that withdrawals can be processed quickly.',
  userName: 'Depositor',
  userNameText: 'Please enter depositor name',
  copy: 'copy successfully',
  certain: 'Certain website features require that you verify your identity.',
  verify: 'To verify your identity, please upload the following documents:',
  id: 'ID Card / Drivers License / Passport',
  bill: 'Utility bill',
  valid: '(Only valid for documents issued within 3 months)',
  file: 'Supported file types:',
  types: '.gif, .jpeg, .jpg, .png, .tif, .tiff',
  maximum: '(Maximum of 5 MB)',
  formats: 'Invalid document formats:',
  black: '- black & white',
  pdf: '- PDF / Word / Excel',
  dialogTitle: 'Fill in your information correctly',
  dialogText1: 'Please complete and confirm your real name and phone number',
  dialogFirstName: 'FIRST NAME',
  dialogLastName: 'LAST NAME',
  mobile: 'MOBILE NUMBER',
  verification: 'Verification',
  open: "didn't receive verification code?",
  upload: 'Please upload ID / Age verification document',
  idCard: 'ID',
  address: 'Proof of address',
  what: 'What authentication documents are required?',
  card: "The card holder name must be identical to the User's name",
  btn: 'CONFIRM',
  trouble: 'Having trouble?',
  team: 'Our 24/7 Live Chat team',
  help: 'can help.',
  send: 'Send',
  enterFirstName: 'Please enter first name',
  enterLastName: 'Please enter last name',
  enterPhone: 'Please enter phone number',
  enterEmail: 'Please enter email',
  enterCode: 'Please enter verification code',
  tips: "Note:Please confirm that you are depositing {{currency}}({{protocol}}) Mismatched & wrong contract may result in the permanent loss of your assets. Additionally, incorrect information or failure to include the memo/tag may incur a deduction of <span style='white-space: nowrap;'>200 USDT</span> as a refund processing fee.",
  choose: 'Channel',
  realName: 'Please enter your real name',
  fail: 'Deposit failed',
  warn1: 'Once you buy Crypto in Onramper Pay, it will be credited to your account balance automatically',
  warn2: 'You Pay',
  warn3: 'You Get',
  warn4: 'Deposit to your Balance ≈ {{amount}} USD',
  warn5:
    'Disclaimer: You are leaving QueenCasino and moving to a third-party platform. You need to complete KYC procedures before purchasing Crypto.',
  warn6: '{{currency}} payment limit is {{min}} - {{max}}',
  tag: 'Tag/memo：',
  xrp: "Note: Please ensure that you are depositing XRP (Ripple). If the information provided is inaccurate or incorrect, you may lose the assets you have transferred. Additionally, if the information is inaccurate or the memo/tag is missing, a refund processing fee of <span style='white-space: nowrap;'>200 USDT</span> will be deducted.",
  limit: 'Min/Max: ',
  fee: 'Fee: ',
  maxText: 'Max: ',
  totalAmount: 'Total amount(Including fee):',
  more: 'More',
  prohibit: 'Account exceptions , Please contact our online service',
  wrong: 'wrong format',
  depositer: 'Deposit success',
  depositerErr: 'Deposit submission failed. Please refresh and try again.',
  userID: 'ID',
  password: 'Password',
  cardNumber: 'Card Number',
  cvv: 'CVV',
  date: 'Expiration Date (MM/YYYY)',
  countrys: 'Country',
  province: 'Prefecture',
  citys: 'City',
  name: 'BANK NAME',
  first: 'Please enter amount first',
  receive: 'Promotions',
  choosePrize: '(Select Bonus)',
  details: 'View Details',
  nochoose: 'Not Selected',
  bonus: 'Bonus',
  finally: 'Total balance update',
  click: 'Click to collect',
  have: 'You have a new offer for ${{amount}}',
  fast: 'Fastest',
  condition: 'Enable Credit Card',
  times: 'Number of deposits',
  moon: 'Duration since registered',
  title:
    'The time is calculated as the average of the first 10 successful orders from application to order completion recharge statistics',
  txt: 'Selected',
  realLastName: 'Please enter your real last name',
  realFirstName: 'Please enter your real first name',
  dates: 'Please select date of birth',
  cou: 'Please select a country',
  cit: 'Please enter city',
  addrress: 'Please enter detailed address',
  codeText: 'Please enter postal code',
  last: 'Last Deposit',
  fastg: 'Quick deposit',
  tips3: 'After recharging, please close the pop-up window and wait patiently',
  find: 'How to Find the CVV/CVN?',
  also: 'CVV/CVN is also known as CVC/CID: ',
  tf: 'Located on the back of VISA/Mastercard/JCB (the last 3 digits)',
  tb: 'On the front of AMEX card (4 digits)',
  font: 'On the back 3 digits',
  fontTxt: 'Visa / Mastercard / JCB',
  back: 'On the front 4 digits',
  backTxt: 'American Express (AMEX)',
  maintain: 'Under Maintenance',
  needKyc: 'Please complete the KYC L1 and L2 verification first',
  needKyc5s: 'Proceed to the verification page in <span>{{num}}</span> s.',
  needKycBtn: 'Verify Now',
  vegaTip: 'Enter your Vega Wallet user ID and password.',
  vegaLink: 'How to use (must read)',
  bankTips: 'How to improve deposit success rate?',
  cardNoSup: 'This type of credit card is currently not supported.',
  cardErr: 'The card number is incorrect, please resubmit',
  vegaWalletTutorialTitle: 'How to deposit by Vega Wallet',
  vegaWalletTutorialTitleHtml: 'How to deposit by <br /> Vega Wallet',
  purchase: 'Purchase',
  goBack: 'Go Back',
  registration: 'Vega Wallet Registration',
  goDeposit: 'Deposit Now',
  chooseCurrency: 'Choose your currency',
  confirmAgain: 'Please confirm again',
  bankTipTitle:
    "When making a bank transfer deposit,<br/>please do not forget to fill in the<span  style='color:#d9004e;cursor: pointer;'>'Remarks'</span>field.",
  bankTipMain:
    "If you choose to deposit via bank transfer, please make sure to enterthe '6-digit code + depositor's name' displayed in the remarks field on the transfer information screen into the remarks field during the transfer.<span  style='color:#d9004e'>Example>123456スズキユキ </span> If this information is not entered correctly, the deposit cannot be processed properly, so please be careful.",
  changePayment: "Change payment method",
  cancel: "Cancel",
  complete: "Complete",
  selecting: "Selecting",
  iSee: "got it",
  buyCrypto: "Buy Crypto",
  cryptoSelect: "Select Payment Method",
  exchangeAmount: "Exchange amount",
  actualAmount: "Actual amount received",
  bankTransfer: "Bank Transfer"
} as const
