import { activity } from './activity'
import { addToHome } from './addToHome'
import { benefit } from './benefit'
import { bonus } from './bonus'
import { brand } from './brand'
import { common } from './common'
import { deposit } from './deposit'
import { exclusion } from './exclusion'
import { help } from './help'
import { home } from './home'
import { misc } from './misc'
import { personal } from './personal'
import { promo } from './promo'
import { responsible } from './responsible'
import { statements } from './statements'
import { stat } from './static'
import { system } from './system'
import { user } from './user'
import { verification } from './verification'
import { withdraw } from './withdraw'

const lang = {
  activity,
  addToHome,
  benefit,
  bonus,
  brand,
  common,
  deposit,
  exclusion,
  help,
  home,
  misc,
  personal,
  promo,
  responsible,
  statements,
  stat,
  system,
  user,
  verification,
  withdraw
} as const
export default lang
