export const brand = {
  name: 'ブランドストーリー',
  mileStone: 'マイルストーン',
  detail: '詳細',
  event: 'イベント',
  importantEvent: '大きなイベント',
  eventDetail: '【キャンペーン内容】',
  more: 'もっと',
  totalPage: '合計 {{0}} ページ',
  january: '一月',
  february: '二月',
  march: '三月',
  april: '四月',
  may: '五月',
  june: '六月',
  july: '七月',
  august: '八月',
  september: '九月',
  october: '十月',
  november: '十一月',
  december: '十二月',
  skip: 'スキップ',
  back: '前へ',
  next: '次へ',
  add: 'ゲームを追加',
  addTxt:
    '上部の『+』アイコンで追加し、ゲームを選択してクリックすると、ゲーム画面が表示され、ベットを開始できます',
  fast: 'クイックチャージ',
  fastTxt:
    'フローティングボタンから『チャージ』を選択し実行します。リチャージが完了して""チャージ成功""のメッセージが表示されます。スコアの反映をお待ち下さい',
  quit: 'ゲーム終了',
  quitTxt:
    '終了したいゲームを選択し、タブまたはゲーム画面を長押しして、「ゲームを終了」を選択し、確認ダイアログボックスが表示されたら、「確認」をクリックしてください',
  instructions: '操作説明を見る',
  instructionsTxt:
    'ゲーム管理ページにアクセスし、開いているゲーム画面を長押しして、機能ポップアップが表示され、「操作説明」を選択すれば、再度マルチウィンドウモードの操作を確認できます',
  illustrate: 'ボタンの説明',
  illustrateTxt:
    '非表示ー ゲームタブのページネーションを非表示にします<br/>チャージ－クイックチャージポップアップウィンドウ表示<br/>ゲーム－ゲームを追加<br/>トップページ－QueenCasinoトップページに戻る',
  hide: 'タブを隠す',
  hideTxt:
    'フローティングボタンで「非表示」を選択するとタブページが非表示になり、再度クリックすると表示されます',
  switch: 'ゲーム切り替え',
  switchTxt:
    '左右にスワイプか上タブをクリックでゲームが切り替わり、小窓タブでは小窓のゲーム画面が切り替わります。',
  small: '小ウィンドウ復元',
  smallTxt:
    'ゲーム管理ページで、ゲーム画面を選択して長押しすると機能ポップアップウィンドウが表示され、『小ウィンドウを開く』を選択すると小ウィンドウが再度表示されます'
} as const
